import { AxiosResponse } from "axios";
import $api, { API_URL } from "../../shared/api/axios";
import { IUser } from "../types/IUser";
import { BackResponse, IBackResponse } from "../types/response";

export default class UserService {
  static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>(`${API_URL.xUser}/user/users`);
  }

  static sendAvatar(avatar: string): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.xUser}/user/avatarupload`, {
      avatar,
    });
  }

  static getAvatar(): Promise<AxiosResponse<IBackResponse>> {
    return $api.get<IBackResponse>(`${API_URL.xUser}/user/getavatar`);
  }

  static async getUsernameById(id: number | string): Promise<string> {
    const response = await $api.post(`${API_URL.xUser}/user/usernamebyid`, {
      id,
    });
    return response.data;
  }

  static async getUserList(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xUser}/user/userlist`);
    return response.data;
  }

  static async getOne(userId: number): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xUser}/user/getone`, {
      userId,
    });
    return new BackResponse(response.data);
  }

  static async create(params: {
    email: string;
    password: string;
    temporary: boolean;
    firstName?: string;
    lastName?: string;
    roles: string[];
  }): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xUser}/user/create`, params);
    return new BackResponse(response.data);
  }

  static async patch(params: {
    id: number;
    firstName?: string;
    lastName?: string;
    roles?: string[];
  }): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xUser}/user/patch`, params);
    return new BackResponse(response.data);
  }
}
