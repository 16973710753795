import { useQuery } from "@tanstack/react-query";
import { Button, Form, Input, notification, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { isEqual, pick } from "lodash";
import React from "react";
import RoleService from "../../entities/model/RoleService";
import UserService from "../../entities/model/UserService";
import { IUserList } from "../../entities/types/IUser";
import Loader from "../../shared/components/loader";

type Props = {
  id: number;
  onSuccess?: () => void | Promise<void>;
};

export const EditUser: React.FC<Props> = ({ id, onSuccess }) => {
  type FormValues = {
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form] = useForm<FormValues>();

  const userQuery = useQuery<IUserList>({
    queryKey: ["user", id],
    queryFn: async () => {
      const response = await UserService.getOne(id);

      if (response.code !== 1) {
        throw new Error(`Ошибка при загрузке пользователя: ${response.text}`);
      }

      return response.data;
    },
  });

  const rolesQuery = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await RoleService.getList();

      if (response.code !== 0) {
        throw new Error(`Ошибка при загрузке ролей: ${response.text}`);
      }

      return response.data;
    },
  });

  const rolesOptions = React.useMemo(
    () =>
      rolesQuery.data?.map((role) => ({ label: role.name, value: role.id })),
    [rolesQuery.data],
  );

  if (userQuery.isLoading) {
    return <Loader />;
  }

  if (userQuery.error) {
    return <div>{userQuery.error.message}</div>;
  }

  if (!userQuery.data) {
    return <div>Не удалось загрузить пользователя</div>;
  }

  const user = userQuery.data;

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);

    const userPatch: Record<string, any> = {};

    if (!isEqual(user.roles, values.roles)) {
      userPatch["roles"] = values.roles;
    }

    if (!isEqual(user.name, values.firstName)) {
      userPatch["firstName"] = values.firstName;
    }

    if (!isEqual(user.surname, values.lastName)) {
      userPatch["lastName"] = values.lastName;
    }

    if (Object.keys(userPatch).length === 0) {
      notification.info({ message: "Нечего обновлять" });
      return;
    }

    console.log("onSubmit", { userPatch });

    const response = await UserService.patch({ id, ...userPatch });
    setIsLoading(false);

    if (response.code !== 1) {
      notification.error({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
      return;
    }

    notification.success({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    await onSuccess?.();
  };

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onSubmit}
      >
        <Form.Item label="Роль">
          <div style={{ display: "flex", gap: "5px" }}>
            <Form.Item name="roles" initialValue={user.roles} noStyle>
              <Select
                mode="multiple"
                options={rolesOptions}
                loading={rolesQuery.isLoading}
              />
            </Form.Item>
            <Reset path="roles" value={user.roles} />
          </div>
        </Form.Item>

        <Form.Item name="email" label="Почта" initialValue={user.email}>
          <Input allowClear disabled />
        </Form.Item>

        <Form.Item label="Имя">
          <div style={{ display: "flex", gap: "5px" }}>
            <Form.Item name="firstName" initialValue={user.name} noStyle>
              <Input allowClear />
            </Form.Item>
            <Reset path="firstName" value={user.name} />
          </div>
        </Form.Item>

        <Form.Item label="Фамилия">
          <div style={{ display: "flex", gap: "5px" }}>
            <Form.Item name="lastName" initialValue={user.surname} noStyle>
              <Input allowClear />
            </Form.Item>
            <Reset path="lastName" value={user.surname} />
          </div>
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          shouldUpdate={(p, c) => !isEqual(p, c)}
        >
          {({ getFieldValue: get }) => {
            const isSame =
              isEqual(user.roles, get("roles")) &&
              isEqual(user.name, get("firstName")) &&
              isEqual(user.surname, get("lastName"));

            return (
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={isLoading}
                disabled={isSame}
              >
                Изменить
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUser;

const Reset = ({ path, value }: { path: string | string[]; value: any }) => (
  <Form.Item
    noStyle
    shouldUpdate={(p, c) => !isEqual(pick(p, [path]), pick(c, [path]))}
  >
    {(form) => (
      <Button
        onClick={() => form.setFieldValue(path, value)}
        disabled={isEqual(form.getFieldValue(path), value)}
      >
        🔙
      </Button>
    )}
  </Form.Item>
);
