import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Tag, Tooltip } from "antd";
import { format } from "date-fns";
import type {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { ReactNode, useState } from "react";
import FlowService from "../../../entities/model/FlowService";
import UserPopover from "../../../shared/components/popovers/user";

const getStatusColor = (status: string | null | undefined | ReactNode) => {
  if (status && status !== "") {
    switch (status) {
      case "Завершено":
        return "#87d068";
      case "В очереди":
        return "grey";
      case "Ошибка":
        return "#f50";
      case "В работе":
        return "#2db7f5";
      case "Предупреждение":
        return "yellow";
      default:
        return "grey";
    }
  }
};

const getStatusIcon = (status: string | null | undefined | ReactNode) => {
  if (status && status !== "") {
    switch (status) {
      case "Завершено":
        return <CheckCircleOutlined />;
      case "В очереди":
        return <ClockCircleOutlined />;
      case "Ошибка":
        return <CloseCircleOutlined />;
      case "В работе":
        return <SyncOutlined spin />;
      case "Предупреждение":
        return <WarningOutlined />;
      default:
        return <MinusCircleOutlined />;
    }
  }
};

const statusConvert = (status: string) => {
  switch (status) {
    case "FINISHED":
      return "Завершено";
    case "INITIALIZED":
      return "В очереди";
    case "ERROR":
      return "Ошибка";
    case "STARTED":
      return "В работе";
    case "WARNING":
      return "Предупреждение";
  }
};

const statusOptions = [
  {
    value: "INITIALIZED",
    label: (
      <Tag
        icon={getStatusIcon("В очереди")}
        color={getStatusColor("В очереди")}
      >
        В очереди
      </Tag>
    ),
  },
  {
    value: "STARTED",
    label: (
      <Tag icon={getStatusIcon("В работе")} color={getStatusColor("В работе")}>
        В работе
      </Tag>
    ),
  },
  {
    value: "FINISHED",
    label: (
      <Tag
        icon={getStatusIcon("Завершено")}
        color={getStatusColor("Завершено")}
      >
        Завершено
      </Tag>
    ),
  },
  {
    value: "ERROR",
    label: (
      <Tag icon={getStatusIcon("Ошибка")} color={getStatusColor("Ошибка")}>
        Ошибка
      </Tag>
    ),
  },
  {
    value: "WARNING",
    label: (
      <Tag
        icon={getStatusIcon("Предупреждение")}
        color={getStatusColor("Предупреждение")}
      >
        Предупреждение
      </Tag>
    ),
  },
];

const TABLE_COLUMNS: MRT_ColumnDef<any>[] = [
  {
    header: "Идентификатор",
    accessorKey: "uuid",
    enableFilterMatchHighlighting: false,
  },
  {
    header: "Создано",
    accessorKey: "createdAt",
    filterVariant: "date",
    filterFn: "betweenInclusive",
    muiFilterDatePickerProps: { format: "DD.MM.YYYY" },
    Cell: ({ cell }: any) =>
      format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
  },
  {
    header: "Статус",
    accessorKey: "status",
    filterVariant: "multi-select",
    filterSelectOptions: statusOptions as any,
    Cell: (e) => (
      <Tag
        icon={getStatusIcon(e.renderedCellValue)}
        color={getStatusColor(e.renderedCellValue)}
        style={{ width: "120px", fontWeight: "bold", fontSize: 15 }}
      >
        {e.renderedCellValue}
      </Tag>
    ),
  },
  {
    header: "Сообщение",
    accessorKey: "message",
  },
  {
    header: "Агент",
    accessorKey: "agentId",
    Cell: (e) => <Tag>{e.renderedCellValue}</Tag>,
  },
  {
    header: "Инициатор",
    accessorKey: "launchedBy",
    Cell: (e) => (
      <UserPopover
        userId={e.renderedCellValue ? e.renderedCellValue.toString() : "0"}
      />
    ),
    enableColumnFilter: false,
  },
  {
    header: "Изменение статуса",
    accessorKey: "updatedAt",
    filterVariant: "date",
    filterFn: "betweenInclusive",
    muiFilterDatePickerProps: { format: "DD.MM.YYYY" },
    Cell: ({ cell }: any) =>
      format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
  },
];

type IntegrationLogsResponse = {
  code: number;
  data: {
    rows: any[];
    count: number;
  };
};

const IntegrationFlowLogs: React.FC<{ flowId: string }> = ({ flowId }) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const {
    data: { data: { rows, count } } = { data: { rows: [], count: 0 } },
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IntegrationLogsResponse>({
    placeholderData: keepPreviousData,
    queryKey: [
      "integration-logs-data",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await FlowService.getLogs({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters: [{ id: "flowId", value: flowId }],
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки логов: "${response.text}"`,
        );
      }

      // convert status codes to user-friendly names
      const rows = response.data.rows.map((row: any) => ({
        ...row,
        status: statusConvert(row.status),
      }));

      return { ...response, data: { ...response.data, rows } };
    },
  });

  const table = useMaterialReactTable({
    data: rows,
    columns: TABLE_COLUMNS,
    initialState: { density: "compact" },
    localization: MRT_Localization_RU,

    enableGrouping: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),

    rowCount: count,

    state: {
      columnFilters: filters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default IntegrationFlowLogs;
