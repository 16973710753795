import { useQuery } from "@tanstack/react-query";
import { Button, Form, Input, notification, Select, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import RoleService from "../../entities/model/RoleService";
import UserService from "../../entities/model/UserService";

type Props = {
  onSuccess?: () => void | Promise<void>;
};

export const AddUser: React.FC<Props> = ({ onSuccess }) => {
  type FormValues = {
    email: string;
    password: string;
    temporary: boolean;
    firstName: string;
    lastName: string;
    roles: string[];
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form] = useForm<FormValues>();

  const rolesQuery = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await RoleService.getList();

      if (response.code !== 0) {
        throw new Error(`Ошибка при загрузке ролей: ${response.text}`);
      }

      return response.data;
    },
  });

  const rolesOptions = React.useMemo(
    () =>
      rolesQuery.data?.map((role) => ({ label: role.name, value: role.id })),
    [rolesQuery.data],
  );

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);

    const response = await UserService.create(values);
    setIsLoading(false);

    if (response.code === 0) {
      notification.error({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
      return;
    }

    notification.success({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    await onSuccess?.();
  };

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onSubmit}
      >
        <Form.Item name="roles" label="Роль" initialValue={[]}>
          <Select
            mode="multiple"
            options={rolesOptions}
            loading={rolesQuery.isLoading}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Почта"
          rules={[
            { required: true, message: "Почта обязательна" },
            { type: "email", message: "Некорректная почта" },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name="firstName" label="Имя" initialValue={""}>
          <Input allowClear />
        </Form.Item>
        <Form.Item name="lastName" label="Фамилия" initialValue={""}>
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="password"
          label="Пароль"
          rules={[{ required: true, message: "Пароль обязателен" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="temporary"
          label="Временный пароль"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={isLoading}
          >
            Создать
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddUser;
