import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Select, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Props = {
  id: string;
};

type Option = {
  value: string;
  label: string;
};

type State = {
  chConn: string;
  chTable: string;
  formula: MappingField[];
};

const initialState: State = {
  chConn: "",
  chTable: "",
  formula: [],
};

const ClickhouseInput: React.FC<Props> = ({ id }) => {
  const { nodes, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.chConn = state.chConn;
          node.data.chTable = state.chTable;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.chConn = node.data.chConn;
          state.chTable = node.data.chTable;
          state.formula = node.data.formula;
        }),
      );

      getChConn();
    }
  }, [id]);

  const setChConn = (chConn: string) => {
    setState(
      produce((state) => {
        state.chConn = chConn;
      }),
    );
  };

  const setChTable = (chTable: string) => {
    setState(
      produce((state) => {
        state.chTable = chTable;
      }),
    );
  };

  const setFormula = (formula: MappingField[]) => {
    setState(
      produce((state) => {
        state.formula = formula;
      }),
    );
  };

  const [chConnOptions, setChConnOptions] = useState<Option[]>([]);
  const [chTableOptions, setChTableOptions] = useState<Option[]>([]);

  const getChConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "CLICKHOUSE" }],
    });
    setChConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const getChTable = async () => {
    if (!state.chConn) {
      return;
    }
    const getTables = await TransferService.getClickhouseTables(state.chConn);
    if (getTables.code === 1) {
      setChTableOptions(
        getTables.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getChTable();
  }, [state.chConn]);

  const getChField = async () => {
    if (!state.chConn || !state.chTable) {
      return;
    }
    const getFields = await TransferService.getClickhouseFields(
      state.chConn,
      state.chTable,
    );
    if (getFields.code === 1) {
      setFormula(
        getFields.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.columnName,
          type: getMappingField(el.dataType),
        })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getChField();
  }, [state.chTable]);

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение Clickhouse
          </div>
          <Select
            value={state.chConn}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setChConn(el)}
            options={chConnOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Таблица</div>
          <Select
            value={state.chTable}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setChTable(el)}
            options={chTableOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.formula?.length > 0 ? (
              state.formula.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default ClickhouseInput;

const getMappingField = (type: string) => {
  if (type.indexOf("String") !== -1) {
    return "STRING";
  }

  if (type.indexOf("Decimal") !== -1) {
    return "DECIMAL";
  }

  if (type.indexOf("Date") !== -1) {
    return "DATE";
  }

  return "STRING";
};
