const colors = {
  mapping: {
    default: "var(--Primary-600)",
    hover: "var(--Primary-400)",
  },
  filter: {
    default: "var(--Secondary-Maroon-600)",
    hover: "var(--Secondary-Maroon-400)",
  },
  join: {
    default: "var(--Secondary-Blue-600)",
    hover: "var(--Secondary-Blue-400)",
  },
  knowledgeSpace: {
    default: "var(--Secondary-Purple-600)",
    hover: "var(--Secondary-Purple-400)",
  },
  rabbitmq: {
    default: "var(--Secondary-Orange-600)",
    hover: "var(--Secondary-Orange-400)",
  },
  rowGen: {
    default: "var(--Secondary-Red-600)",
    hover: "var(--Secondary-Red-400)",
  },
  extrapolation: {
    default: "var(--Secondary-Maroon-600)",
    hover: "var(--Secondary-Maroon-400)",
  },
  analysis: {
    default: "var(--Secondary-Maroon-600)",
    hover: "var(--Secondary-Maroon-400)",
  },
  flatFile: {
    default: "var(--Secondary-Green-600)",
    hover: "var(--Secondary-Green-400)",
  },
  group: {
    default: "var(--Secondary-Teal-600)",
    hover: "var(--Secondary-Teal-400)",
  },
  postgres: {
    default: "var(--Secondary-Blue-600)",
    hover: "var(--Secondary-Blue-400)",
  },
  launchFlow: {
    default: "var(--Primary-Grey-600)",
    hover: "var(--Primary-Grey-400)",
  },
  clickhouse: {
    default: "var(--Secondary-Orange-600)",
    hover: "var(--Secondary-Orange-400)",
  },
  planx: {
    default: "var(--Secondary-Red-600)",
    hover: "var(--Secondary-Red-400)",
  },
  note: "#fdd89250",
};

const NODE_TYPE_TO_COLOR = {
  flatFile: "flatFile",
  targetFlatFile: "flatFile",
  join: "join",
  mapping: "mapping",
  filter: "filter",
  groupBy: "group",
  rowGen: "rowGen",
  knowledgeSpaceClassInput: "knowledgeSpace",
  knowledgeSpaceClassOutput: "knowledgeSpace",
  knowledgeSpaceDictionaryInput: "knowledgeSpace",
  planxInput: "planx",
  planxFigureOutput: "planx",
  planxOutput: "planx",
  postgresInput: "postgres",
  postgresOutput: "postgres",
  clickhouseInput: "clickhouse",
  clickhouseOutput: "clickhouse",
  note: "note",
  rabbitmq: "rabbitmq",
  launchFlow: "launchFlow",
  extrapolation: "extrapolation",
  analysis: "analysis",
};

export function nodeColor(node: any) {
  const key = NODE_TYPE_TO_COLOR[node.type];

  if (typeof key === "undefined") {
    console.warn(`Не найден ключ цвета ноды с типом "${node.type}"`);
    return "#ff0072";
  }

  const color = colors[key];
  if (typeof color === "undefined") {
    console.warn(`Не найден цвет ноды по ключу "${key}"`);
    return "#ff0072";
  }

  return color;
}

export default colors;
