import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Tooltip } from "antd";
import { format } from "date-fns";
import type {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import React, { RefObject, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DictionaryService from "../../entities/model/DictionaryService";
import DictionaryDeleteModal from "../../features/componets/DictionaryDeleteModal";
import DictionaryModal from "../../features/componets/DictionaryModal";
import DictionaryRecordImportModalOutside from "../../features/componets/DictionaryRecordImportModalOutside";
import Button from "../../shared/components/button";
import UserPopover from "../../shared/components/popovers/user";

const COLUMNS: MRT_ColumnDef<any>[] = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "Наименование",
    accessorKey: "name",
  },
  {
    header: "Описание",
    accessorKey: "description",
  },
  {
    header: "Кол-во записей",
    accessorKey: "rowCount",
    filterVariant: "range",
    filterFn: "betweenInclusive",
  },
  {
    header: "Создан",
    accessorKey: "createdBy",
    enableColumnFilter: false,
  },
  {
    header: "Дата создания",
    accessorKey: "createdAt",
    filterVariant: "date",
    filterFn: "betweenInclusive",
    muiFilterDatePickerProps: { format: "DD.MM.YYYY" },
    Cell: ({ cell }: any) =>
      format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
  },
  {
    header: "Изменен",
    accessorKey: "updatedBy",
    enableColumnFilter: false,
  },
  {
    header: "Дата изменения",
    accessorKey: "updatedAt",
    filterVariant: "date",
    filterFn: "betweenInclusive",
    muiFilterDatePickerProps: { format: "DD.MM.YYYY" },
    Cell: ({ cell }: any) =>
      format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
  },
];

const DictionaryPage: React.FC = () => {
  const modalRef: RefObject<any> = useRef();
  const importRef: RefObject<any> = useRef();
  const deleteModalRef: RefObject<any> = useRef();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const {
    data: { count = 0, rows = [] } = { count: 0, rows: [] },
    refetch,
    isLoading,
    isError,
    isRefetching,
  } = useQuery({
    queryKey: [
      "dictionaries",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await DictionaryService.getAll({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке словарей: "${response.text}"`,
        );
      }

      const rows = response.data.rows.map((item) => {
        item.createdBy = (<UserPopover userId={item.createdBy} />) as any;
        item.updatedBy = (<UserPopover userId={item.updatedBy} />) as any;
        return item;
      });

      return { count: response.data.count, rows };
    },
  });

  const table = useMaterialReactTable({
    columns: COLUMNS,
    data: rows,

    localization: MRT_Localization_RU,

    rowCount: count,

    initialState: { density: "compact" },

    state: {
      columnFilters: filters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    enableGrouping: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    groupedColumnMode: "remove",

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Обновить">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),

    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="data">
        <Link
          to={`/dictionary/data/${row.original.id}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          📋 Данные
        </Link>
      </MenuItem>,
      <MenuItem
        key="view"
        onClick={() => modalRef.current?.openEditModal(row.original)}
      >
        ⚙️ Настройки
      </MenuItem>,
      <MenuItem
        key="import"
        onClick={() => importRef.current?.openModal(row.original)}
      >
        📤 Импорт
      </MenuItem>,
      <MenuItem
        key="delete"
        onClick={() => deleteModalRef.current?.open(row.original)}
      >
        🚫 Удалить
      </MenuItem>,
    ],
  });

  return (
    <div className="pageAttribute">
      <div
        className="ManageTable"
        style={{ display: "flex", flexDirection: "row", gap: "15px" }}
      >
        <Link to="/dictionary/create">
          <Button type="primary" size="large" label="📁 Создать" />
        </Link>
        <Button
          label="🔄 Обновить"
          size="large"
          type="default"
          onClick={refetch}
        />
      </div>

      <MaterialReactTable table={table} />

      <DictionaryModal apiRef={modalRef} fetchData={refetch} />
      <DictionaryRecordImportModalOutside
        apiRef={importRef}
        fetchData={refetch}
      />
      <DictionaryDeleteModal apiRef={deleteModalRef} fetchData={refetch} />
    </div>
  );
};

export default DictionaryPage;
