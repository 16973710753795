import { Node, useReactFlow } from "@xyflow/react";
import { Button, message } from "antd";
import { useCallback } from "react";

export const ContextMenu = ({
  id,
  top,
  left,
  right,
  bottom,
  onView,
  historyRecord,
  ...props
}: any) => {
  const { getNode, setNodes, setEdges, getNodes, getEdge, getEdges } =
    useReactFlow();

  if (process.env.NODE_ENV === "development") {
    console.log({ nodes: getNodes(), edges: getEdges() });
  }

  const node: Node<any> | undefined = getNode(id);

  const onDelete = useCallback(() => {
    if (id === "0") {
      return void message.error("Нельзя удалить корневую ноду");
    }

    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) =>
      edges.filter((edge) => edge.source !== id && edge.target !== id),
    );
    historyRecord({
      node: getNodes(),
      edges: getEdges(),
      comment: "onNodeDelete",
    });
    message.warning(`Нода ${id} удалена`);
  }, [id, setNodes, setEdges]);

  return (
    <div
      style={{
        position: "sticky",
        top: top - 60,
        left: left - 120,
        right,
        bottom,
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "5px",
        borderRadius: "10px",
        background: "#222138",
        zIndex: 4,
        width: "200px",
      }}
      className="context-menu"
      {...props}
    >
      <p style={{ margin: "0.5em" }}>
        <small style={{ color: "white", fontWeight: "bold", fontSize: "14px" }}>
          {id}
        </small>
      </p>
      {node?.type !== "note" && (
        <Button type="primary" onClick={() => onView(node)}>
          Просмотр
        </Button>
      )}
      <Button type="default" danger onClick={onDelete}>
        Удалить
      </Button>
    </div>
  );
};
