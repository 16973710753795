import { format } from "date-fns";

export interface IVersion {
  id: string;
  name: string;
  description: string;
  base: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export class Version implements IVersion {
  id: string;
  name: string;
  description: string;
  base: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.base = model.base;
    this.createdAt = format(new Date(model.createdAt), "dd.MM.yyyy HH:mm:ss");
    this.updatedAt = format(new Date(model.updatedAt), "dd.MM.yyyy HH:mm:ss");
    this.createdBy = model.createdBy;
    this.updatedBy = model.updatedBy;
  }
}

export interface IVersionCreate {
  modelId: string;
  id: string;
  name: string;
  description: string;
  base: string;
  figures: string[];
}

export class VersionCreate implements IVersionCreate {
  modelId: string;
  id: string;
  name: string;
  description: string;
  base: string;
  figures: string[];

  constructor(model: any) {
    this.modelId = model.modelId;
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.base = model.base;
    this.figures = model.figures;
  }
}

export interface figureVersion {
  id: string;
  name: string;
  selected: boolean;
}
