import $api, { API_URL } from "../../shared/api/axios";
import { IBackResponse } from "../types/response";

type Role = {
  id: string;
  name: string;
  description?: string | undefined | null;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  catalogs: string[];
  filters: string[];
};

type Catalog = {
  id: string;
  name: string;
  description?: string | undefined | null;
};

export default class RoleService {
  static async getList(): Promise<IBackResponse<Role[]>> {
    const response = await $api.get(`${API_URL.xUser}/role/getlist`);
    return response.data;
  }

  static async getCatalogs(): Promise<IBackResponse<Catalog[]>> {
    const response = await $api.get(`${API_URL.xUser}/role/catalogs`);
    return response.data;
  }

  static async create(role: {
    id: string;
    name: string;
    description?: string | null | undefined;
    catalogs?: string[];
  }): Promise<IBackResponse<Catalog[]>> {
    const { id, name, description, catalogs = [] } = role;
    const response = await $api.post(`${API_URL.xUser}/role/create`, {
      id,
      name,
      description,
      catalogs,
    });
    return response.data;
  }

  static async update(role: {
    id: string;
    name: string;
    description?: string | null | undefined;
    catalogs?: string[];
  }): Promise<IBackResponse<undefined>> {
    const { id, name, description, catalogs = [] } = role;
    const response = await $api.post(`${API_URL.xUser}/role/update`, {
      id,
      name,
      description,
      catalogs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<IBackResponse<undefined>> {
    const response = await $api.post(`${API_URL.xUser}/role/delete`, { id });
    return response.data;
  }
}
