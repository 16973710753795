import { BackResponse, IBackResponse } from "../../entities/types/response";
import $api, { API_URL } from "../../shared/api/axios";

export default class JobService {
  static async getLogs(params: {
    page: number;
    size: number;
    filters: any[];
    sorting: any[];
  }): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xObject}/logs/get`, params);
    return new BackResponse(response.data);
  }

  static async getAll(
    filter: { field: string; operator: string; value: string | string[] }[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xObject}/logs/getall`, {
      filter,
    });
    return new BackResponse(response.data);
  }

  static async getOne(logUuid: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xObject}/logs/getone`, {
      logUuid,
    });
    return new BackResponse(response.data);
  }
}
