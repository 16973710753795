import { useQuery } from "@tanstack/react-query";
import { Button, Descriptions, Modal, Popover } from "antd";
import Result from "antd/lib/result";
import _ from "lodash";
import { useState } from "react";
import JobService from "../../../../entities/model/JobService";
import { Loader } from "../../loader";

const JobLogPopover: React.FC<{ uuid: string }> = ({ uuid }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const content = <JobLogPopoverContent uuid={uuid} />;

  return (
    <>
      <Modal
        title="Задание"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={[]}
      >
        {content}
      </Modal>
      <Popover
        content={<div style={{ width: "500px" }}>{content}</div>}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Задание</span>
            <Button type="link" onClick={() => setIsOpen(true)} children="📄" />
          </div>
        }
      >
        <span style={{ color: "blue", fontWeight: "bold" }}>{uuid}</span>
      </Popover>
    </>
  );
};

export default JobLogPopover;

const JobLogPopoverContent: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ["job-log-info", uuid],
    queryFn: async () => {
      const response = await JobService.getOne(uuid);

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при загрузке информации о логе uuid="${uuid}". ${response.text}`,
        );
      }

      if (_.isString(response.data.info)) {
        return [{ key: 0, children: response.data.info as any }];
      }

      return _.entries(response.data.info).map(([key, value], i) => ({
        key: i,
        label: key,
        children: value as any,
      }));
    },
  });

  if (isError) {
    return (
      <div style={{ zoom: "0.5" }}>
        <Result status="error" title="Не удалось загрузить информацию." />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "20px",
        }}
      >
        <Loader />
      </div>
    );
  }

  return <Descriptions size="small" column={1} title={uuid} items={data} />;
};
