import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Input, Modal, Popconfirm, Select, notification } from "antd";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import VersionService from "../../entities/model/VersionService";
import { IVersion } from "../../entities/types/IVersion";
import ButtonB1 from "../../shared/components/button";
import { fuzzyIsIn } from "../../shared/helper/comparison";
import ModalType from "../types/ModalType";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
  modelId: string;
}

function VersionModal({ apiRef, fetchData, modelId }: ComponentProps) {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [modalDescr, setModalDescr] = useState<string>("");
  const [modalAction, setModalAction] = useState<ModalType>();
  const [modalBase, setModalBase] = useState<string>("");

  const [state, setState] = useState<any[]>([]);
  const [baseVersions, setBaseVersions] = useState<
    { label: string; value: string }[]
  >([]);
  const [allFigures, setAllFigures] = useState<any[]>([]);
  const [optionsFigures, setOptionsFigures] = useState<any[]>([]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref5 = useRef(null);

  const fetchFigures = async () => {
    if (!modelId || modelId === "" || !modalID || modalID === "") {
      return;
    }
    const response = await VersionService.getFigures(modelId, modalID);
    console.log(response);
    if (response.code === 1) {
      setState(
        response.data.map((el: any) => ({
          id: el.figureId,
          uuid: uuidv4(),
          new: false,
        })),
      );
    }
  };

  const fetchVersions = async () => {
    if (!modelId || modelId === "") {
      return;
    }
    const response = await VersionService.getAll({ modelId });
    if (response.code === 1) {
      setBaseVersions([
        ...response.data.map((el: any) => ({ label: el.name, value: el.id })),
        { label: "Базовая", value: "BASE" },
      ]);
    }
  };

  useEffect(() => {
    fetchVersions();
  }, []);

  const handleCreate = async (event: any) => {
    event.preventDefault();
    const filterNew = state.filter((el) => el.new);
    for (const rec of filterNew) {
      const response = await VersionService.addFigure(modelId, modalID, rec.id);
      if (response.code === 1) {
        notification.success({
          message: `Показатель ${rec.id} успешно добавлен к версии ${modalID}`,
        });
        await fetchFigures();
      } else {
        notification.error({
          message: `Ошибка при попытке добавить показатель`,
        });
      }
    }
  };

  const handleDelete = async (figureId: string) => {
    const response = await VersionService.removeFigure(
      modelId,
      modalID,
      figureId,
    );
    console.log(response);
    if (response.code === 1) {
      notification.warning({
        message: `Показатель ${figureId} успешно отвязан от версии ${modalID}`,
      });
      await fetchFigures();
    } else {
      notification.error({ message: `Ошибка при попытке отвязки показателя` });
    }
  };

  const fetchAllFigures = async () => {
    if (!modelId || modelId === "" || !modalID || modalID === "") {
      return;
    }
    const response = await VersionService.getAvailableFigures(modelId, modalID);
    if (response.code === 1) {
      setAllFigures(
        response.data.map((el: any) => ({ label: el.name, value: el.id })),
      );
    }
  };

  useEffect(() => {
    fetchFigures();
    fetchAllFigures();
  }, [modelId, modalID]);

  const handleFiguresOptions = () => {
    const filter = allFigures.filter(
      (el) => !state.some((z) => z.id === el.value),
    );
    setOptionsFigures(filter);
  };

  useEffect(handleFiguresOptions, [allFigures, state]);

  const handleAdd = () => {
    setState((prev) => [...prev, { uuid: uuidv4(), id: "", new: true }]);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeNewId = (uuid: string, value: string) => {
    setState((prev: any) => {
      return prev.map((el: any) =>
        el.uuid === uuid ? { uuid, id: value, new: el.new } : el,
      );
    });
  };

  useImperativeHandle(apiRef, () => ({
    openEditModal: (model: string, version: IVersion) => {
      setModalID(version.id);
      setModalName(version.name);
      setModalDescr(version.description);
      setModalAction(ModalType.edit);
      setModalBase(version.base);
      setOpenModal(true);
    },
    closeModal: () => {
      setOpenModal(false);
      setModalID("");
      setModalName("");
      setModalDescr("");
    },
  }));

  return (
    <Modal
      open={openModal}
      onCancel={apiRef?.current?.closeModal}
      style={{ left: open ? "30%" : "0%" }}
      width={"700px"}
      footer={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <span style={{ fontSize: 18, fontWeight: 900 }}>
            Изменение версии
          </span>
        </Stack>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Идентификатор</span>
        <Input
          disabled={true}
          id="id"
          placeholder="Идентификатор"
          onInput={(e) =>
            setModalID((e.target as HTMLInputElement).value.toUpperCase())
          }
          size="large"
          value={modalID}
          ref={ref1}
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>Наименование</span>
        <Input
          id="name"
          placeholder="Наименование"
          onInput={(e) => setModalName((e.target as HTMLInputElement).value)}
          size="large"
          value={modalName}
          ref={ref2}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Описание</span>
        <Input
          id="description"
          placeholder="Описание"
          onInput={(e) => setModalDescr((e.target as HTMLInputElement).value)}
          value={modalDescr}
          size="large"
          ref={ref3}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Базовая версия</span>
        <Select
          id="base"
          placeholder="Базовая версия"
          options={baseVersions}
          value={modalBase}
          onChange={(e) => setModalBase(e)}
          size="large"
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>Показатели</span>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Показатель</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state &&
              state.map((el: any, ind: any) => (
                <TableRow key={ind}>
                  <TableCell>
                    <Select
                      placeholder="Выберите показатель"
                      optionFilterProp="children"
                      onChange={(e) => onChangeNewId(el.uuid, e)}
                      showSearch={true}
                      filterOption={(
                        input,
                        option?: { label: string; value: string },
                      ) => fuzzyIsIn(input, option?.label ?? "")}
                      style={{ width: "100%" }}
                      value={el.id}
                      disabled={!el.new}
                      options={el.new ? optionsFigures : allFigures}
                    />
                  </TableCell>
                  <TableCell style={{ width: "100px" }}>
                    <Popconfirm
                      title={`Удаление показателя из версии`}
                      description={`Вы действительно хотите отвязать показатель ${el.id}?`}
                      onConfirm={() => handleDelete(el.id)}
                      okText="Удалить"
                      cancelText="Отменить"
                    >
                      <ButtonB1
                        disabled={el.root}
                        size="middle"
                        type="dashed"
                        label={"❌"}
                      />
                    </Popconfirm>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button
          type="default"
          style={{ width: "100%" }}
          onClick={() => handleAdd()}
        >
          Добавить показатель
        </Button>
        <div
          ref={ref5}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <ButtonB1
            type="primary"
            label={modalAction === ModalType.create ? "➕ Создать" : "Изменить"}
            onClick={handleCreate}
          />
          <ButtonB1
            type="default"
            danger
            label="Отменить"
            onClick={apiRef?.current?.closeModal}
          />
        </div>
      </div>
    </Modal>
  );
}

export default VersionModal;
