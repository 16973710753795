import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Input, Popconfirm, notification } from "antd";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModelService from "../../../entities/model/ModelService";
import { IModel } from "../../../entities/types/IModel";
import { IBackResponse } from "../../../entities/types/response";
import ModelModal from "../../../features/componets/ModelModal";
import Button from "../../../shared/components/button";
import { Loader } from "../../../shared/components/loader";

const ModelPage: React.FC = () => {
  const [modelTable, setModelTable] = useState<IModel[]>([]);
  const modalRef: RefObject<any> = useRef();
  const deleteModalRef: RefObject<any> = useRef();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");

  const fetchData = async () => {
    setLoadingState(true);
    try {
      const list: IBackResponse = await ModelService.getAll();
      if (list) setModelTable(list.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingState(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onModelDelete = async (model: string) => {
    const response = await ModelService.delete(model);
    notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    fetchData();
  };

  return (
    <div className="pageAttribute">
      <div
        className="ManageTable"
        style={{ display: "flex", flexDirection: "row", gap: "15px" }}
      >
        <Button
          type="primary"
          size="large"
          label="📁 Создать"
          onClick={modalRef.current?.openCreateModal}
        />
        <Button
          label="🔄 Обновить"
          size="large"
          type="default"
          onClick={fetchData}
        />
      </div>
      <Input
        placeholder="Поиск"
        style={{ marginTop: "15px", marginBottom: "15px" }}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <TableContainer>
        {loadingState && <Loader />}
        {!loadingState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {modelTable &&
                modelTable
                  .filter((model) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      model.id.toLowerCase().includes(searchText) ||
                      model.name.toLowerCase().includes(searchText)
                    );
                  })
                  .map((el: IModel, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            to={`/model/view/${el.id}`}
                            style={{ fontWeight: "bold" }}
                          >
                            {el.id}
                          </Link>
                        </TableCell>
                        <TableCell>{el.name}</TableCell>
                        <TableCell>{el.description}</TableCell>
                        <TableCell>
                          <Popconfirm
                            title="Удаление модели"
                            description={
                              <>
                                <span>
                                  Вы действительно хотите удалить модель {el.id}
                                  ?
                                </span>
                                <br />
                                <span
                                  style={{ fontWeight: "bold", color: "red" }}
                                >
                                  ЭТО БЕЗВОЗВРАТНОЕ ДЕЙСТВИЕ, ВСЕ ДАННЫЕ БУДУТ
                                  УДАЛЕНЫ!
                                </span>
                              </>
                            }
                            onConfirm={() => onModelDelete(el.id)}
                            okText="⚠ Да, удалить"
                            okType={"danger"}
                            cancelText="Нет, отменить"
                          >
                            <Button type="primary" label="🗑 Удалить" />
                          </Popconfirm>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <ModelModal
        apiRef={modalRef}
        attributeTable={modelTable}
        fetchData={fetchData}
      />
    </div>
  );
};

export default ModelPage;
