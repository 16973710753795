import { Reorder } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  message,
  notification,
} from "antd";
import { useImperativeHandle, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  ResponderProvided,
} from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import FlatFileService from "../../../entities/model/FlatFileService";
import { IField } from "../../../entities/types/IFileFormat";
import { IBackResponse } from "../../../entities/types/response";
import { fuzzyIsIn } from "../../../shared/helper/comparison";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
}

export default function FFCreateModal({ apiRef, fetchData }: ComponentProps) {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);

  const [fields, setFields] = useState<IField[]>([]);

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setFields((prev: any) => {
      const temp = [...prev];
      const d = temp[result.destination!.index];
      temp[result.destination!.index] = temp[result.source.index];
      temp[result.source.index] = d;
      return temp;
    });
  };

  const handleDelete = (uuid: string) => {
    setFields((prev) => prev.filter((el) => el.uuid !== uuid));
  };

  const handleCreateField = () => {
    const uuidGen = uuidv4();

    if (fields) {
      setFields((prev) => [
        ...prev,
        { uuid: uuidGen, id: "", desc: "", type: "" },
      ]);
    } else {
      setFields([{ uuid: uuidGen, id: "", desc: "", type: "" }]);
    }
  };

  const onFinish = async (e: any) => {
    let counter: number = 1;
    if (!e.id) {
      return message.error("Не указан идентификатор");
    }

    if (!fields || fields.length === 0) {
      return message.error("Нужно хотя бы одно поле для создания формата");
    }

    for (const field of fields) {
      if (field.id === "") {
        return message.error(
          `Поле под номером ${counter} не содержит идентификатор`,
        );
      }
      if (field.type === "") {
        return message.error(`Для поля ${field.id} не выбран тип значений`);
      }
      counter++;
    }

    let response: IBackResponse = await FlatFileService.create(
      e.id,
      e.desc,
      e.tags,
      fields,
    );
    switch (response.code) {
      case 1:
        fetchData();
        apiRef.current?.closeCreateModal();
        return notification.success({
          message: "Формат файла создан",
          description: response?.text,
        });
      default:
        return notification.error({
          message: "Ошибка при создании формата файла",
          description: response?.text,
        });
    }
  };

  useImperativeHandle(apiRef, () => ({
    openCreateModal: () => {
      setOpenModal(true);
      form.resetFields();
      setFields([]);
    },
    closeCreateModal: () => {
      setOpenModal(false);
    },
  }));

  return (
    <Modal
      open={openModal}
      onCancel={() => {
        apiRef?.current?.closeCreateModal();
      }}
      footer={false}
      width={750}
    >
      <Form
        style={{
          width: "700px",
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
        initialValues={{ remember: true }}
        onFinish={(e) => onFinish(e)}
        form={form}
      >
        <Form.Item
          name="id"
          rules={[
            { required: true, message: "Пожалуйста, введите идентификатор" },
            {
              pattern: /^[A-Z][A-Z0-9]*$/,
              message:
                "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
            },
          ]}
        >
          <div>
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
              Введите идентификатор формата файла
            </span>
            <Input
              size="large"
              placeholder="Идентификатор формата файла"
              onInput={(e: any) =>
                (e.target.value = e.target.value.toUpperCase())
              }
            />
          </div>
        </Form.Item>
        <Form.Item name="desc">
          <div>
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
              Введите описание формата файла
            </span>
            <Input size="large" placeholder="Описание формата файла" />
          </div>
        </Form.Item>
        <Form.Item name="tags">
          <div>
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
              Через запятую перечислите теги (без пробелов)
            </span>
            <Select
              mode="tags"
              size="large"
              placeholder="Теги"
              style={{ width: "100%" }}
              onChange={(value) => {
                console.log({ value });
                form.setFieldValue("tags", value);
              }}
              tokenSeparators={[","]}
              showSearch={true}
              filterOption={(
                input,
                option?: { label: string; value: string },
              ) => fuzzyIsIn(input, option?.label ?? "")}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div>
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
              Укажите поля для формата файла
            </span>
            <Table style={{ width: "700px" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "5%" }}>&nbsp;</TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "30%",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20%",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "5%",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Действие
                  </TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {fields &&
                        fields.map((field: IField, index: number) => (
                          <Draggable
                            key={field.uuid}
                            draggableId={field.uuid}
                            index={index}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot,
                            ) => (
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <TableCell style={{ width: "5%" }}>
                                  <div {...draggableProvided.dragHandleProps}>
                                    <Reorder />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: "10px",
                                    height: "25px",
                                    width: "30%",
                                  }}
                                >
                                  <Input
                                    value={field.id}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setFields((prev) =>
                                        prev.map((f) => {
                                          if (f.uuid === field.uuid) {
                                            return { ...f, id: newValue };
                                          }
                                          return f;
                                        }),
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: "10px",
                                    height: "25px",
                                    width: "40%",
                                  }}
                                >
                                  <Input
                                    value={field.desc}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setFields((prev) =>
                                        prev.map((f) => {
                                          if (f.uuid === field.uuid) {
                                            return { ...f, desc: newValue };
                                          }
                                          return f;
                                        }),
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: "10px",
                                    height: "25px",
                                    width: "20%",
                                  }}
                                >
                                  <Select
                                    style={{ width: "100%" }}
                                    value={field.type}
                                    onChange={(e) => {
                                      const newValue = e;
                                      setFields((prev) =>
                                        prev.map((f: any) => {
                                          if (f.uuid === field.uuid) {
                                            return { ...f, type: newValue };
                                          }
                                          return f;
                                        }),
                                      );
                                    }}
                                    options={__optionType}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: "10px",
                                    height: "25px",
                                    width: "5%",
                                  }}
                                >
                                  <Popconfirm
                                    title={`Вы действительно хотите удалить поле ${field.id}?`}
                                    okText="Да"
                                    cancelText="Нет"
                                    onConfirm={() => handleDelete(field.uuid)}
                                  >
                                    <Button
                                      style={{
                                        width: "50px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      ❌
                                    </Button>
                                  </Popconfirm>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
            <Button
              type="dashed"
              style={{ marginTop: "5px", width: "700px" }}
              onClick={handleCreateField}
            >
              Добавить поле
            </Button>
          </div>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ backgroundColor: "#37AB49", width: "100%" }}
        >
          ➕ Создать
        </Button>
      </Form>
    </Modal>
  );
}

const __optionType = [
  {
    label: "Строка",
    options: [{ value: "STRING", label: "STRING" }],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата",
    options: [
      { value: "DATETIME", label: "DATETIME" },
      { value: "DATE", label: "DATE" },
    ],
  },
];
