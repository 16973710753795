import styled from "@emotion/styled";
import { useAppContext } from "../..";

const SContentContainer = styled.div({
  padding: "0px 24px 16px 0px",
  height: "100%",
  overflow: "hidden",
});

const SContent = styled.div({
  marginTop: "16px",
  backgroundColor: "var(--White)",
  height: "100%",
  borderRadius: "8px",
  padding: "16px",
  overflowY: "auto",
});

export const Content = ({ children }: { children: React.ReactNode }) => {
  const { content } = useAppContext();

  const component =
    content.type === "default" ? <SContent>{children}</SContent> : children;

  return <SContentContainer>{component}</SContentContainer>;
};

export default Content;
