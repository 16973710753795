import { Descriptions, DescriptionsProps, Popover } from "antd";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import AttributeService from "../../../../entities/model/AttributeService";
import UserService from "../../../../entities/model/UserService";
import UserPopover from "../user";

const AttributePopover: React.FC<{ attributeId: string }> = ({
  attributeId,
}) => {
  return (
    <Popover
      content={<AttributePopoverContent attributeId={attributeId} />}
      title="Атрибут"
    >
      <span style={{ color: "blue", fontWeight: "bold" }}>{attributeId}</span>
    </Popover>
  );
};

export default AttributePopover;

const AttributePopoverContent: React.FC<{ attributeId: string }> = ({
  attributeId,
}) => {
  const [items, setItems] = useState<DescriptionsProps["items"]>([]);

  const getInfo = async () => {
    const response = await AttributeService.getOne(attributeId);
    if (response.code === 1) {
      const createdBy = await UserService.getUsernameById(
        response.data.createdBy,
      );
      const updatedBy = await UserService.getUsernameById(
        response.data.updatedBy,
      );

      setItems([
        // {
        //     key: '1',
        //     label: 'Идентификатор',
        //     children: response.data.id,
        // },
        {
          key: "2",
          label: "Наименование",
          children: response.data.name,
        },
        {
          key: "3",
          label: "Описание",
          children: response.data.description ? response.data.description : "-",
        },
        {
          key: "4",
          label: "Тип",
          children: response.data.type,
        },
        {
          key: "5",
          label: "Ссылочный атрибут",
          children: response.data.reference ? response.data.reference : "-",
        },
        {
          key: "6",
          label: "Создал",
          children: <UserPopover userId={response.data.createdBy} />,
        },
        {
          key: "7",
          label: "Дата",

          children: format(
            new Date(response.data.createdAt),
            "dd.MM.yyyy HH:mm:ss",
          ),
        },
        {
          key: "8",
          label: "Изменил",
          children: <UserPopover userId={response.data.updatedBy} />,
        },
        {
          key: "9",
          label: "Дата",
          children: format(
            new Date(response.data.updatedAt),
            "dd.MM.yyyy HH:mm:ss",
          ),
        },
      ]);
    }
  };

  useEffect(() => {
    getInfo();
  }, [attributeId]);

  return (
    <Descriptions
      style={{ width: "350px" }}
      size="small"
      column={1}
      title={attributeId}
      items={items}
    />
  );
};
