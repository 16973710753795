import $api, { API_URL } from "../../shared/api/axios";
import { Attribute, IAttribute, IAttributeCreate } from "../types/IAttribute";
import { BackResponse, IBackResponse } from "../types/response";

export default class AttributeService {
  static async getAll(
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {
      page: undefined,
      size: undefined,
      filters: undefined,
      sorting: undefined,
    },
  ): Promise<IBackResponse> {
    const response: any = await $api.post(
      `${API_URL.xModel}/attribute/getList`,
      params,
    );
    response.data.data.rows = response.data.data.rows.map(
      (model: any) => new Attribute(model),
    );
    return response.data;
  }

  static async getAllModel(modelId: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/attribute/getlistmodel`,
      { modelId },
    );
    return new BackResponse(response.data);
  }

  static async assignedList(): Promise<
    { attribute: string; hierarchy: string; level: string }[]
  > {
    const response = await $api.get(`${API_URL.xModel}/attribute/assignedList`);

    return response.data.data;
  }

  static async update(attribute: IAttributeCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/attribute/update`, {
      attribute,
    });
    return new BackResponse(response.data);
  }

  static async create(attribute: IAttributeCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/attribute/create`, {
      attribute,
    });
    return new BackResponse(response.data);
  }

  static async delete(attribute: IAttribute): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/attribute/delete`, {
      attribute,
    });
    return new BackResponse(response.data);
  }

  static async getOne(attributeId: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/attribute/getone`, {
      attributeId,
    });
    return new BackResponse(response.data);
  }

  static async getDictionaries(attributeId: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/attribute/getdictionaries`,
      { attributeId },
    );
    return new BackResponse(response.data);
  }
}
