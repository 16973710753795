import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Select,
  Tour,
  TourProps,
  notification,
} from "antd";
import { Checkbox } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import ruRU from "antd/lib/locale/ru_RU";
import { MRT_ColumnDef } from "material-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import VersionService from "../../../entities/model/VersionService";
import { VersionCreate, figureVersion } from "../../../entities/types/IVersion";
import { SmallTable } from "../../../shared/components/table";
import { fuzzyIsIn } from "../../../shared/helper/comparison";

const VersionCreatePage: React.FC<{
  modelId: string;
  modalState: any;
  dataFetch: any;
}> = ({ modelId, modalState, dataFetch }) => {
  const [state, setState] = useState<figureVersion[]>([]);
  const [baseVersions, setBaseVersions] = useState<
    { label: string; value: string }[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const navigate = useNavigate();

  const [form] = useForm();

  const fetchFigures = async () => {
    const response = await VersionService.getAvailableFigures(modelId);
    if (response.code === 1) {
      setState(response.data);
    }
  };

  useEffect(() => {
    fetchFigures();
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    const response = await VersionService.getAll({ modelId });
    if (response.code === 1) {
      setBaseVersions([
        ...response.data.map((el: any) => ({ label: el.name, value: el.id })),
        { label: "Базовая", value: "BASE" },
      ]);
    }
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Наименование",
      accessorKey: "name",
    },
    {
      header: "Выбран",
      accessorKey: "selected",
      Cell: ({ renderedCellValue, row }) => (
        <Checkbox
          checked={getChecked(row)}
          onChange={(e) => toggleCheckbox(row, e, "selected")}
        />
      ),
    },
  ];

  const toggleCheckbox = (row: any, event: any, key: string) => {
    setState((prev: any) => {
      return prev.map((e: any) => {
        if (e.id === row.original.id)
          return { ...e, [key]: event.target.checked };
        else return e;
      });
    });
  };

  const getChecked = (row: any) => {
    return state.find((e: any) => e.id === row.original.id)?.selected;
  };

  const onFinish = async (e: any) => {
    const figures: any[] = state
      .filter((e: any) => e.selected)
      .map((e: any) => e.id);
    if (figures.length === 0) {
      return notification.error({
        message: "Необходимо выбрать хотя бы один атрибут для создания уровня",
      });
    }

    const toSend = new VersionCreate({
      modelId,
      id: e.id.toUpperCase(),
      name: e.name,
      description: e.description,
      base: e.base,
      figures: figures,
    });

    const response = await VersionService.create(toSend);

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    if (response.code === 1303) {
      modalState(false);
      dataFetch();
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "Введите идентификатор версии",
      description:
        "Идентификатор может содержать только латинские буквы и цифры.",
      target: () => ref1.current,
    },
    {
      title: "Введите наименование версии",
      description:
        'Это короткое и понятное название (например, "Альтернативная").',
      target: () => ref2.current,
    },
    {
      title: "Введите описание версии (опционально)",
      description:
        "Описание версии может позволяет описать бизнес и/или технические смыслы данной версии.",
      target: () => ref3.current,
    },
    {
      title:
        "Выберите базовую версию. Значения показателей, не включенных в данную версию будут браться из этой версии.",
      target: () => ref4.current,
    },
    {
      title:
        "Выберите те хранимые показатели, которые будут изменяться в рамках новой версии. Обратите внимание, что значения остальных хранимых показателей будут браться из выбранной базовой версии, а значения калькулируемых будут основываться на выбранных показателях.",
      target: () => ref5.current,
    },
    {
      title: "Не забудьте сохранить изменения!",
      target: () => ref6.current,
    },
  ];

  return (
    <div style={{ padding: 30 }}>
      <ConfigProvider locale={ruRU}>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          mask={false}
          type="primary"
          steps={steps}
        />
      </ConfigProvider>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setOpen(true)}
          type="primary"
          style={{ margin: 0 }}
        >
          🎓 Инструкция
        </Button>
      </div>
      <Form
        name="basic"
        style={{
          width: "750px",
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
        initialValues={{ remember: true }}
        onFinish={(e) => onFinish(e)}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="id"
          rules={[
            { required: true, message: "Пожалуйста, введите идентификатор" },
            {
              pattern: /^[A-Z][A-Z0-9]*$/,
              message:
                "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
            },
          ]}
          style={{ width: "50%" }}
        >
          <div
            ref={ref1}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
              Введите идентификатор версии *
            </span>
            <Input size="large" placeholder="Идентификатор версии" />
          </div>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "Пожалуйста, введите наименование" },
          ]}
          style={{ width: "50%" }}
        >
          <div
            ref={ref2}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
              Введите наименование версии *
            </span>
            <Input size="large" placeholder="Наименование версии" />
          </div>
        </Form.Item>
        <Form.Item name="description" style={{ width: "50%" }}>
          <div
            ref={ref3}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
              Введите описание версии
            </span>
            <Input size="large" placeholder="Описание версии" />
          </div>
        </Form.Item>
        <Form.Item style={{ width: "50%" }} name={"base"}>
          <div
            ref={ref4}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
              Выберите базовую версию
            </span>
            <Select
              disabled
              placeholder={"Базовая версия"}
              options={baseVersions}
              onChange={(e: string) => form.setFieldValue("base", e)}
              showSearch={true}
              filterOption={(
                input,
                option?: { label: string; value: string },
              ) => fuzzyIsIn(input, option?.label ?? "")}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            ref={ref5}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 5.</span>{" "}
              Добавьте показатели для версии
            </span>
            <SmallTable columns={columns} data={state} />
          </div>
        </Form.Item>
        <Form.Item>
          <div ref={ref6}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#37AB49", width: "750px" }}
            >
              ➕ Создать
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VersionCreatePage;
