import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Input, Modal, Popconfirm, Select, notification } from "antd";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AttributeService from "../../entities/model/AttributeService";
import DictionaryService from "../../entities/model/DictionaryService";
import ButtonB1 from "../../shared/components/button";
import { fuzzyIsIn } from "../../shared/helper/comparison";
import ModalType from "../types/ModalType";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
}

function DictionaryModal({ apiRef, fetchData }: ComponentProps) {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [modalDescr, setModalDescr] = useState<string>("");
  const [modalAction, setModalAction] = useState<ModalType>();

  const [dictAttributes, setDictAttributes] = useState<
    { id: string; root: boolean; new: boolean }[]
  >([]);
  const [allAttributes, setAllAttributes] = useState<
    { label: string; value: string }[]
  >([]);
  const [optionsAttributes, setOptionsAttributes] = useState<
    { label: string; value: string }[]
  >([]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref5 = useRef(null);

  const handleCreate = async (event: any) => {
    event.preventDefault();
    const filterNew = dictAttributes.filter((el) => el.new);
    for (const rec of filterNew) {
      const response = await DictionaryService.addAttribute(modalID, rec.id);
      console.log(response);
      if (response.code === 1) {
        notification.success({
          message: `Атрибут ${rec.id} успешно привязан к словарю ${modalID}`,
        });
        await fetchAttributes();
      } else {
        notification.error({ message: `Ошибка при попытке привязать атрибут` });
      }
    }
  };

  const handleDelete = async (attributeId: string) => {
    const response = await DictionaryService.removeAttribute(
      modalID,
      attributeId,
    );
    if (response.code === 1) {
      notification.warning({
        message: `Атрибут ${attributeId} успешно отвязан от словаря ${modalID}`,
      });
      await fetchAttributes();
    } else {
      notification.error({ message: `Ошибка при попытке отвязки атрибута` });
    }
  };

  const fetchAttributes = async () => {
    if (!modalID || modalID === "") {
      return;
    }
    const response = await DictionaryService.getColumns(modalID);
    if (response.code === 1) {
      setDictAttributes(
        response.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.attributeId,
          root: el.root,
          new: false,
        })),
      );
    }
  };

  useEffect(() => {
    fetchAttributes();
  }, [modalID]);

  const fetchAllAttributes = async () => {
    const response = await AttributeService.getAll();
    if (response.code === 1) {
      setAllAttributes(
        response.data.rows.map((el: any) => ({ label: el.name, value: el.id })),
      );
    }
  };

  useEffect(() => {
    fetchAllAttributes();
  }, []);

  const handleAttributeOptions = () => {
    const filter = allAttributes.filter(
      (el) => !dictAttributes.some((z) => z.id === el.value),
    );
    setOptionsAttributes(filter);
  };

  useEffect(handleAttributeOptions, [allAttributes, dictAttributes]);

  const handleAdd = () => {
    setDictAttributes((prev) => [
      ...prev,
      { uuid: uuidv4(), id: "", root: false, new: true },
    ]);
  };

  const onChangeNewId = (uuid: string, value: string) => {
    setDictAttributes((prev: any) => {
      return prev.map((el: any) =>
        el.uuid === uuid ? { uuid, id: value, root: el.root, new: el.new } : el,
      );
    });
  };

  useImperativeHandle(apiRef, () => ({
    openCreateModal: () => {
      setModalAction(ModalType.create);
      setOpenModal(true);
    },
    openEditModal: (row: any) => {
      setModalID(row.id);
      setModalName(row.name);
      setModalDescr(row.description);
      setModalAction(ModalType.edit);
      setOpenModal(true);
    },
    closeModal: () => {
      setOpenModal(false);
      setModalID("");
      setModalName("");
      setModalDescr("");
    },
  }));

  return (
    <Modal
      open={openModal}
      onCancel={apiRef?.current?.closeModal}
      style={{ left: open ? "30%" : "0%" }}
      width={"700px"}
      footer={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <span style={{ fontSize: 18, fontWeight: 900 }}>
            Изменение словаря
          </span>
        </Stack>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          Идентификатор словаря
        </span>
        <Input
          disabled={modalAction == ModalType.edit ? true : false}
          id="id"
          placeholder="Идентификатор"
          onInput={(e) =>
            setModalID((e.target as HTMLInputElement).value.toUpperCase())
          }
          size="large"
          value={modalID}
          ref={ref1}
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          Наименование словаря
        </span>
        <Input
          id="name"
          placeholder="Наименование"
          onInput={(e) => setModalName((e.target as HTMLInputElement).value)}
          size="large"
          value={modalName}
          ref={ref2}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Описание словаря</span>
        <Input
          id="description"
          placeholder="Описание"
          onInput={(e) => setModalDescr((e.target as HTMLInputElement).value)}
          value={modalDescr}
          size="large"
          ref={ref3}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Атрибуты</span>
        <span style={{ marginTop: -12, fontSize: 11 }}>
          * Изменение списка ключевых атрибутов происходит через создание нового
          словаря
        </span>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Атрибут</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Ключ</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dictAttributes &&
              dictAttributes.map((el: any, ind: any) => (
                <TableRow key={ind}>
                  <TableCell>
                    <Select
                      placeholder="Выберите атрибут"
                      optionFilterProp="children"
                      onChange={(e) => onChangeNewId(el.uuid, e)}
                      showSearch={true}
                      filterOption={(
                        input,
                        option?: { label: string; value: string },
                      ) => fuzzyIsIn(input, option?.label ?? "")}
                      style={{ width: "100%" }}
                      value={el.id}
                      disabled={!el.new}
                      options={el.new ? optionsAttributes : allAttributes}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "center",
                      fontSize: 20,
                    }}
                  >
                    {el.root ? "✅" : ""}
                  </TableCell>
                  <TableCell style={{ width: "100px" }}>
                    <Popconfirm
                      title={`Удаление атрибута`}
                      description={`Вы действительно хотите отвязать атрибут ${el.id}?`}
                      onConfirm={() => handleDelete(el.id)}
                      okText="Удалить"
                      cancelText="Отменить"
                    >
                      <ButtonB1
                        disabled={el.root}
                        size="middle"
                        type="dashed"
                        label={"❌"}
                      />
                    </Popconfirm>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button
          type="default"
          style={{ width: "100%" }}
          onClick={() => handleAdd()}
        >
          Добавить атрибут
        </Button>
        <div
          ref={ref5}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <ButtonB1
            type="primary"
            label={modalAction === ModalType.create ? "➕ Создать" : "Изменить"}
            onClick={handleCreate}
          />
          <ButtonB1
            type="default"
            danger
            label="Отменить"
            onClick={apiRef?.current?.closeModal}
          />
        </div>
      </div>
    </Modal>
  );
}

export default DictionaryModal;
