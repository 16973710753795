import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Input, Modal, Tag } from "antd";
import React, { useState } from "react";
import UserService from "../../entities/model/UserService";
import { IUserList } from "../../entities/types/IUser";
import Button from "../../shared/components/button";
import { Loader } from "../../shared/components/loader";
import AddUser from "./addUser";
import EditUser from "./editUser";

const UsersPage: React.FC = () => {
  const [editUserId, setEditUserId] = useState<number | undefined>(undefined);
  const [filterText, setFilterText] = useState<string>("");
  const [addModalState, setAddModalState] = useState<boolean>(false);

  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await UserService.getUserList();

      if (response.code !== 1) {
        throw new Error(`Ошибка при загрузке пользователей: ${response.text}`);
      }

      return response.data;
    },
  });

  const users: IUserList[] | undefined = usersQuery.data;

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>
            Всего пользователей: {usersQuery.isLoading ? "..." : users?.length}
          </span>
          <Button
            label="Добавить"
            type="primary"
            onClick={() => setAddModalState(true)}
          />
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        {usersQuery.isLoading && <Loader />}
        {!usersQuery.isLoading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Аватар</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>ИД</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Имя</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Фамилия</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Активирован
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Заблокирован
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Роли</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Фильтры</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users
                  .filter((user) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      user.id.toString().toLowerCase().includes(searchText) ||
                      user.name.toLowerCase().includes(searchText) ||
                      user.surname.toLowerCase().includes(searchText)
                    );
                  })
                  .map((el: IUserList) => (
                    <TableRow>
                      <TableCell>
                        {el.avatar ? (
                          <img src={el.avatar} width={70} alt={el.surname} />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell>{el.id}</TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.surname}</TableCell>
                      <TableCell>{el.email}</TableCell>
                      <TableCell>
                        {el.activated ? (
                          <Tag color="green">ДА</Tag>
                        ) : (
                          <Tag color="red">НЕТ</Tag>
                        )}
                      </TableCell>
                      <TableCell>
                        {el.blocked ? (
                          <Tag color="green">ДА</Tag>
                        ) : (
                          <Tag color="red">НЕТ</Tag>
                        )}
                      </TableCell>
                      <TableCell>
                        {el.roles.map((role: string) => (
                          <Tag color="orange-inverse">{role}</Tag>
                        ))}
                      </TableCell>
                      <TableCell>
                        {el.filters.map((filter: string) => (
                          <Tag>{filter}</Tag>
                        ))}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Button
                            type="dashed"
                            label="Изменить"
                            onClick={() => setEditUserId(el.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal
        title="Создание пользователя"
        open={addModalState}
        onCancel={() => setAddModalState(false)}
        footer={[]}
        destroyOnClose
      >
        <AddUser
          onSuccess={() => {
            setAddModalState(false);
            usersQuery.refetch();
          }}
        />
      </Modal>
      <Modal
        title="Редактирование пользователя"
        open={editUserId !== undefined}
        onCancel={() => setEditUserId(undefined)}
        footer={[]}
        destroyOnClose
      >
        {editUserId !== undefined && (
          <EditUser
            id={editUserId}
            onSuccess={() => {
              setEditUserId(undefined);
              usersQuery.refetch();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default UsersPage;
