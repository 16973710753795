import { Box, Modal } from "@mui/material";
import { notification } from "antd";
import { useImperativeHandle, useState } from "react";
import DictionaryService from "../../entities/model/DictionaryService";
import Button from "../../shared/components/button";
import modalStyle from "../consts/modalStyle";

interface modalProps {
  apiRef: any;
  fetchData: any;
}

function DictionaryDeleteModal({ apiRef, fetchData }: modalProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteAttribute, setDeleteAttribute] = useState<any>(null);

  useImperativeHandle(apiRef, () => ({
    open: (row: any) => {
      setDeleteAttribute(row);
      setModalOpen(true);
    },
    close: () => {
      setModalOpen(false);
    },
  }));

  const handleDelete = async (row: any) => {
    if (deleteAttribute === null) {
      notification.warning({
        message: "Произошла ошибка!",
        description: "Не был задан атрибут для удаления!",
      });
      setModalOpen(false);
      return;
    }

    const response = await DictionaryService.delete(row.id);
    apiRef.current?.close();
    fetchData();
    return notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  return (
    <Modal open={modalOpen} onClose={apiRef.current?.close}>
      <Box
        sx={{
          ...modalStyle,
          width: 400,
          height: 120,
          backgroundColor: "white",
        }}
      >
        <span style={{ fontSize: 18 }}>
          {`Вы действительно хотите безвозвратно удалить словарь `}
        </span>
        <span style={{ fontSize: 18, fontWeight: 900 }}>
          {deleteAttribute?.name} ({deleteAttribute?.id})?
        </span>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
          }}
        >
          <Button
            type="primary"
            danger
            label="Удалить"
            onClick={() => handleDelete(deleteAttribute)}
          />
          <Button
            type="default"
            label="Отмена"
            onClick={apiRef.current?.close}
          />
        </div>
      </Box>
    </Modal>
  );
}

export default DictionaryDeleteModal;
