import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import React, { useEffect } from "react";

import "@xyflow/react/dist/style.css";

import Dagre from "@dagrejs/dagre";
import { useNavigate, useParams } from "react-router-dom";

import FigureService from "../../../entities/model/FigureService";
import figureNode from "./figure";

// const initialNodes = [
//     { id: '1', type:'flow', position: { x: 0, y: 0 }, data: { label: '1' } },
//     { id: '2', type:'storage', position: { x: 300, y: 100 }, data: { label: '2' } },
// ];

// const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

const nodeColor = (node: any) => {
  switch (node.type) {
    case "flow":
      return "#779A19";
    case "storage":
      return "#E48600";
    case "transform":
      return "#1A66A6";
    case "supply":
      return "#D22E26";
    case "demand":
      return "#521D69";
    default:
      return "#ff0072";
  }
};

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
g.setGraph({ rankdir: "TB", align: "DR", ranksep: 100 });

const getLayoutedElements = (figures: any) => {
  if (!figures) return;

  // edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  // nodes.forEach((node) => g.setNode(node.id, node));
  const edges = figures
    .filter((fig: any) => fig.owner !== -1)
    .map((fig: any, i: any) => ({
      id: `e${fig.owner}-${fig.index}`,
      animated: true,
      source: fig.owner.toString(),
      target: fig.index.toString(),
    }));
  edges.forEach((edge: any) => g.setEdge(edge.source, edge.target));

  figures.forEach((node: any) =>
    g.setNode(node.index.toString(), {
      id: node.index.toString(),
      data: { label: node.figure },
      width: 450,
      height: 100,
      position: [0, 0],
    }),
  );

  Dagre.layout(g);

  return {
    nodes: figures.map((node: any, i: any) => {
      console.log(node);
      const { x, y } = g.node(node.index.toString());
      return {
        type: "figure",
        id: node.index.toString(),
        data: {
          figure: node.owner === -1 ? node.figure.id : node.figure,
          figureID: node.figure,
          figureName: node.figureName,
          endFigure: node.endFigure,
          background: node.owner === -1 ? "#779A19" : "#E48600",
        },
        position: { x, y },
      };
    }),
    edges,
  };
};

const LayoutFlow: React.FC<{ model: string; figure: string }> = ({
  model,
  figure,
}) => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    try {
      FigureService.visualize(model, figure).then((response) => {
        if (!response?.data) return;

        if (response.data.length === 0) return navigate(`/model/view/${model}`);

        const layouted = getLayoutedElements(response?.data);

        setNodes(layouted?.nodes);
        setEdges(layouted?.edges);

        window.requestAnimationFrame(() => {
          fitView();
        });
      });
    } catch (error) {
      console.error(error);
    }
  }, [model, figure]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={{ figure: figureNode }}
      fitView
      proOptions={{ hideAttribution: true }}
    >
      <Panel
        position="top-right"
        style={{
          display: "flex",
          gap: "15px",
          padding: 0,
          paddingRight: "45px",
        }}
      >
        <p style={{ fontSize: 20, marginLeft: 50 }}>
          <b>Идентификатор показателя:</b> {figure}
        </p>
      </Panel>
      <Controls />
      <MiniMap
        nodeColor={nodeColor}
        zoomable
        pannable
        className="B1FlowMiniMap"
        style={{ padding: 0 }}
      />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
  );
};

const FiguresVisualizePage: React.FC<{ model: string; figure: string }> = ({
  model,
  figure,
}) => {
  return (
    <div style={{ width: "100%", height: "700px" }}>
      <div
        style={{
          padding: "10px",
          paddingLeft: "25px",
          display: "flex",
          flexDirection: "row",
          gap: "15px",
        }}
      ></div>
      <ReactFlowProvider>
        <LayoutFlow model={model} figure={figure} />
      </ReactFlowProvider>
    </div>
  );
};

export default FiguresVisualizePage;
