import { createContext, useContext } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { historyStore } from "./undoRedo";

export const EXCEPTION_VARIABLE_UUID = "00000000-0000-0000-0000-000000000000";
export const NEW_INTEGRATION_ID = "new";

export const useICLibraryStore = create<Record<string, any>>()(
  immer((set) => ({
    isOpen: true,

    toggle: () =>
      set((state) => {
        state.isOpen = !state.isOpen;
      }),
  })),
);

export const ICContext = createContext({} as any);
export const useICState = () => useContext(ICContext);

export const HistoryContext = createContext(historyStore());
export const useHistory = () => useContext(HistoryContext);
