/**
 * Returns true if piece is in item.
 * Ignores spaces and case.
 * @param piece
 * @param item
 */
export const fuzzyIsIn = (piece: string, item: string): boolean => {
  const fmtPiece: string = piece.toLowerCase().replace(/\s/g, "");
  const fmtItem: string = item.toLowerCase().replace(/\s/g, "");
  return fmtItem.includes(fmtPiece);
};
