import { useQuery } from "@tanstack/react-query";
import { Button, Input, Select } from "antd";
import React from "react";
import RoleService from "../../entities/model/RoleService";

export type Props = {
  onSubmit: (data: {
    id: string;
    name: string;
    description?: string | null | undefined;
    catalogs: string[];
  }) => Promise<void> | void;
};

const Menu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {children}
    </div>
  );
};

const MenuItem: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {children}
    </div>
  );
};

export const CreateRole: React.FC<Props> = ({ onSubmit }) => {
  const idRef = React.useRef<any>(null);
  const nameRef = React.useRef<any>(null);
  const descriptionRef = React.useRef<any>(null);
  const catalogsRef = React.useRef<any>({});

  const onClick = async () => {
    if (
      idRef.current &&
      nameRef.current &&
      descriptionRef.current &&
      catalogsRef.current
    ) {
      await onSubmit({
        id: idRef.current.input.value,
        name: nameRef.current.input.value,
        description: descriptionRef.current.input.value,
        catalogs: catalogsRef.current.state,
      });
    }
  };

  const { data: catalogs } = useQuery({
    queryKey: ["catalogs"],
    queryFn: async () => {
      const response = await RoleService.getCatalogs();

      if (response.code !== 0) {
        throw new Error(`Ошибка при загрузке каталогов: ${response.text}`);
      }

      return response.data;
    },
  });

  return (
    <Menu>
      <MenuItem>
        <span style={{ fontWeight: "bold" }}>Идентификатор</span>
        <Input ref={idRef} placeholder="Идентификатор" />
      </MenuItem>
      <MenuItem>
        <span style={{ fontWeight: "bold" }}>Наименование</span>
        <Input ref={nameRef} placeholder="Наименование" />
      </MenuItem>
      <MenuItem>
        <span style={{ fontWeight: "bold" }}>Описание</span>
        <Input ref={descriptionRef} placeholder="Описание" />
      </MenuItem>
      <MenuItem>
        <span style={{ fontWeight: "bold" }}>Каталоги</span>
        <Select
          mode="multiple"
          placeholder="Каталоги"
          options={catalogs?.map(({ id }) => ({ value: id }))}
          onChange={(catalogs) => void (catalogsRef.current.state = catalogs)}
        />
      </MenuItem>
      <Button onClick={onClick} type="primary">
        Создать
      </Button>
    </Menu>
  );
};

export default CreateRole;
