export const links: {
  id: string;
  link: string;
  label: string;
  breadcrumbs: string[];
}[] = [
  {
    id: "2.1",
    link: "/model",
    label: "Модели",
    breadcrumbs: ["Модель данных", "Модели"],
  },
  {
    id: "2.2",
    link: "/attribute",
    label: "Атрибуты",
    breadcrumbs: ["Модель данных", "Атрибуты"],
  },
  {
    id: "2.3",
    link: "/hierarchy",
    label: "Иерархии",
    breadcrumbs: ["Модель данных", "Иерархии"],
  },
  {
    id: "2.4",
    link: "/dictionary",
    label: "Словари",
    breadcrumbs: ["Модель данных", "Словари"],
  },
  {
    id: "2.5",
    link: "/level",
    label: "Уровни",
    breadcrumbs: ["Модель данных", "Уровни"],
  },
  {
    id: "2.6",
    link: "/figure",
    label: "Показатели",
    breadcrumbs: ["Модель данных", "Показатели"],
  },
  {
    id: "2.7",
    link: "/operator",
    label: "Операторы",
    breadcrumbs: ["Модель данных", "Операторы"],
  },
  {
    id: "2.8",
    link: "/scdesign",
    label: "Дизайн цепи поставок",
    breadcrumbs: ["Цепь поставок"],
  },
  {
    id: "3.1",
    link: "/integration/source",
    label: "Интеграция: источники",
    breadcrumbs: ["Интеграция", "Источники"],
  },
  {
    id: "3.2",
    link: "/integration/task",
    label: "Интеграция: задания",
    breadcrumbs: ["Интеграция", "Задания"],
  },
  {
    id: "3.3",
    link: "/integration/fileformat",
    label: "Интеграция: формат файлов",
    breadcrumbs: ["Интеграция", "Формат файлов"],
  },
  {
    id: "3.4",
    link: "/integration/agent",
    label: "Интеграция: агенты",
    breadcrumbs: ["Интеграция", "Агенты"],
  },
  {
    id: "3.5",
    link: "/integration/logs",
    label: "Интеграция: логи",
    breadcrumbs: ["Интеграция", "Логи"],
  },
  {
    id: "3.6",
    link: "/integration/constructor",
    label: "Интеграция: конструктор",
    breadcrumbs: ["Интеграция", "Конструктор"],
  },
  {
    id: "4.1",
    link: "/role",
    label: "Роли",
    breadcrumbs: ["Администрирование", "Роли"],
  },
  {
    id: "4.2",
    link: "/user",
    label: "Пользователи",
    breadcrumbs: ["Администрирование", "Пользователи"],
  },
  {
    id: "4.3",
    link: "/import/file",
    label: "Импорт файлов",
    breadcrumbs: ["Администрирование", "Импорт файлов"],
  },
  {
    id: "4.4",
    link: "/import/logs",
    label: "Логи заданий",
    breadcrumbs: ["Администрирование", "Логи заданий"],
  },
  {
    id: "4.5",
    link: "/synchronization/logs",
    label: "Логи синхронизации",
    breadcrumbs: ["Администрирование", "Логи синхронизации"],
  },
  {
    id: "5.1",
    link: "/task/history",
    label: "Задания: история",
    breadcrumbs: ["Задания", "История"],
  },
  {
    id: "5.2",
    link: "/task/schedule",
    label: "Задания: расписание",
    breadcrumbs: ["Задания", "Расписание"],
  },
  {
    id: "5.3",
    link: "/task/template",
    label: "Задания: шаблоны",
    breadcrumbs: ["Задания", "Шаблоны"],
  },
  {
    id: "6",
    link: "/help",
    label: "Помощь",
    breadcrumbs: [],
  },
  {
    id: "7.1",
    link: "/viewer",
    label: "Просмотр данных",
    breadcrumbs: ["Приложения", "Просмотр данных"],
  },
  {
    id: "7.2",
    link: "/object-viewer",
    label: "Просмотр объектов",
    breadcrumbs: ["Приложения", "Просмотр объектов"],
  },
  {
    id: "901",
    link: "/settings",
    label: "Настройки пользователя",
    breadcrumbs: [],
  },
  {
    id: "dev",
    link: "/dev",
    label: "Инструменты разработчика",
    breadcrumbs: [],
  },
  {
    id: "1",
    link: "/",
    label: "Главная",
    breadcrumbs: [],
  },
];
