import { QuestionOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Tour,
  TourProps,
  notification,
} from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import { MRT_ColumnDef } from "material-react-table";
import React, { useEffect, useRef, useState } from "react";
import AttributeService from "../../entities/model/AttributeService";
import DictionaryService from "../../entities/model/DictionaryService";
import {
  DictionaryCreate,
  attributeDictionary,
} from "../../entities/types/IDictionary";
import { SmallTable } from "../../shared/components/table";

const DictionaryCreatePage: React.FC = () => {
  const [state, setState] = useState<attributeDictionary[]>([]);
  const [attributesState, setAttributesState] = useState<attributeDictionary[]>(
    [],
  );

  const [open, setOpen] = useState<boolean>(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const toggleCheckbox = (row: any, event: any, key: string) => {
    setAttributesState(
      attributesState.map((e: any) => {
        if (e.id === row.original.id)
          return { ...e, [key]: event.target.checked };
        else return e;
      }),
    );
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Наименование",
      accessorKey: "name",
    },
    {
      header: "Выбран",
      accessorKey: "selected",
      Cell: ({ renderedCellValue, row }) => (
        <Checkbox
          {...(renderedCellValue == "true" ? { checked: true } : {})}
          onChange={(e) => toggleCheckbox(row, e, "selected")}
        />
      ),
    },
    {
      header: "Ключ",
      accessorKey: "root",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <Checkbox
            {...(renderedCellValue == "true" ? { checked: true } : {})}
            onChange={(e) => toggleCheckbox(row, e, "root")}
          />
        );
      },
    },
  ];

  const fetchAttributes = async () => {
    const rawData = await AttributeService.getAll();
    const data: attributeDictionary[] = rawData.data.rows.map((el: any) => ({
      id: el.id,
      root: false,
      selected: false,
      name: el.name,
    }));

    setState(data);
    setAttributesState(data);
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  const onFinish = async (e: any) => {
    const attributes: attributeDictionary[] = attributesState.filter(
      (e: any) => e.selected,
    );
    if (attributes.length === 0) {
      return notification.error({
        message: "Необходимо выбрать хотя бы один атрибут для создания словаря",
      });
    }

    const toSend = new DictionaryCreate({
      id: e.id.toUpperCase(),
      name: e.name,
      description: e.description,
      attributes: attributes,
    });

    const response = await DictionaryService.create(toSend);

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    console.log(response);
    if (response.code === 1) {
      window.location.href = "/dictionary";
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "Введите идентификатор уровня",
      description: "Уровень может содержать только латинские буквы и цифры.",
      target: () => ref1.current,
    },
    {
      title: "Введите наименование уровня",
      description:
        "Это короткое и понятное название (например, Продукт | Клиент | Месяц.",
      target: () => ref2.current,
    },
    {
      title: "Перетащите в правую сторону необходимые атрибуты.",
      target: () => ref3.current,
    },
    {
      title:
        "После того как атрибуты были выбраны, расставьте их в порядке иерархичности",
      description:
        "Уровень 1 - самый детальный уровень. Атрибуты выше данного уровня считаются иерархичными более низкому уровню.",
      target: () => ref4.current,
    },
    {
      title: "Не забудьте сохранить изменения!",
      target: () => ref5.current,
    },
  ];

  return (
    <div style={{ padding: 30 }}>
      <ConfigProvider locale={ruRU}>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          mask={false}
          type="primary"
          steps={steps}
        />
      </ConfigProvider>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <div className="PageTitle">Создание словаря</div>
        <Button
          onClick={() => setOpen(true)}
          icon={<QuestionOutlined />}
          type="primary"
          style={{ margin: 0 }}
        >
          🎓 Инструкция
        </Button>
      </div>
      <Form
        name="basic"
        style={{
          width: "750px",
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
        initialValues={{ remember: true }}
        onFinish={(e) => onFinish(e)}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="id"
          rules={[
            { required: true, message: "Пожалуйста, введите идентификатор" },
            {
              pattern: /^[A-Z][A-Z0-9]*$/,
              message:
                "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
            },
          ]}
          style={{ width: "50%" }}
        >
          <div
            ref={ref1}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
              Введите идентификатор словаря
            </span>
            <Input size="large" placeholder="Идентификатор словаря" />
          </div>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "Пожалуйста, введите наименование" },
          ]}
          style={{ width: "50%" }}
        >
          <div
            ref={ref2}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
              Введите наименование словаря
            </span>
            <Input size="large" placeholder="Наименование словаря" />
          </div>
        </Form.Item>
        <Form.Item name="description" style={{ width: "50%" }}>
          <div
            ref={ref2}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
              Введите описание словаря
            </span>
            <Input size="large" placeholder="Описание словаря" />
          </div>
        </Form.Item>
        <Form.Item>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
              Выберите атрибуты и отметьте из них ключевые
            </span>
            <SmallTable columns={columns} data={state} />
          </div>
        </Form.Item>
        <Form.Item>
          <div ref={ref5}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#37AB49", width: "100%" }}
            >
              ➕ Создать
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DictionaryCreatePage;

const data = [
  { id: "id", name: "name", selected: "false", root: "false" },
  { id: "id2", name: "name2", selected: "false", root: "false" },
  { id: "id3", name: "name3", selected: "false", root: "true" },
];

export const TestTable: React.FC<any> = ({ columns, data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Идентификатор</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Наименование</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Выбран</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Ключ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <Checkbox defaultValue={row.selected} />
              </TableCell>
              <TableCell>
                <Checkbox defaultValue={row.root} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
