import { format } from "date-fns";

export interface ILevel {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export class Level implements ILevel {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.createdAt = format(new Date(model.createdAt), "dd.MM.yyyy HH:mm:ss");
    this.updatedAt = format(new Date(model.updatedAt), "dd.MM.yyyy HH:mm:ss");
    this.createdBy = model.createdBy;
    this.updatedBy = model.updatedBy;
  }
}

export interface LevelTableDto {
  id: string;
  name: string;
  attributes: number;
  ksuuid: string;
  created: string;
  updated: string;
}

export enum LevelPeriod {
  "Нет",
  "День",
  "Тех. Неделя",
  "Неделя",
  "Месяц",
  "Квартал",
  "Год",
}

export interface attributeLevel {
  attribute: string;
  name: string;
  selected: boolean;
  root: boolean;
}

export interface ILevelCreate {
  model: string;
  id: string;
  name: string;
  description: string;
  period: string;
  attributes: attributeLevel[];
}

export class LevelCreate implements ILevelCreate {
  model: string;
  id: string;
  name: string;
  description: string;
  period: string;
  attributes: attributeLevel[];

  constructor(model: any) {
    this.model = model.model;
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.period = model.period;
    this.attributes = model.attributes;
  }
}
