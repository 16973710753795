import $api, { API_URL } from "../../shared/api/axios";
import { BackResponse, IBackResponse } from "../types/response";

export default class ObjectService {
  static async getObjects(
    modelId: string,
    levelId: string,
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {
      page: undefined,
      size: undefined,
      filters: undefined,
      sorting: undefined,
    },
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/object/${modelId}/${levelId}/get`,
      params,
    );
    return new BackResponse(response.data);
  }

  static async deleteObjects(
    modelId: string,
    levelId: string,
    objects: Record<string, unknown>[],
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/object/${modelId}/${levelId}/delete`,
      objects,
    );
    return new BackResponse(response.data);
  }

  static async createObjects(
    modelId: string,
    levelId: string,
    objects: Record<string, unknown>[],
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/object/${modelId}/${levelId}/create`,
      objects,
    );
    return new BackResponse(response.data);
  }

  static async getAttributeValues(
    modelId: string,
    levelId: string,
    attributeId: string,
  ): Promise<IBackResponse> {
    const response = await $api.get(
      `${API_URL.xModel}/object/${modelId}/${levelId}/${attributeId}`,
    );
    return new BackResponse(response.data);
  }
}
