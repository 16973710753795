import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { useEffect } from "react";

import "@xyflow/react/dist/style.css";

import Dagre from "@dagrejs/dagre";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../shared/components/button";

import LevelService from "../../../entities/model/LevelService";
import attributeNode from "./attribute";

const nodeColor = (node: any) => {
  switch (node.type) {
    case "flow":
      return "#779A19";
    case "storage":
      return "#E48600";
    case "transform":
      return "#1A66A6";
    case "supply":
      return "#D22E26";
    case "demand":
      return "#521D69";
    default:
      return "#ff0072";
  }
};

const getLayoutedElements = (
  attributes: any,
): { nodes: Array<any>; edges: Array<any> } => {
  if (!attributes) return { nodes: [], edges: [] };

  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: "TB", ranksep: 200 });

  // edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  // nodes.forEach((node) => g.setNode(node.id, node));
  // const edges = attributes.filter((att:any) => att.children.length !== 0).map((att:any, i:any) => ({id: `e${att.index}-${att.index}`, animated: true, source: fig.owner.toString(), target: fig.index.toString()}));
  const edges = attributes
    .filter((att: any) => att.owners !== 0)
    .map((att: any) =>
      att.owners.map((attOwner: any) => ({
        id: `v${attOwner}-${att.index}`,
        visible: true,
        animated: true,
        source: attOwner.toString(),
        target: att.index.toString(),
        type: "vertical",
        sourceHandle: "b",
        targetHandle: "t",
      })),
    )
    .reduce((acc: any, e: any) => [...acc, ...e], []);
  attributes
    .filter((e: any) => e.children.length === 0)
    .forEach((att: any) =>
      edges.push({
        id: `h${att.index}-H`,
        visible: false,
        animated: true,
        source: att.index.toString(),
        target: `H${att.index}`,
        type: "vertical",
        sourceHandle: "b",
        targetHandle: "t",
      }),
    );

  edges.forEach((edge: any) => {
    if (edge.type == "vertical") g.setEdge(edge.source, edge.target);
  });

  attributes.forEach((node: any, i: any) =>
    g.setNode(node.index.toString(), {
      width: 450,
      height: 100,
      position: [0, 0],
    }),
  );

  attributes
    .filter((e: any) => e.children.length == 0)
    .forEach((node: any, i: any) => {
      g.setNode(`H${node.index}`, {
        width: 450,
        height: 100,
        position: [0, 0],
      });
    });

  Dagre.layout(g);

  attributes
    .filter((att: any) => att.horizontalAttributes.length !== 0)
    .forEach((att: any) => {
      att.hasHorizontal = true;
      att.horizontalAttributes.forEach(
        (attHor: any) => (attributes[attHor].hasHorizontal = true),
      );
    });

  edges.push(
    ...attributes
      .filter((att: any) => att.horizontalAttributes.length !== 0)
      .map((att: any) =>
        att.horizontalAttributes.map((attHorizontal: any) => ({
          id: `h${attHorizontal}-${att.index}`,
          visible: true,
          animated: false,
          type: "horizontal",
          sourceHandle: "r",
          targetHandle: "l",
          source:
            g.node(att.index.toString()).x > g.node(attHorizontal.toString()).x
              ? attHorizontal.toString()
              : att.index.toString(),
          target:
            g.node(att.index.toString()).x > g.node(attHorizontal.toString()).x
              ? att.index.toString()
              : attHorizontal.toString(),
        })),
      )
      .reduce((acc: any, e: any) => [...acc, ...e], []),
  );

  return {
    nodes: [
      ...attributes.map((node: any, i: any) => {
        const { x, y } = g.node(node.index.toString());

        return {
          type: "attribute",
          id: node.index.toString(),
          data: {
            type: "attribute",
            attribute: node.attribute,
            background: node?.hasHorizontal ? "#779A19" : "#E48600",
          },
          position: { x, y },
        };
      }),
      ...attributes
        .filter((e: any) => e.children.length === 0)
        .map((node: any, i: any) => {
          const { x, y } = g.node(`H${node.index}`);

          return {
            type: "attribute",
            id: `H${node.index}`,
            data: {
              type: "hierarchy",
              hierarchy: node.hierarchy,
              background: "#1A66A6",
            },
            position: { x, y },
          };
        }),
    ],
    edges: edges.filter((edge: any) => edges.visible),
  };
};

const LayoutFlow = () => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    try {
      LevelService.visualize(params.levelID).then((response) => {
        if (!response?.data) return;

        if (response.data.length === 0) return navigate("/level");

        const layouted: any = getLayoutedElements(response?.data);

        setNodes(layouted?.nodes);
        setEdges(layouted?.edges);

        // window.requestAnimationFrame(() => {
        //     // fitView();
        // });
      });
    } catch (error) {
      console.error(error);
    }
  }, [params.level]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={{ attribute: attributeNode }}
      fitView
    >
      <Panel position="top-left" style={{ display: "flex", gap: "15px" }}>
        <Button
          type={"default"}
          label="Назад"
          onClick={() => navigate("/level")}
        />
      </Panel>
      <Panel
        position="top-right"
        style={{
          display: "flex",
          gap: "15px",
          padding: 0,
          paddingRight: "45px",
        }}
      >
        <p style={{ fontSize: 20, marginLeft: 50 }}>
          <b>LEVEL:</b> {params.level}
        </p>
      </Panel>
      <Controls />
      <MiniMap
        nodeColor={nodeColor}
        zoomable
        pannable
        className="B1FlowMiniMap"
        style={{ padding: 0 }}
      />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
  );
};

const LevelVisualizePage = () => {
  return (
    <div style={{ width: "100%", height: "calc(100vh - 115px)" }}>
      <div
        style={{
          padding: "10px",
          paddingLeft: "25px",
          display: "flex",
          flexDirection: "row",
          gap: "15px",
        }}
      ></div>
      <ReactFlowProvider>
        <LayoutFlow />
      </ReactFlowProvider>
    </div>
  );
};

export default LevelVisualizePage;
