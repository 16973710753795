import styled from "@emotion/styled";
import { Button, Input } from "antd";
import message from "antd/lib/message";
import React, { useContext } from "react";
import { ReactComponent as CheckSolidIcon } from "../../../../assets/check_solid.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/clear.svg";
import { ReactComponent as EditIcon } from "../../../../assets/edit.svg";
import { ReactComponent as SaveIcon } from "../../../../assets/save.svg";
import { useICState } from "../state";
import IntegrationManager from "./utils/common";

export const Context = React.createContext<any>({});

export const SContainer = styled.div(
  () =>
    ({
      minWidth: "1400px", // FIXME: 1200px
      borderRadius: "16px",
      backgroundColor: "var(--White)",
      margin: 0,
      padding: 0,
      overflow: "hidden",
    }) as const,
);

export const SHeader = styled.div(
  ({ colors }: { colors: Record<string, string> }) =>
    ({
      padding: "24px",
      boxShadow: `0px -2px 0px 0px ${colors.primary} inset`,
      backgroundColor: colors.secondary,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }) as const,
);

export const SContent = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "column",
      height: "596px", // FIXME: 496px
      gap: "24px",
      padding: "24px",
      overflowY: "scroll",
    }) as const,
);

const SHeaderItems = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      gap: "4px",
      alignItems: "center",
    }) as const,
);

const SHeaderIcon = styled.div(
  ({
    colors,
    pointer = false,
  }: {
    colors: Record<string, string>;
    pointer?: boolean;
  }) =>
    ({
      width: "24px",
      height: "24px",
      cursor: pointer ? "pointer" : undefined,

      "*": {
        width: "100%",
        height: "100%",
        fill: colors.primary,
      },
    }) as const,
);

const SHeaderTitle = styled.div(
  () =>
    ({
      color: "var(--Black)",
      textTransform: "uppercase",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
    }) as const,
);

const SHeaderIdentifier = styled.div(
  ({ colors }: { colors: Record<string, string> }) =>
    ({
      color: `${colors.primary} !important`,
      textTransform: "uppercase",
      minWidth: "500px",
    }) as const,
);

export const Header = () => {
  const { nodes, setNodeId, setNodes, setEdges } = useICState();

  const { actions } = useContext(Context);

  const { title, colors, node } = React.useContext(Context);
  const id = node.id;

  const [nodeName, setNodeName] = React.useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] =
    React.useState<boolean>(false);

  const [isValid, setIsValid] = React.useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValid = value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/) !== null;

    setIsValid(isValid);

    if (!isValid) {
      message.warning("Вводимое наименование содержит недопустимые символы");
      return;
    }

    setNodeName(value);
  };

  const startEdit = () => {
    setEditNodeNameStatus(true);
  };

  const endEdit = () => {
    setEditNodeNameStatus(false);
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      setNodeId,
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  return (
    <SHeader colors={colors}>
      <SHeaderItems>
        <SHeaderIcon colors={colors}>
          <CheckSolidIcon />
        </SHeaderIcon>
        <SHeaderTitle>{title}</SHeaderTitle>
        <SHeaderIdentifier colors={colors}>
          {/* // FIXME: make width dynamic, currently constant */}
          <Input
            value={nodeName}
            variant={"borderless"}
            status={isValid ? "error" : undefined}
            disabled={!editNodeNameStatus}
            onChange={onChange}
            style={{ color: `${colors.primary} !important` }}
          />
        </SHeaderIdentifier>
        <SHeaderIcon
          colors={colors}
          pointer
          onClick={editNodeNameStatus ? endEdit : startEdit}
        >
          {editNodeNameStatus ? <SaveIcon /> : <EditIcon />}
        </SHeaderIcon>
      </SHeaderItems>
      <SHeaderIcon
        colors={colors}
        pointer
        onClick={() => {
          actions.close();
        }}
      >
        <ClearIcon />
      </SHeaderIcon>
    </SHeader>
  );
};

const SFooter = styled.div(
  () =>
    ({
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "1fr",
      gridColumnGap: "16px",
      gridRowGap: "0px",
      padding: "24px",
      height: "96px",
    }) as const,
);

export const Footer = () => {
  const { setOpen } = useICState();
  const { actions } = useContext(Context);

  return (
    <SFooter>
      <Button
        type="default"
        style={{ height: "40px" }}
        onClick={() => {
          actions.close();
        }}
      >
        Отмена
      </Button>
      <Button
        type="primary"
        style={{ height: "40px" }}
        onClick={() => {
          actions.save();
          setOpen(false);
        }}
      >
        ОК
      </Button>
    </SFooter>
  );
};
