import { message } from "antd";
import { Dispatch, SetStateAction } from "react";
import { v4 as uuidv4 } from "uuid";
import { MappingField } from "../utils";

class IntegrationFieldsManager {
  static addField(
    fields: any[],
    setFields: SetStateAction<any>,
    setFieldEditStatus: SetStateAction<any>,
  ) {
    const uuidGen = uuidv4();

    // Добавляем новую запись в массив
    if (!fields) {
      setFields([{ uuid: uuidGen, id: "", desc: "", type: "" }]);
    } else {
      setFields((prev: any) => [
        ...prev,
        { uuid: uuidGen, id: "", desc: "", type: "" },
      ]);
    }

    // Включаем в поле режим редактирования
    this.enableFieldEditMode(uuidGen, setFieldEditStatus);
  }

  static removeField(fieldUuid: string, setFields: SetStateAction<any>) {
    setFields((prev: any) => prev.filter((el: any) => el.uuid !== fieldUuid));
  }

  static enableFieldEditMode(
    fieldUuid: string,
    setFieldEditStatus: SetStateAction<any>,
  ) {
    setFieldEditStatus((prev: any) =>
      Array.from(new Set([...prev, fieldUuid])),
    );
  }

  static disableFieldEditMode(
    fieldUuid: string,
    fields: any[],
    fieldEditStatus: SetStateAction<any>,
  ) {
    const element = fields.find((el) => el.uuid === fieldUuid);

    if (element?.id === "") {
      message.error("Заполните идентификаторы для всех полей");
      return;
    }
    if (element?.type === "") {
      message.error("Заполните типы значений для всех полей");
      return;
    }

    fieldEditStatus((prev: any) => prev.filter((el: any) => el !== fieldUuid));
    message.success("Поле успешно изменено");
  }

  static moveFieldToTarget(
    fieldId: string,
    sourceFields: any,
    setTargetFields: SetStateAction<any>,
  ): void {
    const element = sourceFields.find((el: any) => el.id === fieldId);
    if (!element) {
      message.error(`Ошибка при попытке найти поле ${fieldId}`);
      return;
    }

    setTargetFields((prev: any[]) => {
      if (!prev) {
        return [{ ...element, formula: `${fieldId}`, uuid: uuidv4() }];
      }

      const field = prev.find((ele: any) => ele.id === fieldId);
      if (field) {
        if (field.type !== element.type) {
          message.error(
            `Невозможно смэппить ${fieldId}. Разные типы данных во входном и в выходном потоке`,
          );
          return prev;
        }

        const index = prev.indexOf(field);
        const head = prev.slice(0, index);
        const tail = prev.slice(index + 1);

        return [
          ...head,
          { ...element, formula: `${fieldId}`, uuid: uuidv4() },
          ...tail,
        ];
      }

      return [
        ...prev,
        {
          ...element,
          id: element.id.replaceAll(" ", ""),
          formula: `${fieldId}`,
          uuid: uuidv4(),
        },
      ];
    });
  }

  static mapFieldToTarget(
    id: string,
    sources: MappingField[],
    setTargetFields: Dispatch<SetStateAction<MappingField[]>>,
  ): void {
    const source = sources.find((source) => source.id === id);

    if (!source) {
      message.error(`Ошибка при попытке найти поле ${id}`);
      return;
    }

    setTargetFields((targets) => {
      if (targets.length === 0) return targets;

      const match = targets
        .filter((target) => !target.formula)
        .find((target) => target.id === source.id.replaceAll(" ", ""));

      return match
        ? targets.map((target) =>
            target.id === match.id ? { ...target, formula: source.id } : target,
          )
        : targets;
    });
  }

  static mapAllFieldsToRight({
    sourceFields: sources,
    setTargetFields,
  }: IMoveFieldsToRight) {
    sources.forEach((source) => {
      this.mapFieldToTarget(source.id, sources, setTargetFields);
    });
  }

  static moveAllFieldsToRight({
    sourceFields: sources,
    setTargetFields,
  }: IMoveFieldsToRight) {
    sources.forEach((source) => {
      this.moveFieldToTarget(source.id, sources, setTargetFields);
    });
  }

  static getSourceFieldStatus(fieldId: string, targetFields: any) {
    if (targetFields) {
      for (const targetField of targetFields) {
        if (targetField.formula) {
          // Создаем регулярное выражение для поиска fieldId как отдельного слова
          const regex = new RegExp(`\\b${fieldId}\\b`);
          const search = regex.test(targetField.formula);
          if (search) {
            return true;
          }
        }
      }
    }
    return false;
  }
}

export default IntegrationFieldsManager;

interface IMoveFieldsToRight {
  sourceFields: MappingField[];
  setTargetFields: Dispatch<SetStateAction<MappingField[]>>;
  silent?: boolean;
}
