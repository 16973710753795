import { Button as AntdButton } from "antd";
import React from "react";
import "./index.css";

interface IButton {
  label: string;
  type: "primary" | "link" | "text" | "default" | "dashed" | undefined;
  onClick?: any;
  danger?: boolean;
  size?: "small" | "middle" | "large" | undefined;
  disabled?: boolean;
}

const Button: React.FC<IButton> = ({
  label,
  type,
  onClick,
  danger = false,
  size = "large",
  disabled = false,
}) => {
  let ClassName: string;
  switch (type) {
    case "primary":
      ClassName = "xButton__primary";
      break;
    case "dashed":
      ClassName = "xButton__dashed";
      break;
    case "default":
      ClassName = "xButton__default";
      break;
    case "link":
      ClassName = "xButton__link";
      break;
    case "text":
      ClassName = "xButton__text";
      break;
    default:
      ClassName = "xButton__default";
      break;
  }
  return (
    <AntdButton
      type={type}
      onClick={onClick || undefined}
      danger={danger}
      size={size}
      className={ClassName}
      disabled={disabled}
    >
      {label}
    </AntdButton>
  );
};

export default Button;
