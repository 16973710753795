import { Select, notification } from "antd";
import { useEffect, useState } from "react";
import AttributeService from "../../../../entities/model/AttributeService";
import ModelService from "../../../../entities/model/ModelService";
import Button from "../../../../shared/components/button";
import { fuzzyIsIn } from "../../../../shared/helper/comparison";

interface IChangeAttributeDictionary {
  modelId: string;
  changeDictionaryAttribute: string | null;
  setChangeDictionaryModal: any;
  setChangeDictionaryAttribute: any;
  fetchAttributes: any;
}

const ChangeAttributeDictionary: React.FC<IChangeAttributeDictionary> = ({
  modelId,
  changeDictionaryAttribute,
  setChangeDictionaryModal,
  setChangeDictionaryAttribute,
  fetchAttributes,
}) => {
  const [selectedDictionary, setSelectedDictionary] = useState<string | null>(
    null,
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );

  const fetchOptions = async () => {
    if (changeDictionaryAttribute) {
      const response = await AttributeService.getDictionaries(
        changeDictionaryAttribute,
      );
      if (response.code === 1) {
        setOptions(
          response.data.map((el: any) => ({
            value: el.dictionaryId,
            label: el.dictionaryId,
          })),
        );
      } else {
        notification.error({
          message: response.text,
          description: `Код ошибки: ${response.code}`,
        });
      }
    }
  };

  const handleInitial = async () => {
    if (modelId && changeDictionaryAttribute && selectedDictionary) {
      const response = await ModelService.reassignAttribute(
        modelId,
        selectedDictionary,
        changeDictionaryAttribute,
      );
      if (response.code === 1) {
        notification.success({ message: "Успешно перепривязано" });
        setChangeDictionaryModal(false);
        setChangeDictionaryAttribute(null);
        setSelectedDictionary(null);
        fetchAttributes();
      } else {
        notification.error({
          message: response.text,
          description: `Код ошибки: ${response.code}`,
        });
      }
    } else {
      notification.error({
        message: "Не выбраны все параметры",
      });
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [changeDictionaryAttribute]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
      <span>
        Вы собираетесь переназначить атрибут{" "}
        <span style={{ fontWeight: "bold" }}>{changeDictionaryAttribute}</span>
      </span>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <span>Выберите новый словарь</span>
        <Select
          size="large"
          placeholder="Выберите словарь"
          style={{ width: "300px" }}
          options={options}
          value={selectedDictionary}
          onChange={(e) => setSelectedDictionary(e)}
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Button
          size="large"
          type={"primary"}
          label="Перепривязать"
          onClick={handleInitial}
        />
        <Button
          size="large"
          type="default"
          danger
          onClick={() => {
            setChangeDictionaryModal(false);
            setChangeDictionaryAttribute(null);
            setSelectedDictionary(null);
          }}
          label="Отменить"
        />
      </div>
    </div>
  );
};

export default ChangeAttributeDictionary;
