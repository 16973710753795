import $api, { API_URL } from "../../shared/api/axios";
import {
  Dictionary,
  IDictionary,
  IDictionaryCreate,
} from "../types/IDictionary";
import { BackResponse, IBackResponse } from "../types/response";

export default class DictionaryService {
  static async getAll(
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {
      page: undefined,
      size: undefined,
      filters: undefined,
      sorting: undefined,
    },
  ): Promise<IBackResponse<{ count: number; rows: IDictionary[] }>> {
    const response = await $api.post(
      `${API_URL.xModel}/dictionary/getList`,
      params,
    );
    response.data.data.rows = response.data.data.rows.map(
      (model: any) => new Dictionary(model),
    );
    return response.data;
  }

  static async getAllModel(modelId: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/dictionary/getListModel`,
      { modelId },
    );
    return new BackResponse(response.data);
  }

  static async update(dictionary: IDictionaryCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/update`, {
      dictionary,
    });
    return new BackResponse(response.data);
  }

  static async create(dictionary: IDictionaryCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/create`, {
      dictionary,
    });
    return new BackResponse(response.data);
  }

  static async delete(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/delete`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async getRecords(
    dictionary: string,
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {
      page: undefined,
      size: undefined,
      filters: undefined,
      sorting: undefined,
    },
  ): Promise<IBackResponse<{ count: number; rows: any[] }>> {
    const response = await $api.post(
      `${API_URL.xModel}/dictionary/getrecords/${dictionary}`,
      params,
    );
    return new BackResponse(response.data);
  }

  static async getColumns(dictionary: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/dictionary/getcolumns`,
      { dictionary },
    );
    return new BackResponse(response.data);
  }

  static async sendRecord(
    dictionary: string,
    row: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/addonerow`, {
      dictionary,
      row,
    });
    return new BackResponse(response.data);
  }

  static async deleteRecord(
    dictionary: string,
    row: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/deleterow`, {
      dictionary,
      row,
    });
    return new BackResponse(response.data);
  }

  static async addAttribute(
    dictionaryId: string,
    attributeId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/assign`, {
      dictionaryId,
      attributeId,
    });
    return new BackResponse(response.data);
  }

  static async removeAttribute(
    dictionaryId: string,
    attributeId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/dictionary/unassign`, {
      dictionaryId,
      attributeId,
    });
    return new BackResponse(response.data);
  }

  static async filterOptions(
    dictionaryId: string,
    attributeId: string,
    text: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/dictionary/columnoptions`,
      { dictionaryId, attributeId, text },
    );
    return new BackResponse(response.data);
  }
}
