import styled from "@emotion/styled";
import { Handle, useEdges, useNodeId } from "@xyflow/react";
import React, { useMemo } from "react";

import { ReactComponent as AddCircleIcon } from "../../../assets/add_circle.svg";
import { ReactComponent as AddCircleOutlineIcon } from "../../../assets/add_circle_outline.svg";
import { ReactComponent as NodeLinkIcon } from "../../../assets/node_link.svg";

type Props = {
  id: React.ComponentProps<typeof Handle>["id"];
  type: React.ComponentProps<typeof Handle>["type"];
  position: React.ComponentProps<typeof Handle>["position"];
  maxConnections?: number | undefined;
};

const SConnectorContainer = styled.div(
  ({ connected = false }: { connected?: boolean }) =>
    ({
      position: "relative",
      width: "18px",
      height: "18px",
      zIndex: 100000,

      "> :nth-child(1)": {
        opacity: connected ? 1 : 0.5,
      },

      ":hover > :nth-child(1)": !connected && {
        opacity: 1,
      },
      ":hover > :nth-child(1) > :nth-child(1)": !connected && {
        display: "none",
      },
      ":hover > :nth-child(1) > :nth-child(2)": !connected && {
        display: "unset",
      },

      "> :nth-child(1) > :nth-child(2)": !connected && {
        display: "none",
      },
    }) as const,
);

const SIcon = styled.div(
  () =>
    ({
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,

      "*": {
        width: "100%",
        height: "100%",
        fill: "var(--White)",
      },
    }) as const,
);

const SHandle = styled(Handle)(
  ({ connected = false }: { connected?: boolean }) =>
    ({
      transform: "unset",
      pointerEvents: "unset",
      minWidth: "unset",
      maxWidth: "unset",
      minHeight: "unset",
      maxHeight: "unset",
      border: "unset",
      background: "unset",

      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    }) as const,
);

export const Connector: React.FC<Props> = ({
  id,
  type,
  position,
  maxConnections = undefined,
}) => {
  const nodeId = useNodeId()!;
  const edges = useEdges();

  const connected = useMemo(() => {
    if (typeof maxConnections === "undefined") return false;
    const connections = edges.filter((edge) => edge[type] === nodeId);
    return connections.length >= maxConnections;
  }, [nodeId, edges, type, maxConnections]);

  const icon = (() => {
    if (connected) return <NodeLinkIcon />;

    return (
      <>
        <AddCircleOutlineIcon />
        <AddCircleIcon />
      </>
    );
  })();

  return (
    <SConnectorContainer connected={connected}>
      <SIcon>{icon}</SIcon>
      <SHandle
        id={id}
        type={type}
        position={position}
        isConnectable={!connected}
        connected={connected}
      />
    </SConnectorContainer>
  );
};

export default Connector;
