import { Box, Modal, Stack, TextField } from "@mui/material";
import { ConfigProvider, Tour, TourProps, notification } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import ModelService from "../../entities/model/ModelService";
import { ModelCreate } from "../../entities/types/IModel";
import { IBackResponse } from "../../entities/types/response";
import Button from "../../shared/components/button";
import modalStyle from "../consts/modalStyle";
import ModalType from "../types/ModalType";

interface ComponentProps {
  apiRef: any;
  attributeTable: any;
  fetchData: any;
}

function ModelModal({ apiRef, attributeTable, fetchData }: ComponentProps) {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [modalDescr, setModalDescr] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [modalAction, setModalAction] = useState<ModalType>();

  const [modalIDStatus, setModalIDStatus] = useState<boolean>();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const status =
      modalID === "" ? undefined : /^[A-Z][A-Z0-9]*$/.test(modalID);
    setModalIDStatus(status);
  }, [modalID]);

  const handleCreate = async (event: any) => {
    event.preventDefault();
    const attribute = new ModelCreate({
      id: modalID,
      name: modalName,
      description: modalDescr,
    });
    let response: IBackResponse =
      modalAction === ModalType.create
        ? await ModelService.create(attribute)
        : await ModelService.update(attribute);
    apiRef.current?.closeModal();
    fetchData();
    return notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  const steps: TourProps["steps"] = [
    {
      target: () => ref1.current,
      title: "Введите идентификатор модели",
      description:
        "Индификатор должен содержать только латинские буквы и цифры от 0 до 9.",
    },

    {
      target: () => ref2.current,
      title: "Введите наименование модели",
      description: `Краткое наименование, отражающее ее суть (например, "Песочница" или "Продуктив"). Доступна любая форма наименования.`,
    },

    {
      target: () => ref3.current,
      title: "Введите краткое описание атрибута",
      description:
        "Оно поможет в дальнейшем не потерять бизнес или технический смысл созданной модели.",
    },
    {
      target: () => ref5.current,
      title: "Не забудьте нажать сохранить!",
      description: "Иначе модель не будет создана.",
    },
  ];

  useImperativeHandle(apiRef, () => ({
    openCreateModal: () => {
      setModalAction(ModalType.create);
      setOpenModal(true);
    },
    openEditModal: (row: any) => {
      setModalID(row.id);
      setModalName(row.name);
      setModalDescr(row.description);
      setModalType(row.type);
      setModalAction(ModalType.edit);
      setOpenModal(true);
    },
    closeModal: () => {
      setOpenModal(false);
      setModalID("");
      setModalName("");
      setModalDescr("");
      setModalType("");
    },
  }));

  return (
    <Modal
      open={openModal}
      onClose={apiRef?.current?.closeModal}
      style={{ left: open ? "30%" : "0%" }}
    >
      <Box
        sx={{
          ...modalStyle,
          width: 400,
          height: 360,
          backgroundColor: "white",
        }}
      >
        <ConfigProvider locale={ruRU}>
          <Tour
            open={open}
            onClose={() => setOpen(false)}
            mask={false}
            type="primary"
            steps={steps}
            zIndex={1000000}
            placement={"left"}
          />
        </ConfigProvider>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            marginTop: 10,
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <span style={{ fontSize: 18, fontWeight: 900 }}>
              Создание модели
            </span>

            <Button
              label="🎓 Инструкция"
              type="dashed"
              onClick={() => setOpen(true)}
            />
          </Stack>
          <TextField
            disabled={modalAction === ModalType.edit ? true : false}
            id="id"
            label="Идентификатор"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onInput={(e) =>
              setModalID((e.target as HTMLInputElement).value.toUpperCase())
            }
            defaultValue={modalID}
            ref={ref1}
            error={modalIDStatus === false}
          ></TextField>

          <TextField
            id="name"
            label="Наименование"
            onInput={(e) => setModalName((e.target as HTMLInputElement).value)}
            defaultValue={modalName}
            ref={ref2}
          ></TextField>
          <TextField
            id="description"
            label="Описание"
            onInput={(e) => setModalDescr((e.target as HTMLInputElement).value)}
            defaultValue={modalDescr}
            ref={ref3}
          ></TextField>
          <div
            ref={ref5}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              label={
                modalAction === ModalType.create ? "➕ Создать" : "Изменить"
              }
              onClick={handleCreate}
            />
            <Button
              type="default"
              danger
              label="Отменить"
              onClick={apiRef?.current?.closeModal}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModelModal;
