import { Button, Drawer, Input } from "antd";
import { MouseEventHandler } from "react";

interface IIntegrationFlowCopy {
  open: boolean;
  onClose: any;

  flowToCopyName: string | null | undefined;
  setNewFlowName: any;

  launchCopy: MouseEventHandler<HTMLElement> | undefined;
}

const IntegrationFlowCopy: React.FC<IIntegrationFlowCopy> = ({
  open,
  onClose,
  flowToCopyName,
  setNewFlowName,
  launchCopy,
}) => (
  <Drawer title="Копирование потока" open={open} onClose={onClose}>
    <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
      <span>
        Вы собираетесь скопировать поток{" "}
        <span style={{ fontWeight: "bold" }}>{flowToCopyName}</span>
      </span>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <span>Введите наименование нового потока</span>
        <Input
          size="large"
          placeholder="Наименование нового потока"
          onChange={(e) => setNewFlowName(e.target.value)}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Button size="large" type={"primary"} onClick={launchCopy}>
          Скопировать
        </Button>
        <Button size="large" danger onClick={onClose}>
          Отменить
        </Button>
      </div>
    </div>
  </Drawer>
);

export default IntegrationFlowCopy;
