export interface IDictionary {
  id: string;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export class Dictionary implements IDictionary {
  id: string;
  name: string;
  type: string;
  rowCount: number;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.type = model.type;
    this.rowCount = model.numRecords;
    this.createdAt = model.createdAt;
    this.updatedAt = model.updatedAt;
    this.createdBy = model.createdBy;
    this.updatedBy = model.updatedBy;
  }
}

export interface IDictionarySimple {
  id: string;
  name: string;
}

export class DictionarySimple implements IDictionarySimple {
  id: string;
  name: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
  }
}

export interface attributeDictionary {
  attribute: string;
  name: string;
  selected: boolean;
  root: boolean;
}

export interface IDictionaryCreate {
  id: string;
  name: string;
  description: string;
  attributes: attributeDictionary[];
}

export class DictionaryCreate implements IDictionaryCreate {
  id: string;
  name: string;
  description: string;
  attributes: attributeDictionary[];

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.attributes = model.attributes;
  }
}
