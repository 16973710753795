import { Spin, Typography } from "antd";
import { FC } from "react";
import LoaderGif from "./loader.gif";

const { Text } = Typography;

const LoadingSpinner: FC = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Spin size="large" indicator={<img src={LoaderGif} />} />
      <Text style={{ paddingTop: 8, fontWeight: "bolder" }}>
        Загружаемся...
      </Text>
    </div>
  );
};

export const Loader: FC = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Spin size="large" indicator={<img src={LoaderGif} />} delay={150} />
    </div>
  );
};

export default LoadingSpinner;

export const LoaderFullScreen: FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <Spin
        size="large"
        indicator={<img src={LoaderGif} />}
        delay={150}
        fullscreen
      />
    </div>
  );
};
