import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Modal, Popconfirm, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { fuzzyIsIn } from "../../shared/helper/comparison";

type Props = {
  state: boolean;
  columns: { id: string; name: string }[];
  values: { [id: string]: unknown[] };
  onOk: ({
    objects,
  }: {
    objects: Record<string, string>[];
  }) => Promise<void> | void;
  onCancel: () => void;
};

const CreateMenu: React.FC<Props> = ({
  state,
  onOk,
  onCancel,
  columns,
  values,
}) => {
  const [objects, setObjects] = useState<Record<string, string>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // reset component state when the modal changes state
  useEffect(() => setObjects([]), [columns, state]);

  const setValue = useCallback(
    (uuid: string, key: string, value: string) =>
      setObjects((rows) =>
        rows.map((row) => (row.uuid === uuid ? { ...row, [key]: value } : row)),
      ),
    [],
  );

  const addEmptyRow = useCallback(
    () => setObjects((rows) => [...rows, { uuid: uuid() }]),
    [],
  );
  const removeRow = useCallback(
    (uuid: string) =>
      setObjects((rows) => rows.filter((row) => row.uuid !== uuid)),
    [],
  );

  const onOkClick = async () => {
    setLoading(true);
    await onOk({ objects });
    setLoading(false);
  };

  return (
    <Modal
      title="Добавить объекты планирования"
      open={state}
      onOk={onOkClick}
      onCancel={onCancel}
      width="1000px"
      confirmLoading={loading}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ id, name }) => (
              <TableCell key={id} style={{ fontWeight: "bold" }}>
                {name}
              </TableCell>
            ))}
            <TableCell style={{ width: "20px" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {objects.map((row) => (
            <TableRow key={row.uuid}>
              {columns.map(({ id }) => (
                <TableCell key={id}>
                  <Select
                    style={{ width: "100%" }}
                    value={row[id]}
                    options={
                      values[id].map((value) => ({
                        label: value,
                        value,
                      })) as any
                    }
                    onChange={(value) => setValue(row.uuid, id, value)}
                    showSearch={true}
                    filterOption={(
                      input,
                      option?: { label: string; value: string },
                    ) => fuzzyIsIn(input, option?.label ?? "")}
                  />
                </TableCell>
              ))}
              <TableCell
                style={{
                  padding: "10px",
                  height: "25px",
                  width: "min-content",
                }}
              >
                <Popconfirm
                  title={`Вы действительно хотите удалить поле?`}
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => removeRow(row.uuid)}
                >
                  <Button style={{ padding: "5px" }}>❌</Button>
                </Popconfirm>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        type="dashed"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={addEmptyRow}
      >
        Добавить объект планирования
      </Button>
    </Modal>
  );
};

export default CreateMenu;
