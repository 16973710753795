import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Input, Select, notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import AttributeService from "../../entities/model/AttributeService";
import { AttributeCreate, IAttribute } from "../../entities/types/IAttribute";
import { IBackResponse } from "../../entities/types/response";
import Button from "../../shared/components/button";
import { fuzzyIsIn } from "../../shared/helper/comparison";

type Props = {
  id: string;
  onSuccess: () => void;
  onCancel: () => void;
};

function EditAttribute({ id, onSuccess, onCancel }: Props) {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [reference, setReference] = useState<string>("");

  const onChange = async (event: any) => {
    event.preventDefault();

    const attribute = new AttributeCreate({
      id: id,
      name: name,
      description: description,
      type: type,
      reference: reference,
    });

    const response: IBackResponse = await AttributeService.update(attribute);

    if (response.code !== 1008) {
      return notification.error({
        message: "Ошибка при изменении атрибута",
        description: response?.text,
      });
    }

    notification.success({
      message: "Атрибут изменен",
      description: response?.text,
    });

    onSuccess();
  };

  const { data: attribute } = useQuery<IAttribute>({
    retry: false,
    queryKey: ["attribute", id],
    queryFn: async () => {
      const response = await AttributeService.getOne(id);

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке атрибута: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  useEffect(() => {
    if (typeof attribute !== "undefined") {
      setName(attribute.name);
      setDescription(attribute.description);
      setType(attribute.type);
      setReference(attribute.reference);
    }
  }, [attribute]);

  const { data = [] } = useQuery({
    retry: false,
    queryKey: ["attributes"],
    queryFn: async () => {
      const response = await AttributeService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке атрибутов: "${response.text}"`,
        );
      }

      return response.data.rows;
    },
  });

  const attributes = useMemo(
    () => data.map(({ id, name }: any) => ({ label: name, value: id })),
    [data],
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <span style={{ fontSize: 18, fontWeight: 900 }}>
            Изменение атрибута
          </span>
        </Stack>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
          Введите идентификатор атрибута
        </span>
        <Input
          id="id"
          value={id}
          disabled={true}
          placeholder="Идентификатор"
          size="large"
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
          Введите наименование атрибута
        </span>
        <Input
          id="name"
          placeholder="Наименование"
          value={name}
          onInput={(e) => setName((e.target as HTMLInputElement).value)}
          size="large"
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
          Введите описание атрибута
        </span>
        <Input
          id="description"
          placeholder="Описание"
          value={description}
          onInput={(e) => setDescription((e.target as HTMLInputElement).value)}
          size="large"
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
          Выберите тип атрибута
        </span>
        <Select
          optionFilterProp="children"
          disabled={true}
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
          options={[{ label: "Строка", value: "STRING" }]}
          onChange={(e) => setType(e)}
          value={type}
          size="large"
          placeholder="Тип атрибута"
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 5.</span> Если
          применимо, выберите связанный атрибут
        </span>
        <Select
          optionFilterProp="children"
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
          onChange={(e) => setReference(e)}
          options={attributes}
          value={reference}
          size="large"
          placeholder="Связанный атрибут"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <Button type="primary" label="✏ Изменить" onClick={onChange} />
          <Button type="default" danger label="Отменить" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}

export default EditAttribute;
