import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Ballot } from "../../assets/ballot.svg";
import { ReactComponent as Dictionaries } from "../../assets/dictionaries.svg";
import { ReactComponent as DocumentCSV } from "../../assets/document_csv.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import { ReactComponent as IntegrAgents } from "../../assets/integr_agents.svg";
import { ReactComponent as LogData } from "../../assets/log_data.svg";
import { ReactComponent as LogIntegr } from "../../assets/log_integr.svg";
import { ReactComponent as LogSinch } from "../../assets/log_sinch.svg";
import { ReactComponent as People } from "../../assets/people.svg";
import { ReactComponent as PersonSettings } from "../../assets/person_settings.svg";
import { ReactComponent as Scenario } from "../../assets/scenario.svg";
import { ReactComponent as SourcesIntegr } from "../../assets/sources_integr.svg";
import { ReactComponent as TaskManager } from "../../assets/task_manager.svg";
import { ReactComponent as ViewData } from "../../assets/view_data.svg";
import { ReactComponent as ViewObjects } from "../../assets/view_objects.svg";

const SHomePage = styled.div({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  gap: "16px",
  height: "100%",
});

const SMenuGroupHeader = styled.div({
  fontSize: "16px",
  fontWeight: "600",
  color: "var(--Primary-Grey-200)",
});

const SMenuGroup = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const SMenuGroupItems = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  columnGap: "16px",
  rowGap: "8px",
});

const SItem = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
  height: "80px",
  width: "368px",
  borderRadius: "8px",
  padding: "16px 24px",
  backgroundColor: "var(--Primary-010)",
  ":hover": {
    backgroundColor: "var(--Primary-400)",
    "*": {
      fill: "var(--White)",
      color: "var(--White)",
    },
  },
});

const SItemIcon = styled.div({
  "*": {
    width: "32px",
    height: "32px",
    fill: "var(--Primary-400)",
    color: "var(--Primary-400)",
  },
});

const SItemName = styled.div({
  fontSize: "14px",
  fontWeight: "600",
  color: "var(--Primary-800)",
});

const MenuItem = React.memo(({ item }: any) => (
  <Link to={item.link}>
    <SItem>
      <SItemIcon>{item.icon}</SItemIcon>
      <SItemName>{item.name}</SItemName>
    </SItem>
  </Link>
));

const MenuGroup = React.memo(({ group, items }: any) => (
  <SMenuGroup>
    <SMenuGroupHeader>{group}</SMenuGroupHeader>
    <SMenuGroupItems>
      {items.map((item: any) => (
        <MenuItem key={item.link} item={item} />
      ))}
    </SMenuGroupItems>
  </SMenuGroup>
));

const HomePage = () => {
  const groupedMenuItems: any = {};

  MenuItems.forEach((item) => {
    if (!groupedMenuItems[item.group]) {
      groupedMenuItems[item.group] = [];
    }
    groupedMenuItems[item.group].push(item);
  });

  return (
    <SHomePage>
      {Object.keys(groupedMenuItems).map((group) => (
        <MenuGroup key={group} group={group} items={groupedMenuItems[group]} />
      ))}
    </SHomePage>
  );
};

export default HomePage;

const MenuItems = [
  {
    name: "Модели",
    icon: <Scenario />,
    link: "/model",
    group: "Модель данных",
  },
  {
    name: "Атрибуты",
    icon: <Ballot />,
    link: "/attribute",
    group: "Модель данных",
  },
  {
    name: "Словари",
    icon: <Dictionaries />,
    link: "/dictionary",
    group: "Модель данных",
  },
  // {
  //   name: 'Примитивы',
  //   icon: 'shape_block.svg',
  //   link: '/primitives',
  //   group: 'Приложения',
  // },
  {
    name: "Просмотр данных",
    icon: <ViewData />,
    link: "/viewer",
    group: "Приложения",
  },
  {
    name: "Просмотр объектов",
    icon: <ViewObjects />,
    link: "/object-viewer",
    group: "Приложения",
  },
  // {
  //   name: 'Производственные цепи',
  //   icon: 'shape_chain.svg',
  //   link: '/scdesign/subgraph-pre',
  //   group: 'Приложения',
  // },
  // {
  //   name: 'Результаты оптимизации',
  //   icon: 'shape_chain.svg',
  //   link: '/scdesign/subgraph-post',
  //   group: 'Приложения',
  // },
  // {
  //   name: 'DataLens',
  //   icon: 'datalens.ico',
  //   link: '/datalens',
  //   group: 'Приложения',
  // },
  {
    name: "Управление пользователями",
    icon: <People />,
    link: "/user",
    group: "Администрирование",
  },
  {
    name: "Управление ролями",
    icon: <PersonSettings />,
    link: "/role",
    group: "Администрирование",
  },
  {
    name: "Импорт файлов",
    icon: <Download />,
    link: "/import/file",
    group: "Администрирование",
  },
  {
    name: "Логи заданий",
    icon: <LogData />,
    link: "/import/logs",
    group: "Администрирование",
  },
  {
    name: "Логи синхронизации",
    icon: <LogSinch />,
    link: "/synchronization/logs",
    group: "Администрирование",
  },
  {
    name: "Источники интеграции",
    icon: <SourcesIntegr />,
    link: "/integration/source",
    group: "Интеграция",
  },
  {
    name: "Задания интеграции",
    icon: <TaskManager />,
    link: "/integration/task",
    group: "Интеграция",
  },
  {
    name: "Форматы файлов",
    icon: <DocumentCSV />,
    link: "/integration/fileformat",
    group: "Интеграция",
  },
  {
    name: "Агенты интеграции",
    icon: <IntegrAgents />,
    link: "/integration/agent",
    group: "Интеграция",
  },
  {
    name: "Состояние системы",
    icon: <LogIntegr />,
    link: "/health",
    group: "Администрирование",
  },
  {
    name: "Логи интеграции",
    icon: <LogIntegr />,
    link: "/integration/logs",
    group: "Интеграция",
  },
  // {
  //   name: 'Модели прогнозирования',
  //   icon: 'shape_tune.svg',
  //   link: '/forecast',
  //   group: 'Алгоритмы'
  // },
  // {
  //   name: 'Анализ временных рядов',
  //   icon: 'shape_multiline_chart.svg',
  //   link: '/timeseriesanalyze',
  //   group: 'Планирование спроса'
  // },
  // {
  //   name: 'Управление каннибализацией',
  //   icon: 'shape_poll.svg',
  //   link: '/cannibalization',
  //   group: 'Планирование спроса'
  // },
  // {
  //   name: 'Управление продуктами',
  //   icon: 'shape_rest.svg',
  //   link: '/plm',
  //   group: 'Планирование спроса'
  // },
  // {
  //   name: 'Оптимизатор',
  //   icon: 'shape_blocks.svg',
  //   link: '/optimization/task',
  //   group: 'Планирование производства'
  // },
  // {
  //   name: 'Операторы данных',
  //   icon: 'shape_layers.svg',
  //   link: '/operator',
  //   group: 'Алгоритмы'
  // }
];
