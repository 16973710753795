import { Input, InputLabel } from "@mui/material";
import { Button, Flex } from "antd";
import { useState } from "react";
import { API_URL } from "../../shared/api/axios";

export const DevPage = () => {
  const [api, setApi] = useState(API_URL);

  const saveApi = () => {
    Object.entries(api).forEach(([key, value]) => {
      // @ts-ignore
      API_URL[key] = value;
    });

    setApi({ ...API_URL });
  };

  return (
    <div>
      <div>
        <Flex vertical gap="20px" style={{ marginBottom: "20px" }}>
          {Object.entries(api).map(([key, value]) => (
            <div>
              <InputLabel>{key}</InputLabel>
              <Input
                value={value}
                onChange={({ target: { value } }) =>
                  setApi({ ...api, [key]: value })
                }
                fullWidth
              />
            </div>
          ))}
        </Flex>
        <Flex vertical={false}>
          <Button onClick={saveApi} type="primary">
            Сохранить
          </Button>
        </Flex>
      </div>
    </div>
  );
};

export default DevPage;
