export interface IBackResponse<T = any> {
  code: number;
  text: string;
  data: T;
}

export class BackResponse<T = any> implements IBackResponse<T> {
  code: number;
  text: string;
  data: T;

  constructor(model: { code: number; text: string; data: any }) {
    this.code = model.code;
    this.text = model.text;
    this.data = model.data;
  }
}
