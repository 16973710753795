export interface ISource {
  id: string;
  system: string;
}

export class Source implements ISource {
  id: string;
  system: string;

  constructor(model: any) {
    this.id = model.id;
    this.system = model.system;
  }
}

export interface ITask {
  id: string;
  sourceId: string;
}

export class Task implements ITask {
  id: string;
  sourceId: string;

  constructor(model: any) {
    this.id = model.id;
    this.sourceId = model.data.system;
  }
}
