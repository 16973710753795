import styled from "@emotion/styled";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeTypes,
  getBezierPath,
  useReactFlow,
} from "@xyflow/react";
import { Popconfirm, message } from "antd";
import { useCallback } from "react";
import { useHistory } from "./state";

const SLabelContainer = styled.div(
  ({ x, y }: { x: number; y: number }) =>
    ({
      position: "absolute",
      transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
      fontSize: 12,
      pointerEvents: "all",
    }) as const,
);

const SLabel = styled.div({
  padding: "4px 10px",
  borderRadius: "6px",
  color: "var(--White)",
  backgroundColor: "var(--Primary-Grey-100)",
  fontSize: "10px",
  fontWeight: "400",
  lineHeight: "16px",
  textAlign: "center",
});

const SEdge = styled(BaseEdge)({
  strokeWidth: "2px",
  stroke: "var(--Primary-Grey-100)",
  strokeDasharray: "5",
});

const ButtonEdge: React.FC<any> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}) => {
  const { historyRecord } = useHistory();
  const { setEdges, getNodes, getEdges } = useReactFlow();

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onConfirm = useCallback(
    (event: any) => {
      event.stopPropagation();
      setEdges((edges) => edges.filter((edge) => edge.id !== id));
      historyRecord({
        nodes: getNodes(),
        edges: getEdges(),
        comment: "OnEdgesChange",
      });
      message.success(`Связь удалена`);
    },
    [id, setEdges],
  );

  return (
    <>
      <SEdge path={edgePath} />
      <EdgeLabelRenderer>
        <SLabelContainer x={labelX} y={labelY} className="nodrag nopan">
          <Popconfirm
            title="Действительно хотите удалить связь?"
            onConfirm={onConfirm}
            okText="Да"
            cancelText="Нет"
          >
            <SLabel>X</SLabel>
          </Popconfirm>
        </SLabelContainer>
      </EdgeLabelRenderer>
    </>
  );
};

export const edgeTypes: EdgeTypes = {
  buttonedge: ButtonEdge,
};
