export const FigureAggAttrModes = [
  { id: "SUM", label: "Сумма" },
  { id: "AVG", label: "Среднее" },
  // { id: 'MAX', label: 'Максимум' },
  // { id: 'MIN', label: 'Минимум' },
];

export const FigureAggTimeModes = [
  { id: "SUM", label: "Сумма" },
  { id: "AVG", label: "Среднее" },
  // { id: 'MAX', label: 'Максимум' },
  // { id: 'MIN', label: 'Минимум' },
];

export const FigureType = [
  { id: "CALCULATED", label: "Расчетный" },
  { id: "STORED", label: "Хранимый" },
];

export const FigureDisAgrModes = [
  { id: "EQUAL", label: "Равномерно" },
  { id: "COPY", label: "Копирование" },
  { id: "FIGURE", label: "По показателю" },
];

export const FigureDisTimeModes = [
  { id: "EQUAL", label: "Равномерно" },
  { id: "COPY", label: "Копирование" },
  { id: "FIGURE", label: "По показателю" },
];
