import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Select, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  pgConn: string;
  pgSchema: string;
  pgTable: string;
  formula: MappingField[];
};

const initialState: State = {
  pgConn: "",
  pgSchema: "",
  pgTable: "",
  formula: [],
};

const PostgresInput: React.FC<Props> = ({ id }) => {
  const { nodes, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.pgConn = state.pgConn;
          node.data.pgSchema = state.pgSchema;
          node.data.pgTable = state.pgTable;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      getPgConn();

      setState(
        produce((state) => {
          state.pgConn = node.data.pgConn;
          state.pgSchema = node.data.pgSchema;
          state.pgTable = node.data.pgTable;
          state.formula = node.data.formula;
        }),
      );
    }
  }, [id]);

  const setStateKeyValue = (key: string) => (value: any) => {
    setState(
      produce((state) => {
        state[key] = value;
      }),
    );
  };

  const setPgConn = setStateKeyValue("pgConn");
  const setPgSchema = setStateKeyValue("pgSchema");
  const setPgTable = setStateKeyValue("pgTable");
  const setFormula = setStateKeyValue("formula");

  const [pgConnOptions, setPgConnOptions] = useState<Option[]>([]);
  const [pgSchemaOptions, setPgSchemaOptions] = useState<Option[]>([]);
  const [pgTableOptions, setPgTableOptions] = useState<Option[]>([]);

  const getPgConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "POSTGRES" }],
    });
    setPgConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const getPgSchema = async () => {
    if (!state.pgConn) {
      return;
    }
    const getSchemas = await TransferService.getPostgresSchemas(state.pgConn);
    if (getSchemas.code === 1) {
      setPgSchemaOptions(
        getSchemas.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить схемы" });
    }
  };
  useEffect(() => {
    getPgSchema();
  }, [state.pgConn]);

  const getPgTable = async () => {
    if (!state.pgConn || !state.pgSchema) {
      return;
    }
    const getTables = await TransferService.getPostgresTables(
      state.pgConn,
      state.pgSchema,
    );
    if (getTables.code === 1) {
      setPgTableOptions(
        getTables.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getPgTable();
  }, [state.pgSchema]);

  const getPgField = async () => {
    if (!state.pgConn || !state.pgSchema || !state.pgTable) {
      return;
    }
    const getFields = await TransferService.getPostgresFields(
      state.pgConn,
      state.pgSchema,
      state.pgTable,
    );
    if (getFields.code === 1) {
      setFormula(
        getFields.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.columnName,
          type: getMappingField(el.dataType),
        })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getPgField();
  }, [state.pgTable]);

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение Postgres
          </div>
          <Select
            value={state.pgConn}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setPgConn(el)}
            options={pgConnOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Схема</div>
          <Select
            value={state.pgSchema}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setPgSchema(el)}
            options={pgSchemaOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Таблица</div>
          <Select
            value={state.pgTable}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setPgTable(el)}
            options={pgTableOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.formula?.length > 0 ? (
              state.formula.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default PostgresInput;

const getMappingField = (type: string) => {
  switch (type) {
    case "character varying":
      return "STRING";
    case "double precision":
      return "DECIMAL";
    case "datetime":
      return "DATETIME";
    case "date":
      return "DATE";
    case "integer":
      return "INTEGER";
    case "uuid":
      return "UUID";
    case "numeric":
      return "DECIMAL";
    case "boolean":
      return "STRING";
    case "timestamp without time zone":
      return "DATE";
    case "bigint":
      return "INTEGER";
  }
};
