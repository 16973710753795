import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import React from "react";
import "./index.css";
interface ITable {
  columns: MRT_ColumnDef<any>[];
  data: any[];
  ActionMenu?: any;
  options?: any;
}

const Table: React.FC<ITable> = ({
  columns,
  data,
  ActionMenu,
  options,
}: ITable) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      localization={MRT_Localization_RU}
      enableGrouping
      enableStickyHeader
      enableRowActions={ActionMenu ? true : false}
      renderRowActionMenuItems={ActionMenu}
    />
  );
};

export const SmallTable: React.FC<ITable> = ({ columns, data }: ITable) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      localization={MRT_Localization_RU}
      enableStickyHeader
      enableTopToolbar={false}
      enablePagination={false}
      enableBottomToolbar={false}
    />
  );
};

export default Table;
