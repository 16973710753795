import AttributePage from "../../pages/attributes/attribute";
import HomePage from "../../pages/home";
// import PrimitivesPage from '../../pages/_archive/primitives';
// import HierarchyPage from '../../pages/_archive/hierarchy';
import DevPage from "../../pages/dev";
import LoginPage from "../../pages/login";
import LevelVisualizePage from "../../pages/models/levelVisualize";
import ObjectViewerPage from "../../pages/objectViewer";
import ViewerPage from "../../pages/viewer";
// import HierarchyCreatePage from '../../pages/_archive/hierarchy/createHierarchy';
// import HierarchyEditPage from '../../pages/_archive/hierarchy/editHierarchy';
import DictionaryPage from "../../pages/dictionary";
import DictionaryCreatePage from "../../pages/dictionary/createDictionary";
import ModelPage from "../../pages/models/model";
import ModelViewPage from "../../pages/models/model/viewModel";
import UserSettingsPage from "../../pages/userSettings";
// import SupplyChainPage from '../../pages/_archive/supplychain';
// import SupplyChainConstructorPage from '../../pages/_archive/supplychain/constructor';
import DataDictionaryPage from "../../pages/dictionary/dataDictionary";
import IntegrationSourceViewPage from "../../pages/integration/integrationSource";
import CreateSourcePage from "../../pages/integration/integrationSource/createSource";
import ViewSourcePage from "../../pages/integration/integrationSource/viewSource";
import RolesPage from "../../pages/role";
import UsersPage from "../../pages/users";
// import ViewIntegrationTaskPage from '../../pages/integrationTask';
// import CreateTaskPage from '../../pages/_archive/integrationTask/createTask';
// import ViewerV2 from '../../pages/_archive/viewerv2';
// import ViewTaskPage from '../../pages/_archive/integrationTask/viewTask';
// import DemoFlow from '../../pages/_archive/supplychain/demo';
// import Subgraph from '../../pages/supplychain/subgraph';
import ImportFilePage from "../../pages/importfile";
import IntegrationAgentsPage from "../../pages/integration/integrationAgents";
import IntegrationConstructorPage from "../../pages/integration/integrationConstructor";
import IntegrationFileFormatsPage from "../../pages/integration/integrationFileFormats";
import IntegrationTaskPage from "../../pages/integration/integrationTaskv4";
// import OptimizationTaskPage from '../../pages/optimization';
import IntegrationLogsPage from "../../pages/integration/integrationLogs";
import OperatorPage from "../../pages/models/operator";
import CreateOperatorPage from "../../pages/models/operator/createOperator";
// import PageViewerv3 from '../../pages/_archive/viewerv3';
import PageError404 from "../../pages/errors/error404";
import PageError500 from "../../pages/errors/error500";
import SystemHealthPage from "../../pages/health";
import ImportLogsPage from "../../pages/importlogs";
import SynchronizationLogsPage from "../../pages/synchronization/logs";
// import TimeSeriesAnalysePage from '../../pages/timeseries';
// import DataLens from "../../pages/datalens";

const RoutesList = {
  unAuthorized: [
    {
      path: "/",
      element: <LoginPage />,
      errorElement: <PageError404 />,
    },
  ],
  authorized: [
    {
      path: "/",
      element: <HomePage />,
      errorElement: <PageError404 />,
    },
    {
      path: "/404",
      element: <PageError404 />,
    },
    {
      path: "/500",
      element: <PageError500 />,
    },
    // МОДЕЛЬ
    {
      path: "/model",
      element: <ModelPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/model/view/:modelID",
      element: <ModelViewPage />,
      errorElement: <PageError500 />,
    },
    // АТРИБУТ
    {
      path: "/attribute",
      element: <AttributePage />,
      errorElement: <PageError500 />,
    },
    // СЛОВАРЬ
    {
      path: "/dictionary",
      element: <DictionaryPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/dictionary/view/:dictionaryID",
    },
    {
      path: "/dictionary/data/:dictionaryID",
      element: <DataDictionaryPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "dictionary/create",
      element: <DictionaryCreatePage />,
      errorElement: <PageError500 />,
    },
    // ИЕРАРХИЯ
    // {
    //   path: '/hierarchy',
    //   element: <HierarchyPage />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/hierarchy/create',
    //   element: <HierarchyCreatePage />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/hierarchy/edit/:hierarchyID',
    //   element: <HierarchyEditPage />,
    //   errorElement: <PageError500 />
    // },
    {
      path: "/level/visualize/:levelID",
      element: <LevelVisualizePage />,
      errorElement: <PageError500 />,
    },
    // ПОЛЬЗОВАТЕЛЬ
    {
      path: "/user",
      element: <UsersPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/role",
      element: <RolesPage />,
      errorElement: <PageError500 />,
    },
    // Дизайн цепи поставок
    // {
    //   path: '/scdesign',
    //   element: <SupplyChainPage />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/scdesign/create',
    //   element: <SupplyChainConstructorPage />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/scdesign/demo',
    //   element: <DemoFlow />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/scdesign/subgraph-pre',
    //   element: <Subgraph title="Производственные цепи"/>
    // },
    // {
    //   path: '/scdesign/subgraph-post',
    //   element: <Subgraph title="Результаты оптимизации" showQuantities />
    // },
    // {
    //   path: '/primitives',
    //   element: <PrimitivesPage />,
    //   errorElement: <PageError500 />
    // },
    {
      path: "/viewer",
      element: <ViewerPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/object-viewer",
      element: <ObjectViewerPage />,
      errorElement: <PageError500 />,
    },
    // {
    //   path: '/viewerv2',
    //   element: <ViewerV2 />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/viewerv3',
    //   element: <PageViewerv3/>,
    //   errorElement: <PageError500 />
    // },
    {
      path: "/settings",
      element: <UserSettingsPage />,
      errorElement: <PageError500 />,
    },
    // Интеграция
    {
      path: "/integration/source",
      element: <IntegrationSourceViewPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/source/create",
      element: <CreateSourcePage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/source/view/:sourceID",
      element: <ViewSourcePage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/task",
      element: <IntegrationTaskPage />,
      errorElement: <PageError500 />,
    },
    // {
    //   path: '/integration/task/create',
    //   element: <CreateTaskPage />,
    //   errorElement: <PageError500 />
    // },
    // {
    //   path: '/integration/task/view/:taskID',
    //   element: <ViewTaskPage />,
    //   errorElement: <PageError500 />
    // },
    {
      path: "/import/file",
      element: <ImportFilePage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/import/logs",
      element: <ImportLogsPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/synchronization/logs",
      element: <SynchronizationLogsPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/constructor/:id",
      element: <IntegrationConstructorPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/fileformat",
      element: <IntegrationFileFormatsPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/agent",
      element: <IntegrationAgentsPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/integration/logs",
      element: <IntegrationLogsPage />,
      errorElement: <PageError500 />,
    },
    // {
    //   path: '/optimization/task',
    //   element: <OptimizationTaskPage />
    // },
    {
      path: "/operator",
      element: <OperatorPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/operator/create",
      element: <CreateOperatorPage />,
      errorElement: <PageError500 />,
    },

    // Admin Pages
    {
      path: "/dev",
      element: <DevPage />,
      errorElement: <PageError500 />,
    },
    {
      path: "/health",
      element: <SystemHealthPage />,
      errorElement: <PageError500 />,
    },
    // {
    //   path: '/timeseriesanalyze',
    //   element: <TimeSeriesAnalysePage />
    // },
    // {
    //   path: "/datalens",
    //   element: <DataLens />,
    // },
  ],
};

export default RoutesList;
