export interface IFigure {
  id: string;
  name: string;
  description: string;
  type: string;
  aggmode: string;
  aggtimemode: string;
  disaggmode: string;
  disaggfigure: string;
  disaggtimemode: string;
  disaggtimefigure: string;
  level: any;
  formularequest: string;
  formulacalc: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export class Figure implements IFigure {
  id: string;
  name: string;
  description: string;
  type: string;
  aggmode: string;
  aggtimemode: string;
  disaggmode: string;
  disaggfigure: string;
  disaggtimemode: string;
  disaggtimefigure: string;
  level: string;
  formularequest: string;
  formulacalc: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.type = model.type;
    this.aggmode = model.aggmode;
    this.aggtimemode = model.aggtimemode;
    this.disaggmode = model.disaggmode;
    this.disaggfigure = model.disaggfigure;
    this.disaggtimemode = model.disaggtimemode;
    this.disaggtimefigure = model.disaggtimefigure;
    this.level = model.level;
    this.formularequest = model.formularequest;
    this.formulacalc = model.formulacalc;
    this.createdAt = model.createdAt;
    this.updatedAt = model.updatedAt;
    this.createdBy = model.createdBy;
    this.updatedBy = model.updatedBy;
  }
}

export interface iListOfLevels {
  id: string;
  name: string;
}

export class ListOfLevels implements iListOfLevels {
  id: string;
  name: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
  }
}

export enum FigureDisagrigationTipes {
  "Sum",
  "Avverage",
  "Max",
  "Min",
}

export interface IFigureList {
  id: string;
  name: string;
}

export interface IFigureCreate {
  model: string;
  id: string;
  name: string;
  description: string;
  type: string;
  level: string;
  aggmode: string;
  aggtimemode: string;
  disaggmode: string;
  disaggfigure: string;
  disaggtimefigure: string;
  disaggtimemode: string;
  formularequest: string;
  formulacalc: string;
}

export class FigureCreate implements IFigureCreate {
  model: string;
  id: string;
  name: string;
  description: string;
  type: string;
  level: string;
  aggmode: string;
  aggtimemode: string;
  disaggmode: string;
  disaggfigure: string;
  disaggtimefigure: string;
  disaggtimemode: string;
  formularequest: string;
  formulacalc: string;

  constructor(model: any) {
    this.model = model.model;
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.type = model.type;
    this.level = model.level;
    this.aggmode = model.aggmode;
    this.aggtimemode = model.aggtimemode;
    this.disaggmode = model.disaggmode;
    this.disaggfigure = model.disaggfigure;
    this.disaggtimefigure = model.disaggtimefigure;
    this.disaggtimemode = model.disaggtimemode;
    this.formularequest = model.formularequest;
    this.formulacalc = model.formulacalc;
  }
}
