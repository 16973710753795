import { IBackResponse } from "../../entities/types/response";
import $api, { API_URL } from "../../shared/api/axios";

export default class ViewService {
  static async getAll(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xModel}/view`);

    if (response.data.code !== 1) {
      throw new Error("Request failed");
    }

    return response.data;
  }

  static async create(
    name: string,
    description: string | undefined,
    body: { [key: string]: any },
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/view/create`, {
      name,
      description,
      body,
    });

    if (response.data.code !== 1) {
      throw new Error("Request failed");
    }

    return response.data;
  }

  static async delete(uuid: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/view/delete`, { uuid });

    if (response.data.code !== 1) {
      throw new Error("Request failed");
    }

    return response.data;
  }
}
