import $api, { API_URL } from "../../shared/api/axios";
import { FigureCreate } from "../types/IFigure";
import { BackResponse, IBackResponse } from "../types/response";

export default class FigureService {
  static async getAll(model: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/getlist`, {
      model,
    });

    // if (response.data.data) {
    //   response.data.data = response.data.data.map(
    //     (figure: any) => new Figure(figure)
    //   );
    //   for (const element of response.data.data) {
    //     element.createdBy = await UserService.getUsernameById(
    //       element.createdBy
    //     );
    //     element.updatedBy = await UserService.getUsernameById(
    //       element.updatedBy
    //     );
    //   }
    // }

    return response.data;
  }

  static async getAllShort(model: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/getlistshort`, {
      model,
    });

    return new BackResponse(response.data);
  }

  static async getOne(model: string, figure: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/getone`, {
      model,
      figure,
    });

    // if (response.data.data) {
    //   const data = new Figure(response.data.data);

    //   const authorString = await UserService.getUsernameById(data.createdBy);
    //   data.createdBy = authorString;

    //   const editorString = await UserService.getUsernameById(data.updatedBy);
    //   data.updatedBy = editorString;

    //   response.data.data = data;
    // }

    return new BackResponse(response.data);
  }

  static async visualize(
    model: string,
    figure: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/getlinks`, {
      model,
      figure,
    });

    return new BackResponse(response.data);
  }

  static async create(figure: FigureCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/create`, {
      figure,
    });

    return new BackResponse(response.data);
  }

  static async update(figure: FigureCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/update`, {
      figure,
    });

    return new BackResponse(response.data);
  }

  static async delete(model: string, figure: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/figure/delete`, {
      model,
      figure,
    });

    return new BackResponse(response.data);
  }

  static async availableAttributes(
    model: string,
    figure: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/figure/availableattributes`,
      {
        model,
        figure,
      },
    );

    return new BackResponse(response.data);
  }
}
