import { notification } from "antd";
import { jwtDecode } from "jwt-decode";
import { makeAutoObservable } from "mobx";
import { IUser } from "../types/IUser";
import AuthService from "./AuthService";

export default class Store {
  user = {} as IUser;
  isAuth = false;
  isLoading = false;
  avatar = "";
  pageName = "";

  constructor() {
    makeAutoObservable(this);
  }

  setPageName(name: string) {
    this.pageName = name;
  }

  setAuth(bool: boolean) {
    this.isAuth = bool;
  }

  setUser(user: IUser) {
    this.user = user;
  }

  setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  setAvatar(avatar: string) {
    this.avatar = avatar;
  }

  backgroundRefresh = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) return;

    try {
      const response = await AuthService.refresh();

      switch (response.data.code) {
        case 1409: {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          break;
        }

        case 1411: {
          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("refreshToken", response.data.data.refreshToken);

          const data: any = jwtDecode(response.data.data.accessToken);
          const { id, name, surname, email } = response.data.data.user;

          const user = {
            id: String(id),
            name,
            surname,
            email,

            // FIXME: dummy
            role: "UNKNOWN",
            isActivated: true,
          };

          this.setAuth(true);
          this.setUser(user);
          break;
        }
        default:
          return;
      }
    } catch (e: any) {
      console.log(e.response?.data?.message);
      this.signout();
    }
  };

  auth = async (code: string, redirect: string) => {
    this.setLoading(true);

    try {
      const response = await AuthService.auth(code, redirect);

      switch (response.data.code) {
        case 1403: {
          const data: any = jwtDecode(response.data.data.accessToken);
          const { id, name, surname, email } = response.data.data.user;

          const user = {
            id: String(id),
            name,
            surname,
            email,

            // FIXME: dummy
            role: "UNKNOWN",
            isActivated: true,
          };

          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("refreshToken", response.data.data.refreshToken);

          this.setUser(user);
          this.setAuth(true);

          return notification.success({
            message: "Успешная авторизация",
            description: response?.data.text,
          });
        }
        default:
          return notification.error({
            message: "Ошибка при попытке авторизации",
            description: response?.data.text,
          });
      }
    } catch (e: any) {
      notification.error({
        message: "Ошибка",
        description: e.response?.data?.message,
      });
      console.log(e.response?.data?.message);
    } finally {
      this.setLoading(false);
    }
  };

  signout = async () => {
    this.setLoading(true);

    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      await AuthService.signout();

      this.setAuth(false);
      this.setUser({} as IUser);
    } catch (e: any) {
      console.log(e.response?.data?.message);
    } finally {
      this.setLoading(false);
    }
  };

  checkAuth = async () => {
    this.setLoading(true);

    try {
      const response = await AuthService.refresh();

      switch (response.data.code) {
        case 1409: {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          break;
        }

        case 1411: {
          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("refreshToken", response.data.data.refreshToken);

          const data: any = jwtDecode(response.data.data.accessToken);
          const { id, name, surname, email } = response.data.data.user;

          const user = {
            id: String(id),
            name,
            surname,
            email,

            // FIXME: dummy
            role: "UNKNOWN",
            isActivated: true,
          };

          this.setAuth(true);
          this.setUser(user);
          break;
        }
        default:
          return;
      }
    } catch (e: any) {
      console.log(e.response?.data?.message);
      this.signout();
    } finally {
      this.setLoading(false);
    }
  };
}
