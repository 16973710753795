import { Descriptions, DescriptionsProps, Popover } from "antd";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import UserService from "../../../../entities/model/UserService";

const UserPopover: React.FC<{ userId: string; color?: string }> = ({
  userId,
  color = "blue",
}) => {
  const [name, setName] = useState<string>("");

  const fetchName = async () => {
    const name = await UserService.getUsernameById(userId);
    setName(name);
  };

  useEffect(() => {
    fetchName();
  }, [userId]);

  return (
    <Popover
      content={<UserPopoverContent userId={userId} />}
      title="Пользователь"
    >
      <span style={{ color, fontWeight: "bold" }}>{name}</span>
    </Popover>
  );
};

export default UserPopover;

const UserPopoverContent: React.FC<{ userId: string }> = ({ userId }) => {
  const [items, setItems] = useState<DescriptionsProps["items"]>([]);

  const getInfo = async () => {
    const response = await UserService.getOne(Number(userId));
    console.log(response);
    if (response.code === 1) {
      const createdBy = await UserService.getUsernameById(
        response.data.createdBy,
      );
      const updatedBy = await UserService.getUsernameById(
        response.data.updatedBy,
      );

      setItems([
        {
          key: "0",
          children: (
            <div style={{ width: "100%" }}>
              <img
                src={response.data.avatar}
                width={"80px"}
                height={"80px"}
                style={{
                  borderRadius: "50%",
                  border: "1px solid lightgrey",
                  objectFit: "cover",
                  objectPosition: "center",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          ),
        },
        {
          key: "1",
          label: "Идентификатор",
          children: response.data.id,
        },
        {
          key: "2",
          label: "Имя",
          children: response.data.name,
        },
        {
          key: "3",
          label: "Фамилия",
          children: response.data.surname,
        },
        {
          key: "4",
          label: "Email",
          children: response.data.email,
        },
        {
          key: "5",
          label: "Заблокирован",
          children: response.data.blocked ? "ДА" : "НЕТ",
        },
        {
          key: "6",
          label: "Роли",
          children: response.data.roles ? response.data.roles.join(", ") : "-",
        },
        {
          key: "7",
          label: "Фильтры",
          children: response.data.filters
            ? response.data.filters.join(", ")
            : "-",
        },
        {
          key: "8",
          label: "Создал",
          children: createdBy,
        },
        {
          key: "9",
          label: "Дата",
          children: format(
            new Date(response.data.createdAt),
            "dd.MM.yyyy HH:mm:ss",
          ),
        },
        {
          key: "10",
          label: "Изменил",
          children: updatedBy,
        },
        {
          key: "11",
          label: "Дата",
          children: format(
            new Date(response.data.updatedAt),
            "dd.MM.yyyy HH:mm:ss",
          ),
        },
      ]);
    }
  };

  useEffect(() => {
    getInfo();
  }, [userId]);

  return (
    <Descriptions
      style={{ width: "350px" }}
      size="small"
      column={1}
      items={items}
    />
  );
};
