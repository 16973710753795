import { AxiosResponse } from "axios";
import { AuthResponse } from "../../entities/types/IAuthResponse";
import $api, { API_URL } from "../../shared/api/axios";

export default class AuthService {
  static async auth(
    code: string,
    redirect: string,
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.get(
      `${API_URL.xUser}/session/login?code=${code}&redirect=${redirect}`,
    );
  }

  static async refresh(): Promise<AxiosResponse<AuthResponse>> {
    return $api.get(`${API_URL.xUser}/session/refresh`, {
      withCredentials: true,
    });
  }

  static async signout(): Promise<void> {
    return $api.get(`${API_URL.xUser}/session/logout`);
  }

  // static async changePassword(
  //   curr_password: String,
  //   new_password: String
  // ): Promise<AxiosResponse<IBackResponse>> {
  //   return $api.post(`${API_URL.xUser}/user/changepassword`, { curr_password, new_password });
  // }
}
