import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Popconfirm, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../..";
import AgentService from "../../../entities/model/AgentService";
import { IAgent } from "../../../entities/types/IAgent";
import AgentCreateModal from "./createModal";

const IntegrationAgentsPage = () => {
  const { store } = useAppContext();
  store.setPageName("Интеграция: Агенты");

  const modalCreateRef: any = useRef();
  const modalEditRef: any = useRef();

  const [fList, setFList] = useState<IAgent[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<string | null>(null);

  const fetchData = async () => {
    const response = await AgentService.getAll();
    if (response.code === 1) {
      setFList(response.data.map((el: IAgent) => ({ id: el.id })));
    } else {
      notification.error({
        message: "Возникла ошибка при загрузке",
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  const handleDelete = async (id: string) => {
    const response = await AgentService.delete(id);
    if (response.code === 1) {
      notification.warning({ message: `Агент ${id} успешно удален` });
      fetchData();
    } else {
      console.log(response);
      notification.error({
        message: "Возникла ошибка при удалении формата",
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div
        className="ManageTable"
        style={{ display: "flex", flexDirection: "row", gap: "15px" }}
      >
        <Button
          size="small"
          onClick={() => {
            modalCreateRef.current?.openCreateModal();
          }}
        >
          ➕
        </Button>
        <Button size="small" onClick={fetchData}>
          🔄
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Идентификатор</TableCell>
            <TableCell>Действие</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fList.map((rec: IAgent, ind: number) => {
            return (
              <TableRow key={ind}>
                <TableCell>{rec.id}</TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    <Popconfirm
                      title={`Вы действительно хотите удалить формат файла ${rec.id}?`}
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => handleDelete(rec.id)}
                    >
                      <Button size="small">❌</Button>
                    </Popconfirm>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <AgentCreateModal apiRef={modalCreateRef} fetchData={fetchData} />
    </div>
  );
};

export default IntegrationAgentsPage;
