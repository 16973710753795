import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, Popover, Select, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";
import { v5 as uuidv5 } from "uuid";
import LevelService from "../../../../../entities/model/LevelService";
import ModelService from "../../../../../entities/model/ModelService";
import VersionService from "../../../../../entities/model/VersionService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { EXCEPTION_VARIABLE_UUID, useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

const NAMESPACE = "00000000-0000-0000-0000-000000000000";
const DATE_UUID = "ded4dcc5-27f2-45b5-9726-6ce078193e23";

enum VersionType {
  NEW = "new",
  BASE = "base",
  VARIABLE = "variable",
  CONSTANT = "constant",
}

type Version = {
  type: VersionType;
  value: any;
  label: string;
};

const BASE_VERSION: Version = {
  type: VersionType.BASE,
  value: "__BASE__",
  label: "🧱 Базовая",
};

const NEW_VERSION: Version = {
  type: VersionType.NEW,
  value: "__NEW__",
  label: "➕ Новая версия",
};

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  model: string;
  level: string;
  version: Version;
  formula: MappingField[];
};

const initialState: State = {
  model: "",
  level: "",
  version: BASE_VERSION,
  formula: [],
};

const PlanXFigureOutputWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, variables, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [transform, setTransform] = useState<string | null>(null);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.model = state.model;
          node.data.level = state.level;
          node.data.version = state.version.value;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    setTransform(null);

    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.model = node.data.model;
          state.level = node.data.level;
          state.version = node.data.version || BASE_VERSION;
          state.formula = node.data.formula || [];
        }),
      );
    }

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id]);

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setModel = setStateKeyValue("model");
  const setLevel = setStateKeyValue("level");
  const setVersion = setStateKeyValue("version");
  const setFormula = setStateKeyValue("formula");

  const [modelOptions, setModelOptions] = useState<Option[]>([]);
  const [levelOptions, setLevelOptions] = useState<Option[]>([]);
  const [versions, setVersions] = useState<Option[]>([]);

  const versionOptions = useMemo(
    () => [
      BASE_VERSION,
      NEW_VERSION,
      ...variables
        .filter((v) => v.uuid !== EXCEPTION_VARIABLE_UUID)
        .map((variable) => ({
          label: `🧮 ${variable.id}`,
          value: variable.id,
          type: VersionType.VARIABLE,
        })),
      ...versions.map((version) => ({
        ...version,
        type: VersionType.CONSTANT,
      })),
    ],
    [versions, variables],
  );

  console.log({
    nodes,
    edges,
    variables,
    versions,
    versionOptions,
    versionSelected: state.version,
  });

  useEffect(() => {
    const getModelList = async () => {
      const getModels = await ModelService.getAll();
      if (getModels.code === 1) {
        setModelOptions((prevOptions: any) =>
          getModels.data.map((x: any) => ({
            label: x.name,
            value: x.id,
          })),
        );
      } else {
        notification.error({
          message: getModels.text,
          description: `Код ответа: ${getModels.code}`,
        });
      }
    };

    getModelList();
  }, []);

  useEffect(() => {
    const getLevelList = async () => {
      if (!state.model || state.model === "") {
        return;
      }
      const getLevels = await LevelService.getAll(state.model);
      if (getLevels.code === 1) {
        setLevelOptions((prevOptions: any) =>
          getLevels.data.map((x: any) => ({
            label: x.name,
            value: x.id,
          })),
        );
      }
    };

    getLevelList();
  }, [state.model]);

  useEffect(() => {
    const getVersionList = async () => {
      if (!state.model || !state.level) {
        return;
      }

      const getVersions = await VersionService.getAll({
        modelId: state.model,
        levelId: state.level,
      });

      if (getVersions.code === 1) {
        setVersions(
          getVersions.data.map((version: any) => ({
            label: version.name,
            value: version.id,
          })),
        );
      }
    };

    getVersionList();
  }, [state.model, state.level]);

  useEffect(() => {
    const getFields = async () => {
      if (!state.model || !state.level) {
        return;
      }

      const getAttributes = await LevelService.getAttributes(
        state.model,
        state.level,
      );
      if (getAttributes.code !== 1) {
        return;
      }
      const keyAttributes = getAttributes.data.filter((x: any) => x.key);

      const getFigures = await LevelService.getFigures(
        state.model,
        state.level,
      );
      if (getFigures.code !== 1) {
        return;
      }

      const getPeriod = await LevelService.getPeriod(state.model, state.level);
      if (getPeriod.code !== 1) {
        return;
      }

      const fields: MappingField[] = [];

      for (const key of keyAttributes) {
        fields.push({
          uuid: uuidv5(key.attributeId, NAMESPACE),
          id: key.attributeId,
          desc: key.attributeName,
          type: "STRING",
        });
      }

      if (getPeriod.data > 0) {
        fields.push({
          uuid: DATE_UUID,
          id: "DATE",
          desc: "Дата",
          type: "DATE",
        });
      }

      for (const figure of getFigures.data) {
        fields.push({
          uuid: figure.uuid,
          id: "F_" + figure.id,
          desc: figure.name,
          type: "DECIMAL",
        });
      }

      setFormula((fs) =>
        fields.map((field: any) => {
          const f = fs.find((f) => f.uuid === field.uuid || f.id === field.id);
          const formula = f ? f.formula : undefined;
          return { ...field, formula };
        }),
      );
    };

    getFields();
  }, [state.model, state.level]);

  const outFsMap: Record<string, MappingField> = {};

  state.formula.forEach((f) => {
    outFsMap[f.uuid] = f;
  });

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель</div>
          <Select
            style={{ width: "300px", color: "black" }}
            value={state.model}
            onChange={setModel}
            options={modelOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Уровень</div>
          <Select
            style={{ width: "300px", color: "black" }}
            value={state.level}
            onChange={setLevel}
            options={levelOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Версия</div>
          <Select
            style={{ width: "300px", color: "black" }}
            value={state.version}
            onChange={setVersion}
            options={versionOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "285px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>⤵️ Входящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() =>
                          IntegrationFieldsManager.mapAllFieldsToRight({
                            sourceFields: inFs,
                            setTargetFields: setFormula,
                          })
                        }
                      >
                        ↘
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inFs.length > 0 ? (
                    inFs.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            f.id,
                            state.formula,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              IntegrationFieldsManager.mapFieldToTarget(
                                f.id,
                                inFs,
                                setFormula,
                              )
                            }
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>⤴️ Исходящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.formula.length > 0 ? (
                    state.formula.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.formula && f.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.id || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.id || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "id"),
                              )}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.desc || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.desc || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "desc"),
                              )}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.type || ""}>
                            <Select
                              style={{ width: "100%" }}
                              value={outFsMap[f.uuid]?.type || ""}
                              onChange={makeFormulaUpdater(f.uuid, "type")}
                              options={__optionType}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setTransform(f.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {state.formula &&
              state.formula.find((f) => f.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log({ nodes, edges })}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default PlanXFigureOutputWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
