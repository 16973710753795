import { Handle, Position } from "@xyflow/react";
import { memo } from "react";

export default memo(({ data, isConnectable }: any) => {
  return (
    <>
      {!data.endFigure && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="ta"
          style={{ background: "#555" }}
          isConnectable={isConnectable}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          width: "300px",
          height: "80px",
          maxHeight: "100px",
          //padding: '20px',
          borderRadius: "8px",
          background: data.background || "#779A19",
          boxShadow:
            "0px 4px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.15), 0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px 1px 0px 0px rgba(255, 255, 255, 0.70) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset",
        }}
      >
        <p
          style={{ color: "white", padding: "20px" }}
        >{`${data.figureName} (${data.figure})`}</p>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="sa"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
