import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, message, notification } from "antd";
import { useImperativeHandle, useState } from "react";
import AgentService from "../../../entities/model/AgentService";
import { IField } from "../../../entities/types/IFileFormat";
import { IBackResponse } from "../../../entities/types/response";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
}

export default function AgentCreateModal({
  apiRef,
  fetchData,
}: ComponentProps) {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);

  const [fields, setFields] = useState<IField[]>([]);

  const [modal, contextHolder] = Modal.useModal();

  const onFinish = async (e: any) => {
    let counter: number = 1;
    if (!e.id) {
      return message.error("Не указан идентификатор");
    }

    let response: IBackResponse = await AgentService.create(e.id);
    switch (response.code) {
      case 1:
        fetchData();
        modal.warning({
          title: "Сохраните реквизиты агента",
          content: (
            <Form layout="vertical">
              <Form.Item
                name="id"
                label="Идентификатор"
                initialValue={response.data.id}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="key"
                label="Ключ"
                initialValue={response.data.key}
                tooltip={{
                  title: "PLANX_AGENT_UUID",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="secret"
                label="Секрет"
                initialValue={response.data.secret}
                tooltip={{ title: "SECRET", icon: <InfoCircleOutlined /> }}
              >
                <Input disabled />
              </Form.Item>
            </Form>
          ),
          afterClose: () => apiRef.current?.closeCreateModal(),
        });
        return notification.success({
          message: "Агент создан",
          description: response?.text,
        });
      default:
        return notification.error({
          message: "Ошибка при создании агента",
          description: response?.text,
        });
    }
  };

  useImperativeHandle(apiRef, () => ({
    openCreateModal: () => {
      setOpenModal(true);
      form.resetFields();
      setFields([]);
    },
    closeCreateModal: () => {
      setOpenModal(false);
    },
  }));

  return (
    <>
      {contextHolder}
      <Modal
        open={openModal}
        onCancel={() => {
          apiRef?.current?.closeCreateModal();
        }}
        footer={false}
        width={750}
      >
        <Form
          style={{
            width: "700px",
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
          }}
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e)}
          form={form}
        >
          <Form.Item
            name="id"
            rules={[
              { required: true, message: "Пожалуйста, введите идентификатор" },
              {
                pattern: /^[A-Z][A-Z0-9]*$/,
                message:
                  "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
              },
            ]}
          >
            <div>
              <span style={{ fontSize: 18, fontWeight: 700 }}>
                <span style={{ color: "#37AB49", fontWeight: 900 }}>
                  Шаг 1.
                </span>{" "}
                Введите идентификатор агента
              </span>
              <Input
                size="large"
                placeholder="Идентификатор агента"
                onInput={(e: any) =>
                  (e.target.value = e.target.value.toUpperCase())
                }
              />
            </div>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#37AB49", width: "100%" }}
          >
            ➕ Создать
          </Button>
        </Form>
      </Modal>
    </>
  );
}
