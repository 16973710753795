import { useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import Select from "antd/lib/select";
import React, { useEffect, useState } from "react";
import LevelService from "../../entities/model/LevelService";
import ModelService from "../../entities/model/ModelService";
import { fuzzyIsIn } from "../../shared/helper/comparison";

type Props = {
  state: boolean;
  onOk: ({ model, level }: { model: string; level: string }) => void;
  onCancel: () => void;
};

const Menu: React.FC<Props> = ({ state, onOk, onCancel }) => {
  const [model, setModel] = useState();
  const [level, setLevel] = useState();

  // reset level when model chanes
  useEffect(() => setLevel(undefined), [model]);

  const { data: models, isLoading: isModelsLoading } = useQuery({
    queryKey: ["models"],
    queryFn: async () => {
      const response = await ModelService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке моделей: "${response.text}"`,
        );
      }

      return response.data.map(({ id, name }: any) => ({ id, name }));
    },
  });

  const { data: levels, isLoading: isLeveleLoading } = useQuery({
    queryKey: ["levels", model],
    queryFn: async () => {
      // return empty list of levels if model is not selected
      if (model === undefined) {
        return [];
      }

      const response = await LevelService.getAll(model);

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке уровней: "${response.text}"`,
        );
      }

      return response.data.map(({ id, name }: any) => ({ id, name }));
    },
  });

  const onOkClick = () => {
    if (!model || !level) {
      notification.error({
        message: `Для загрузки объектов необходимо выбрать модель и уровень`,
      });
      return;
    }

    onOk({ model, level });
  };

  return (
    <Modal open={state} onOk={onOkClick} onCancel={onCancel}>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontWeight: "bold" }}>Шаг 1. Выбрать модель</span>
          <Select
            value={model}
            options={models?.map((x: any) => ({
              value: x.id,
              label: `${x.name} (${x.id})`,
            }))}
            onChange={setModel}
            loading={isModelsLoading}
            disabled={isModelsLoading}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontWeight: "bold" }}>Шаг 2. Выбрать уровень</span>
          <Select
            value={level}
            options={levels?.map((x: any) => ({
              value: x.id,
              label: `${x.name} (${x.id})`,
            }))}
            onChange={setLevel}
            loading={isLeveleLoading}
            disabled={isLeveleLoading}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default Menu;
