import $api, { API_URL } from "../../shared/api/axios";
import { BackResponse, IBackResponse } from "../types/response";

export default class AgentService {
  static async getAll(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xTransfer}/agent/list`);
    return new BackResponse(response.data);
  }

  static async getOne(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/agent/getone`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async create(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/agent/create`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async delete(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/agent/delete`, {
      id,
    });
    return new BackResponse(response.data);
  }
}
