import $api, { API_URL } from "../../shared/api/axios";
import { IModelCreate, ITimeModel } from "../types/IModel";
import { BackResponse, IBackResponse } from "../types/response";

export default class ModelService {
  static async getAll(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xModel}/model/getList`);

    // response.data.data = response.data.data.map((model: any) => new Model(model));
    // for (const element of response.data.data) {
    //   element.createdBy = await UserService.getUsernameById(element.createdBy);
    //   element.updatedBy = await UserService.getUsernameById(element.updatedBy);
    // }

    return response.data;
  }

  static async update(model: IModelCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/model/update`, {
      model,
    });
    return new BackResponse(response.data);
  }

  static async updateTimeLevel(model: ITimeModel): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/model/updatetimelevel`,
      { model },
    );
    return new BackResponse(response.data);
  }

  static async create(model: IModelCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/model/create`, {
      model,
    });
    return new BackResponse(response.data);
  }

  static async delete(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/model/delete`, { id });
    return new BackResponse(response.data);
  }

  static async generalInfo(model: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/model/generalinfo`, {
      model,
    });
    return new BackResponse(response.data);
  }

  static async modelAttributes(model: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/model/attributes`, {
      model,
    });
    return new BackResponse(response.data);
  }

  static async availableDictionaryAttributes(
    model: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/model/availabledictionaryattributes`,
      { model },
    );
    return new BackResponse(response.data);
  }

  static async availableLevelAttributes(model: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/model/availablelevelattributes`,
      { model },
    );
    return new BackResponse(response.data);
  }

  static async assignAttribute(
    attribute: string,
    dictionary: string,
    model: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/model/assignattribute`,
      { attribute, dictionary, model },
    );
    return new BackResponse(response.data);
  }

  static async removeAttribute(
    attribute: string,
    model: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/model/removeattribute`,
      { attribute, model },
    );
    return new BackResponse(response.data);
  }

  static async createConversion(
    id: string,
    name: string,
    modelId: string,
    figureId: string,
    attributeId: string,
    attributeBaseUoM: string,
    levelId: string,
    attributeFromId: string,
    attributeToId: string,
  ): Promise<IBackResponse> {
    console.log(
      id,
      name,
      modelId,
      figureId,
      attributeId,
      attributeBaseUoM,
      levelId,
      attributeFromId,
      attributeToId,
    );
    const response = await $api.post(`${API_URL.xModel}/conversion/create`, {
      id,
      name,
      modelId,
      figureId,
      attributeId,
      attributeBaseUoM,
      levelId,
      attributeFromId,
      attributeToId,
    });
    return new BackResponse(response.data);
  }

  static async updateConversion(
    id: string,
    name: string,
    modelId: string,
    figureId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/conversion/update`, {
      id,
      name,
      modelId,
      figureId,
    });
    return new BackResponse(response.data);
  }

  static async deleteConversion(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/conversion/delete`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async getConversion(modelId: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/conversion/getlist`, {
      modelId,
    });
    return new BackResponse(response.data);
  }

  static async availableTimeLevels(modelId: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/model/availabletime`, {
      modelId,
    });
    return new BackResponse(response.data);
  }

  static async reassignAttribute(
    modelId: string,
    dictionaryId: string,
    attributeId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/model/reassignattribute`,
      { modelId, dictionaryId, attributeId },
    );
    return new BackResponse(response.data);
  }
}
