import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, Popover, Select, Switch, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import { v5 as uuidv5 } from "uuid";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

const NAMESPACE = "00000000-0000-0000-0000-000000000000";

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  chConn: string;
  table: string;
  delete: boolean;
  rewrite: boolean;
  rewriteFilter: string;
  formula: MappingField[];
};

const initialState: State = {
  chConn: "",
  table: "",
  delete: false,
  rewrite: false,
  rewriteFilter: "",
  formula: [],
};

const ClickhouseOutput: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.chConn = state.chConn;
          node.data.table = state.table;
          node.data.delete = state.delete;
          node.data.rewrite = state.rewrite;
          node.data.rewriteFilter = state.rewriteFilter;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setConnection = setStateKeyValue("chConn");
  const setTable = setStateKeyValue("table");
  const setDelete = setStateKeyValue("delete");
  const setRewrite = setStateKeyValue("rewrite");
  const setRewriteFilter = setStateKeyValue("rewriteFilter");
  const setFormula = setStateKeyValue("formula");

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [transform, setTransform] = useState<string | null>(null);

  useEffect(() => {
    setTransform(null);

    const node = nodes.find((node) => node.id === id);

    if (node) {
      getConnections();

      setState(
        produce((state) => {
          state.chConn = node.data.chConn ?? initialState.chConn;
          state.table = node.data.table ?? initialState.table;
          state.delete = node.data.delete ?? initialState.delete;
          state.rewrite = node.data.rewrite ?? initialState.rewrite;
          state.rewriteFilter =
            node.data.rewriteFilter ?? initialState.rewriteFilter;
          state.formula = node.data.formula ?? initialState.formula;
        }),
      );
    }

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id]);

  const [pgConnOptions, setPgConnOptions] = useState<Option[]>([]);
  const [tableOptions, setTableOptions] = useState<Option[]>([]);

  const getConnections = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "CLICKHOUSE" }],
    });
    setPgConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const getTables = async () => {
    if (!state.chConn) {
      return;
    }
    const getTables = await TransferService.getClickhouseTables(state.chConn);
    if (getTables.code === 1) {
      setTableOptions(
        getTables.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };

  useEffect(() => {
    getTables();
  }, [state.chConn]);

  const getChField = async () => {
    if (!state.chConn || !state.table) {
      return;
    }
    const getFields = await TransferService.getClickhouseFields(
      state.chConn,
      state.table,
    );
    if (getFields.code === 1) {
      setFormula(
        produce((fs: MappingField[]) => {
          return getFields.data.map((f: any) => {
            const uuid = uuidv5(f.columnName, NAMESPACE);

            return {
              uuid,
              id: f.columnName,
              type: getMappingField(f.dataType),
              formula: fs.find((f) => f.uuid === uuid)?.formula ?? "",
            };
          });
        }),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };

  useEffect(() => {
    getChField();
  }, [state.chConn, state.table]);

  const outFsMap: Record<string, MappingField> = {};

  state.formula.forEach((f) => {
    outFsMap[f.uuid] = f;
  });

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "250px", fontWeight: "bold" }}>
            Подключение Postgres
          </div>
          <Select
            value={state.chConn}
            style={{ width: "300px", color: "black" }}
            onChange={setConnection}
            options={pgConnOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "250px", fontWeight: "bold" }}>Таблица</div>
          <Select
            value={state.table}
            style={{ width: "300px", color: "black" }}
            onChange={setTable}
            options={tableOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "250px", fontWeight: "bold" }}>
            Очищать перед записью
          </div>
          <Switch
            checked={state.rewrite}
            onChange={(x) => {
              setRewrite(x);
              setRewriteFilter("");

              if (x) {
                setDelete(false);
              }
            }}
          />
          <Input
            disabled={!state.rewrite}
            onChange={({ target: { value } }) => setRewriteFilter(value)}
            value={state.rewriteFilter}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "250px", fontWeight: "bold" }}>
            Режим удаления
          </div>
          <Switch
            checked={state.delete}
            onChange={(x) => {
              setDelete(x);

              if (x) {
                setRewrite(false);
                setRewriteFilter("");
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            minHeight: "190px",
            maxHeight: "350px",
            height: "fit-content",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>⤵️ Входящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inFs.length > 0 ? (
                    inFs.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            f.id,
                            state.formula,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.type}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>⤴️ Исходящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.formula.length > 0 ? (
                    state.formula.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.formula && f.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.id || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.id || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "id"),
                              )}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.desc || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.desc || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "desc"),
                              )}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.type || ""}>
                            <Select
                              style={{ width: "100%" }}
                              value={outFsMap[f.uuid]?.type || ""}
                              onChange={makeFormulaUpdater(f.uuid, "type")}
                              options={__optionType}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setTransform(f.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {state.formula.find((f) => f.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log({ nodes, edges })}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default ClickhouseOutput;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];

const getMappingField = (type: string) => {
  if (type.indexOf("String") !== -1) {
    return "STRING";
  }

  if (type.indexOf("Decimal") !== -1) {
    return "DECIMAL";
  }

  if (type.indexOf("Date") !== -1) {
    return "DATE";
  }

  return "STRING";
};
