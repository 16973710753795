import { InboxOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadProps, message } from "antd";
import { useEffect, useImperativeHandle, useState } from "react";
import DictionaryService from "../../entities/model/DictionaryService";
import { API_URL } from "../../shared/api/axios";
import Button from "../../shared/components/button";
import LoadingSpinner from "../../shared/components/loader";
import { exportToExcel } from "../../shared/helper/excelExport";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
}

function DictionaryRecordImportModalOutside({
  apiRef,
  fetchData,
}: ComponentProps) {
  const { Dragger } = Upload;

  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [exportColumns, setExportColumns] = useState<any>({});

  useImperativeHandle(apiRef, () => ({
    openModal: (row: any) => {
      setModalID(row.id);
      setModalName(row.name);
      setOpenModal(true);
    },
    closeModal: () => {
      setOpenModal(false);
    },
  }));

  const fetchColumns = async () => {
    if (modalID !== "") {
      const response = await DictionaryService.getColumns(modalID);

      if (response.data.length > 0) {
        const roots = [];
        const simpl = [];
        for (const column of response.data) {
          column.root ? roots.push(column) : simpl.push(column);
        }

        const data = [...roots, ...simpl];
        let dict: any = {};
        for (const column of data) {
          dict[column.attributeId] = "";
        }
        setExportColumns(dict);
      }
    }
  };

  useEffect(() => {
    fetchColumns();
  }, [modalID]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".xlsx",
    action: `${API_URL.xModel}/dictionary/importexcel`,
    data: { id: modalID },
    withCredentials: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        <LoadingSpinner />;
      }
      if (status === "done") {
        console.log(info.file.response);
        message.warning(`${info.file.response.text}`);
        fetchData();
        setOpenModal(false);
      } else if (status === "error") {
        message.error(`Возникла ошибка при попытке загрузки файла`);
      }
    },
    onDrop(e) {},
  };

  // useEffect(() => {
  //   let dict: any = {};
  //   for (const column of columns) {
  //     dict[column.id] = '';
  //   }
  //   setExportColumns(dict);
  // }, [columns]);

  return (
    <Modal open={openModal} onCancel={apiRef?.current?.closeModal}>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл</p>
          <p className="ant-upload-hint">
            Файл должен содержать список записей для словаря {modalID} в
            установленом формате.
          </p>
        </Dragger>
        <Button
          label="Скачать формат файла"
          type="primary"
          onClick={() =>
            exportToExcel(`Импорт_словарь_${modalID}`, [exportColumns])
          }
        />
      </div>
    </Modal>
  );
}

export default DictionaryRecordImportModalOutside;
