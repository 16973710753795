import { IBackResponse } from "../../entities/types/response";
import $api, { API_URL } from "../../shared/api/axios";

export default class KSService {
  static async getAll(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xKS}/system/getlist`);
    return response.data;
  }

  static async getProjects(system: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/project/getlist`, {
      system,
    });
    return response.data;
  }

  static async getClasses(
    system: string,
    project: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/class/getlist`, {
      system,
      project,
    });
    return response.data;
  }

  static async getDictionaries(
    system: string,
    project: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/dictionary/getlist`, {
      system,
      project,
    });
    return response.data;
  }

  static async getModels(
    system: string,
    project: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/model/getlist`, {
      system,
      project,
    });
    return response.data;
  }

  static async getFoldersModel(
    system: string,
    project: string,
    model: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/model/getfolders`, {
      system,
      project,
      model,
    });
    return response.data;
  }

  static async getFigures(
    system: string,
    project: string,
    classUuid: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/figure/getlist`, {
      system,
      project,
      classUuid,
    });
    return response.data;
  }

  static async createTask(
    id: string,
    target: string,
    type: string,
    data: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/task/create`, {
      id,
      target,
      type,
      data,
    });
    return response.data;
  }

  static async execute(task: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/integration/execute`, {
      task,
    });
    return response.data;
  }

  static async getDatasets(
    system: string,
    project: string,
    model: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/dataset/getlist`, {
      system,
      project,
      model,
    });
    return response.data;
  }
}
