import { useEffect, useState } from "react";

interface B1Button {
  placeholder: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "Primary" | "Secondary";
  style?: React.CSSProperties;
  disabled?: boolean;
}

const B1Button: React.FC<B1Button> = ({
  placeholder,
  onClick,
  type,
  style,
  disabled,
}) => {
  const [styles, setStyle] = useState<object>();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    if (!disabled) {
      setIsHovering(true);
    }
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getStyles = () => {
    switch (type) {
      case "Secondary":
        setStyle({
          backgroundColor: disabled
            ? "#ddd"
            : isHovering
              ? "#37AB49"
              : "#F2F2F2",
          color: disabled ? "#aaa" : isHovering ? "white" : "black",
          border: "solid 2px #37AB49",
          fontSize: 16,
          borderRadius: "4px",
          cursor: disabled ? "default" : "pointer",
        });
        break;
      default:
        setStyle({
          backgroundColor: disabled
            ? "#ddd"
            : isHovering
              ? "#21672C"
              : "#37AB49",
          color: disabled ? "#aaa" : "white",
          border: "none",

          fontSize: 16,
          borderRadius: "4px",
          cursor: "pointer",
        });
    }
  };

  useEffect(getStyles, [type, isHovering]);

  return (
    <button
      style={{ ...styles, ...style }}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {placeholder}
    </button>
  );
};

export default B1Button;
