import * as XLSX from "xlsx";

export const exportToExcel = (name: string, data: any) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  name.length > 30
    ? XLSX.utils.book_append_sheet(wb, ws, "main")
    : XLSX.utils.book_append_sheet(wb, ws, name);

  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const exportToExcelMultilist = (name: string, data: any) => {
  const keys = Object.keys(data);
  const wb = XLSX.utils.book_new();

  for (const key of keys) {
    const ws = XLSX.utils.json_to_sheet(JSON.parse(data[key]));
    XLSX.utils.book_append_sheet(wb, ws, key);
  }

  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const exportToCSV = (name: string, data: any) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  name.length > 30
    ? XLSX.utils.book_append_sheet(wb, ws, "main")
    : XLSX.utils.book_append_sheet(wb, ws, name);

  XLSX.writeFile(wb, `${name}.csv`);
};
