import cx from "classnames";
import { FC } from "react";

export const Cell: FC<{
  gutter: boolean;
  stickyRight: boolean;
  disabled?: boolean;
  className?: string;
  active?: boolean;
  children?: any;
  width: number;
  left: number;
}> = ({
  children,
  gutter,
  stickyRight,
  active,
  disabled,
  className,
  width,
  left,
}) => {
  return (
    <div
      className={cx(
        "dsg-cell",
        gutter && "dsg-cell-gutter",
        disabled && "dsg-cell-disabled",
        gutter && active && "dsg-cell-gutter-active",
        stickyRight && "dsg-cell-sticky-right",
        className,
      )}
      style={{
        width,
        left: stickyRight ? undefined : left,
      }}
    >
      {children}
    </div>
  );
};
