import styled from "@emotion/styled";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { links } from "./constants";

import { ReactComponent as Logo } from "../../assets/b1-logo-en-dark.svg";
import { ReactComponent as Dashboard } from "../../assets/dashboard.svg";
import { ReactComponent as ExpandLess } from "../../assets/expand_less.svg";
import { ReactComponent as ExpandMore } from "../../assets/expand_more.svg";
import { ReactComponent as Integration } from "../../assets/integration.svg";
import { ReactComponent as MenuClose } from "../../assets/menu-close.svg";
import { ReactComponent as MenuOpen } from "../../assets/menu-open.svg";
import { ReactComponent as People } from "../../assets/people.svg";
import { ReactComponent as Scenario } from "../../assets/scenario.svg";

const ITEMS = [
  {
    key: "1",
    label: "Дашборд",
    icon: <Dashboard />,
  },
  {
    key: "2",
    label: "Модель данных",
    icon: <Scenario />,
    children: [
      { key: "2.1", label: "Модели" },
      { key: "2.2", label: "Атрибуты" },
      { key: "2.4", label: "Словари" },
    ],
  },
  {
    key: "3",
    label: "Интеграция",
    icon: <Integration />,
    children: [
      { key: "3.1", label: "Источники" },
      { key: "3.2", label: "Задания" },
    ],
  },
  {
    key: "4",
    label: "Пользователи",
    icon: <People />,
    children: [
      { key: "4.1", label: "Роли" },
      { key: "4.2", label: "Пользователи" },
    ],
  },
  //   {
  //     key: "5",
  //     label: "Задания",
  //     icon: <OrderedListOutlined />,
  //     children: [
  //       { key: "5.1", label: "История" },
  //       { key: "5.2", label: "Расписание" },
  //       { key: "5.3", label: "Шаблоны" },
  //     ],
  //   },
];

const SLink = styled(Link)({
  color: "var(--Black)",
  transition: "none",
});

const SItemContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const SItemHeader = styled.div(
  ({ changeOnHover }: { changeOnHover: boolean }) => ({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    cursor: "pointer",
    borderRadius: "4px",

    ":hover": changeOnHover
      ? {
          backgroundColor: "var(--Primary-400)",
          color: "var(--White)",

          "*": {
            color: "var(--White)",
            fill: "var(--White)",
          },
        }
      : undefined,
  }),
);

const SItemHeaderButtonContainer = styled.div({
  width: "40px",
  padding: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SItemHeaderBody = styled.div({
  display: "flex",
  alignItems: "center",
  height: "40px",
});

const SItemHeaderBodyIconContainer = styled.div({
  width: "40px",
  padding: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SItemHeaderBodyLabelContainer = styled.div({
  padding: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SItemChildrenContainer = styled.div({
  padding: "4px 8px 4px 40px",
});

const SSubItemContainer = styled.div({
  padding: "8px 16px",
  backgroundColor: "var(--Primary-Grey-005)",
  cursor: "pointer",
  borderRadius: "4px",

  ":hover": {
    backgroundColor: "var(--Primary-400)",
    color: "var(--White)",
  },
});

const SSidebarContainer = styled.div({
  padding: "16px",
  height: "100vh",
  display: "flex",
  justifyContent: "stretch",
  alignItems: "stretch",
  background: "none",
});

const SSidebar = styled.div(
  ({ isExpanded }: any) =>
    ({
      backgroundColor: "var(--Primary-Grey-005)",
      height: "100%",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "4px 8px",
      width: isExpanded ? "294px" : "56px",
    }) as const,
);

export const Sidebar = () => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [items, setItems] = React.useState<any[]>(ITEMS);

  const Item = ({
    icon,
    label,
    children,
    isOpen = false,
    type = "dynamic",
    onClick,
  }: {
    icon: React.ReactNode;
    label: React.ReactNode;
    children?: React.ReactNode;
    isOpen?: boolean;
    type?: "static" | "dynamic";
    onClick?: () => void;
  }) => {
    return (
      <SItemContainer>
        <SItemHeader onClick={onClick} changeOnHover={type === "dynamic"}>
          <SItemHeaderBody>
            <SItemHeaderBodyIconContainer>{icon}</SItemHeaderBodyIconContainer>
            {isExpanded && (
              <SItemHeaderBodyLabelContainer>
                {label}
              </SItemHeaderBodyLabelContainer>
            )}
          </SItemHeaderBody>
          {isExpanded && children && (
            <SItemHeaderButtonContainer>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </SItemHeaderButtonContainer>
          )}
        </SItemHeader>
        {isExpanded && isOpen && children && (
          <SItemChildrenContainer>{children}</SItemChildrenContainer>
        )}
      </SItemContainer>
    );
  };

  const SubItem = ({
    children,
    ...props
  }: {
    children: React.ReactNode;
    [key: string]: any;
  }) => {
    return <SSubItemContainer {...props}>{children}</SSubItemContainer>;
  };

  return (
    <SSidebarContainer>
      <SSidebar isExpanded={isExpanded}>
        <div style={{ width: "100%" }}>
          <div>
            <SLink to="/" style={{ textDecoration: "none" }}>
              <Item
                type="static"
                icon={<Logo width="24px" height="24px" />}
                label={
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      color: "var(--Black)",
                    }}
                  >
                    ИБП Мастер
                  </span>
                }
              />
            </SLink>
          </div>
          {items.map((item) => {
            let component = (
              <Item
                key={item.key}
                icon={item.icon}
                label={item.label}
                isOpen={item.isOpen}
                onClick={() => {
                  setItems((items) =>
                    items.map((i) =>
                      i.key === item.key ? { ...i, isOpen: !i.isOpen } : i,
                    ),
                  );
                }}
              >
                {item.children?.map((child: any) => (
                  <SLink
                    key={child.key}
                    to={links.find((link) => link.id === child.key)?.link!}
                    style={{ textDecoration: "none" }}
                  >
                    <SubItem key={child.key}>{child.label}</SubItem>
                  </SLink>
                ))}
              </Item>
            );

            const path = links.find((link) => link.id === item.key)?.link;
            if (path) {
              component = (
                <SLink key={item.key} to={path}>
                  {component}
                </SLink>
              );
            }

            return component;
          })}
        </div>
        <Item
          icon={isExpanded ? <MenuClose /> : <MenuOpen />}
          label="Скрыть меню"
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </SSidebar>
    </SSidebarContainer>
  );
};

export default Sidebar;
