export const pipe = (value: unknown, ...fns: Function[]) =>
  fns.reduce((acc, fn) => fn(acc), value);

export const map =
  (fn: (value: any, index: number, array: any[]) => unknown) =>
  (values: unknown[]) =>
    Array.prototype.map.call(values, fn);

export const zip = (...arr: unknown[][]) =>
  Array(Math.max(...arr.map((a) => a.length)))
    .fill(undefined)
    .map((_, i) => arr.map((a) => a[i]));
