import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Select,
  Upload,
  UploadFile,
  UploadProps,
  message,
  notification,
} from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../..";
import LevelService from "../../entities/model/LevelService";
import ModelService from "../../entities/model/ModelService";
import TransferService from "../../entities/model/TransferService";
import VersionService from "../../entities/model/VersionService";
import { Level } from "../../entities/types/ILevel";
import { Model } from "../../entities/types/IModel";
import { Version } from "../../entities/types/IVersion";
import Button from "../../shared/components/button";
import { LoaderFullScreen } from "../../shared/components/loader";
import { fuzzyIsIn } from "../../shared/helper/comparison";
import { exportToExcel } from "../../shared/helper/excelExport";

const DEFAULT_VERSION_ID = "BASE";
const DEFAULT_VERSION_NAME = "Базовая";

const ImportFilePage: React.FC = () => {
  const { store } = useAppContext();
  store.setPageName("Импорт файлов");

  const [files, setFiles] = useState<UploadFile[]>([]);

  const [model, setModel] = useState<string>();
  const [level, setLevel] = useState<string>();
  const [version, setVersion] = useState<string>(DEFAULT_VERSION_ID);

  // reset level when model changes
  useEffect(() => setLevel(undefined), [model]);

  // reset version when model or level changes
  useEffect(() => setVersion(DEFAULT_VERSION_ID), [model, level]);

  const { data: models = [], isLoading: isModelsLoading } = useQuery<Model[]>({
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: ["models"],
    queryFn: async () => {
      const response = await ModelService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки моделей: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const { data: levels = [], isLoading: isLevelsLoading } = useQuery<Level[]>({
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: ["levels", model],
    queryFn: async () => {
      if (!model) return [];

      const response = await LevelService.getAll(model);

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки уровней: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const { data: versions = [], isLoading: isVersionsLoading } = useQuery<
    Version[]
  >({
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: ["versions", model, level],
    queryFn: async () => {
      if (!model || !level) return [];

      const response = await VersionService.getAll({
        modelId: model,
        levelId: level,
      });

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки версий: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const upload = useMutation({
    retry: false,
    mutationFn: async () => {
      if (!model || !level || !version || files.length === 0) {
        return message.error(`Необходимо заполнить все требуемые поля.`);
      }

      const formData = new FormData();

      files.forEach((file) => {
        formData.append("file", file as RcFile);
      });

      formData.append("model", model);
      formData.append("level", level);
      formData.append("version", version);

      const response = await TransferService.fileImport(formData);

      if (response.code !== 1) {
        return message.error(response.text);
      }

      message.success("Успешно загружено");
    },
  });

  const props: UploadProps = {
    fileList: files,
    onRemove: (file) => {
      const index = files.indexOf(file);
      const newFileList = files.slice();
      newFileList.splice(index, 1);
      setFiles(newFileList);
    },
    beforeUpload: (file) => {
      setFiles([...files, file]);
      return false;
    },
  };

  const downloadTemplate = async () => {
    if (!model) {
      return notification.warning({ message: "Сначала выберите модель" });
    }

    if (!level) {
      return notification.warning({ message: "Сначала выберите уровень" });
    }

    const [getAttributes, getFigures, getPeriod] = await Promise.all([
      LevelService.getAttributes(model, level),
      LevelService.getFigures(model, level, version),
      LevelService.getPeriod(model, level),
    ]);

    if (
      getAttributes.code !== 1 ||
      getFigures.code !== 1 ||
      getPeriod.code !== 1
    ) {
      return notification.error({
        message: "Ошибка при попытке получения шаблона",
      });
    }

    const header: Record<string, any> = {};

    getAttributes.data
      .filter((attribute: any) => attribute.key === true)
      .forEach((attribute: any) => {
        header[attribute.attributeId] = "";
      });

    if (getPeriod.data > 0) {
      header["DATE"] = "";
    }

    getFigures.data.forEach((figure: any) => {
      header[`F_${figure.id}`] = "";
    });

    exportToExcel(`import_${model}_${level}`, [header]);
  };

  const modelOptions = useMemo(
    () => models.map((model) => ({ value: model.id, label: model.name })),
    [models],
  );
  const levelOptions = useMemo(
    () => levels.map((level) => ({ value: level.id, label: level.name })),
    [levels],
  );
  const versionOptions = useMemo(
    () => [
      { value: DEFAULT_VERSION_ID, label: DEFAULT_VERSION_NAME },
      ...versions.map((level) => ({ value: level.id, label: level.name })),
    ],
    [versions],
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "250px",
      }}
    >
      {upload.isPending && <LoaderFullScreen />}
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span>Шаг 1. Выберите модель</span>
        <Select
          style={{ width: "250px" }}
          value={model}
          options={modelOptions}
          onChange={setModel}
          loading={isModelsLoading}
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span>Шаг 2. Выберите уровень</span>
        <Select
          style={{ width: "250px" }}
          value={level}
          options={levelOptions}
          onChange={setLevel}
          disabled={!model || isLevelsLoading || models.length === 0}
          loading={isLevelsLoading}
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span>Шаг 3. Выберите версию</span>
        <Select
          style={{ width: "250px" }}
          value={version}
          options={versionOptions}
          onChange={setVersion}
          disabled={
            !model ||
            isVersionsLoading ||
            models.length === 0 ||
            versions.length === 0
          }
          loading={isVersionsLoading}
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
        />
      </div>
      <div style={{ width: "200px" }}>
        <Button
          type="dashed"
          label="Скачать Excel шаблон"
          onClick={downloadTemplate}
          disabled={!level}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span>Шаг 4. Выберите файл</span>
        <Upload {...props} maxCount={1}>
          <Button type="dashed" label="Выбрать файл" />
        </Upload>
      </div>
      <div style={{ width: "200px" }}>
        <Button
          type="primary"
          label="Загрузить"
          onClick={upload.mutate}
          disabled={!model || !level || !version || files.length === 0}
        />
      </div>
    </div>
  );
};

export default ImportFilePage;
