import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Select, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import KSService from "../../../../../entities/model/KSService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Option = {
  value: string;
  label: string;
};

type Props = {
  id: string;
};

type State = {
  system: string;
  project: string;
  dictionary: string;
  formula: MappingField[];
};

const initialState: State = {
  system: "",
  project: "",
  dictionary: "",
  formula: [],
};

const KnowledgeSpaceDictionaryInput: React.FC<Props> = ({ id }) => {
  const { nodes, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.system = state.system;
          node.data.project = state.project;
          node.data.dictionary = state.dictionary;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.system = node.data.system;
          state.project = node.data.project;
          state.dictionary = node.data.dictionary;
          state.formula = node.data.formula;
        }),
      );
    }

    KSgetSystemList();
  }, [id]);

  const setFormula = (value: MappingField[]) => {
    setState(
      produce((state) => {
        state.formula = value;
      }),
    );
  };

  const setSystem = (value: string) => {
    setState(
      produce((state) => {
        state.system = value;
      }),
    );
  };

  const setProject = (value: string) => {
    setState(
      produce((state) => {
        state.project = value;
      }),
    );
  };

  const setDictionary = (value: string) => {
    setState(
      produce((state) => {
        state.dictionary = value;
      }),
    );
  };

  const [ksSystemOptions, setKSSystemOptions] = useState<Option[]>([]);
  const [ksProjectOptions, setKSProjectOptions] = useState<Option[]>([]);
  const [ksDictionaryOptions, setKSDictionaryOptions] = useState<Option[]>([]);

  const KSgetSystemList = async () => {
    const getSystems = await KSService.getAll();
    setKSSystemOptions(
      getSystems.data.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const KSgetProjectList = async () => {
    if (state.system && state.system !== "") {
      const getProjects = await KSService.getProjects(state.system);
      if (getProjects.code === 1) {
        setKSProjectOptions((prevOptions: any) =>
          getProjects.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      } else {
        notification.error({
          message: getProjects.text,
          description: `Код ответа: ${getProjects.code}`,
        });
      }
    }
  };

  useEffect(() => {
    KSgetProjectList();
  }, [state.system]);

  const KSgetDictionaryList = async () => {
    const getDictionaries = await KSService.getDictionaries(
      state.system,
      state.project,
    );
    if (getDictionaries.code === 1) {
      setKSDictionaryOptions((prevOptions: any) =>
        getDictionaries.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetDictionaryList();
  }, [state.project]);

  useEffect(() => {
    setFormula([
      {
        uuid: uuidv4(),
        type: "STRING",
        id: "UUID",
      },
      {
        uuid: uuidv4(),
        type: "STRING",
        id: "NAME",
      },
      {
        uuid: uuidv4(),
        type: "STRING",
        id: "DESCRIPTION",
      },
    ]);
  }, [state.dictionary]);

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение KS
          </div>
          <Select
            value={state.system}
            style={{ width: "300px", color: "black" }}
            onChange={setSystem}
            options={ksSystemOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Проект KS</div>
          <Select
            value={state.project}
            style={{ width: "300px", color: "black" }}
            onChange={setProject}
            options={ksProjectOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Словарь KS</div>
          <Select
            value={state.dictionary}
            style={{ width: "300px", color: "black" }}
            onChange={setDictionary}
            options={ksDictionaryOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.formula?.length ? (
              state.formula.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default KnowledgeSpaceDictionaryInput;
