import styled from "@emotion/styled";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlowProvider,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { notification } from "antd";
import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAppContext } from "../../..";
import FlowService from "../../../entities/model/FlowService";
import { NodeType } from "./blockSettings/blockTypes";
import colors from "./colors";
import { Constructor } from "./constructor";
import { Library } from "./library";
import { Menu } from "./menu";
import { IFlowVariableField } from "./modalVariables";
import {
  ICContext,
  NEW_INTEGRATION_ID,
  useHistory,
  useICLibraryStore,
} from "./state";

const PageLayout = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  height: "100%",
  width: "100%",
  marginTop: "4px",
});

const PageContent = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  height: "100%",
  width: "100%",
});

const NODE_TYPE_TO_MODAL_TYPE = {
  flatFile: "flatfile",
  mapping: "mapping",
  join: "join",
  filter: "filter",
  groupBy: "group",
  rowGen: "rowGen",
  targetFlatFile: "target__flatFile",
  knowledgeSpaceDictionaryInput: "knowledgeSpaceDictionaryInput",
  knowledgeSpaceDictionaryOutput: "knowledgeSpaceDictionaryOutput",
  knowledgeSpaceClassInput: "knowledgeSpaceClassInput",
  knowledgeSpaceClassOutput: "knowledgeSpaceClassOutput",
  planxFigureInput: "planxFigureInput",
  planxFigureOutput: "planxFigureOutput",
  postgresInput: "postgresInput",
  postgresOutput: "postgresOutput",
  clickhouseInput: "clickhouseInput",
  clickhouseOutput: "clickhouseOutput",
  rabbitmqOutput: "rabbitmqOutput",
  launchFlow: "launchFlow",
  note: "note",
  extrapolation: "extrapolation",
  analysis: "analysis",
} as const;

const getModalType = (type: string | undefined | null) => {
  const key:
    | (typeof NODE_TYPE_TO_MODAL_TYPE)[keyof typeof NODE_TYPE_TO_MODAL_TYPE]
    | undefined = NODE_TYPE_TO_MODAL_TYPE[type];

  if (typeof key === "undefined") {
    throw new Error(`Не найден ключ ноды типа "${type}"`);
  }

  const nodeType = NodeType[key];

  if (typeof nodeType === "undefined") {
    throw new Error(`Не найден тип ноды по ключу "${key}"`);
  }

  return nodeType;
};

export const IntegrationConstructorPage: React.FC = () => {
  const { store, content } = useAppContext();
  const { setType } = content;

  const { id } = useParams();

  useEffect(() => {
    const integrationName = id === NEW_INTEGRATION_ID ? `Новая интеграция` : id;
    store.setPageName(`Конструктор трансформаций: ${integrationName}`);
  }, [store, id]);

  useEffect(() => {
    setType("custom");
    return () => setType("default");
  }, [setType]);

  useEffect(() => {
    if (id === NEW_INTEGRATION_ID) {
      useICLibraryStore.setState((state) => {
        state.isOpen = true;
      });
    }
  }, [id]);

  const [flowId, setFlowId] = useState<string | undefined>(
    id === NEW_INTEGRATION_ID ? undefined : id,
  );

  const [project, setProject] = useState<string | null>(null);
  const [variables, setVariables] = useState<IFlowVariableField[]>([]);

  const reactFlowWrapper = useRef<any>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [menu, setMenu] = useState<any>(null);

  const [undoCount, setUndoCount] = useState<number>(0);

  const [nodeId, setNodeId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<NodeType | null>(null);

  const [flowChangedAt, setFlowChangedAt] = useState<string>();
  const [flowChangedBy, setFlowChangedBy] = useState<string>();

  const ref = useRef<any>(null);

  const { history, historyRecord } = useHistory();

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nodes) => {
      const newNodes = applyNodeChanges(changes, nodes);

      // put notes on the top to make them appear under other nodes
      newNodes.sort((x, y) => (x.type === "note" ? -1 : 0));

      return newNodes;
    });
  }, []);

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );

  const openModal = (
    id: string | undefined | null,
    type: string | undefined | null,
  ) => {
    setOpen(true);
    setModalType(getModalType(type));
    setNodeId(id ? id : "");
  };

  const onConnect: OnConnect = (connection) => {
    setEdges((edges) => {
      const ret = addEdge({ ...connection, type: "buttonedge" }, edges);
      historyRecord({
        nodes,
        edges: ret,
        comment: "OnEdgesChange",
      });
      return ret;
    });

    const node = nodes.find((node) => node.id === connection.target);

    openModal(connection.target, node?.type);
  };

  const onDrop = (event: any) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");

    // check if the dropped element is valid
    if (typeof type === "undefined" || !type) {
      return;
    }

    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const genId = uuidv4().replaceAll("-", "");

    if (type === "note") {
      setNodes((nodes) => {
        const res = [
          ...nodes,
          {
            id: `${type}_${genId}`,
            type,
            position,
            dragHandle: ".custom-drag-handle",
            data: {
              id: `${type}_${genId}`,
              label: `${type}_${genId}`,
              color: colors.note,
            },
          },
        ];

        historyRecord({
          nodes: res,
          edges: edges,
          comment: "onDrop",
        });

        return res;
      });
    } else {
      setNodes((nodes) => {
        const res = [
          ...nodes,
          {
            id: `${type}_${genId}`,
            type,
            position,
            data: {
              label: `${type}_${genId}`,
            },
          },
        ];

        historyRecord({
          nodes: res,
          edges: edges,
          comment: "onDrop",
        });

        return res;
      });
    }
  };

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onPaneClick = useCallback(() => setMenu(null), []);

  const onNodeContextMenu = useCallback((event: any, node: Node) => {
    // Prevent native context menu from showing
    event.preventDefault();

    // Calculate position of the context menu. We want to make sure it
    // doesn't get positioned off-screen.
    const pane = ref.current.getBoundingClientRect();
    setMenu({
      id: node.id,
      top: event.clientY < pane.height - 200 && event.clientY,
      left: event.clientX < pane.width - 200 && event.clientX,
      right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
      bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
    });
  }, []);

  const handleFetchData = React.useCallback(async () => {
    if (id && id !== NEW_INTEGRATION_ID) {
      const response = await FlowService.getOne(id);

      if (response.code !== 1) {
        return notification.error({ message: "Поток не найден" });
      }

      setVariables(response.data.variables);
      setProject(response.data.project);
      setNodes(response.data.nodes);
      setEdges(response.data.edges);
      setFlowChangedAt(
        format(new Date(response.data.updatedAt), "dd.MM.yyyy HH:mm:ss"),
      );
      setFlowChangedBy(response.data.updatedBy);
      historyRecord({
        nodes: response.data.nodes,
        edges: response.data.edges,
        comment: "initial",
      });
    }
  }, [historyRecord, id]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData, id]);

  const icContext = {
    id,
    flowId,
    setFlowId,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    setReactFlowInstance,
    onDrop,
    onDragOver,
    ref,
    onPaneClick,
    onNodeContextMenu,
    menu,
    openModal,
    historyRecord,
    open,
    setOpen,
    modalType,
    nodeId,
    setNodeId,
    setNodes,
    setEdges,
    variables,
    setVariables,
    flowChangedAt,
    flowChangedBy,
    fetchData: handleFetchData,
    project,
    setProject,
    history,
    undoCount,
    setUndoCount,
    reactFlowWrapper,
  };

  return (
    <ICContext.Provider value={icContext}>
      <div
        className="Flow"
        style={{ width: "100%", height: "100%" }}
        onKeyDown={(e: any) =>
          open && e.stopPropagation() && e.preventDefault()
        }
      >
        <PageLayout>
          <ReactFlowProvider>
            <Menu />
            <PageContent>
              <Library />
              <Constructor />
            </PageContent>
          </ReactFlowProvider>
        </PageLayout>
      </div>
    </ICContext.Provider>
  );
};

export default IntegrationConstructorPage;
