import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Input, Modal, Popconfirm, Select, notification } from "antd";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AttributeService from "../../entities/model/AttributeService";
import LevelService from "../../entities/model/LevelService";
import { ILevel } from "../../entities/types/ILevel";
import ButtonB1 from "../../shared/components/button";
import { fuzzyIsIn } from "../../shared/helper/comparison";
import ModalType from "../types/ModalType";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
}

function LevelModal({ apiRef, fetchData }: ComponentProps) {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [modalDescr, setModalDescr] = useState<string>("");
  const [modalAction, setModalAction] = useState<ModalType>();
  const [model, setModel] = useState<string>("");
  const [modalTime, setModalTime] = useState<string | number>("");

  const [lvlAttributes, setLvlAttributes] = useState<
    { id: string; key: boolean; new: boolean }[]
  >([]);
  const [allAttributes, setAllAttributes] = useState<
    { label: string; value: string }[]
  >([]);
  const [optionsAttributes, setOptionsAttributes] = useState<
    { label: string; value: string }[]
  >([]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref5 = useRef(null);

  const handleCreate = async (event: any) => {
    event.preventDefault();
    const filterNew = lvlAttributes.filter((el) => el.new);
    for (const rec of filterNew) {
      const response = await LevelService.addAttribute(model, modalID, rec.id);
      console.log(response);
      if (response.code === 1) {
        notification.success({
          message: `Атрибут ${rec.id} успешно привязан к уровню ${modalID}`,
        });
        await fetchAttributes();
      } else {
        notification.error({ message: `Ошибка при попытке привязать атрибут` });
      }
    }
  };

  const handleDelete = async (attributeId: string) => {
    const response = await LevelService.removeAttribute(
      model,
      modalID,
      attributeId,
    );
    if (response.code === 1) {
      notification.warning({
        message: `Атрибут ${attributeId} успешно отвязан от уровня ${modalID}`,
      });
      await fetchAttributes();
    } else {
      notification.error({ message: `Ошибка при попытке отвязки атрибута` });
    }
  };

  const fetchAttributes = async () => {
    if (!modalID || modalID === "") {
      return;
    }
    const response = await LevelService.getAttributes(model, modalID);
    if (response.code === 1) {
      setLvlAttributes(
        response.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.attributeId,
          key: el.key,
          new: false,
        })),
      );
    }
  };

  useEffect(() => {
    fetchAttributes();
  }, [modalID]);

  const fetchAllAttributes = async () => {
    if (!model || model === "") {
      return;
    }
    const response = await AttributeService.getAllModel(model);
    if (response.code === 1) {
      setAllAttributes(
        response.data.map((el: any) => ({ label: el.name, value: el.id })),
      );
    }
  };

  useEffect(() => {
    fetchAllAttributes();
  }, [model]);

  const handleAttributeOptions = () => {
    const filter = allAttributes.filter(
      (el) => !lvlAttributes.some((z) => z.id === el.value),
    );
    setOptionsAttributes(filter);
  };

  useEffect(handleAttributeOptions, [allAttributes, lvlAttributes]);

  const handleAdd = () => {
    setLvlAttributes((prev) => [
      ...prev,
      { uuid: uuidv4(), id: "", key: false, new: true },
    ]);
  };

  const onChangeNewId = (uuid: string, value: string) => {
    setLvlAttributes((prev: any) => {
      return prev.map((el: any) =>
        el.uuid === uuid ? { uuid, id: value, root: el.root, new: el.new } : el,
      );
    });
  };

  const getTimeLevel = async () => {
    const response = await LevelService.getPeriod(model, modalID);
    if (response.code === 1) {
      switch (Number(response.data)) {
        case 0:
          setModalTime("Нет (0)");
          break;
        case 1:
          setModalTime("День (1)");
          break;
        case 2:
          setModalTime("Техническая неделя (2)");
          break;
        case 3:
          setModalTime("Календарная неделя (3)");
          break;
        case 4:
          setModalTime("Месяц (4)");
          break;
        case 5:
          setModalTime("Квартал (5)");
          break;
        case 6:
          setModalTime("Год (6)");
          break;
      }
    }
  };

  useEffect(() => {
    getTimeLevel();
  }, [model, modalID]);

  useImperativeHandle(apiRef, () => ({
    openEditModal: (model: string, level: ILevel) => {
      console.log(level);
      setModalID(level.id);
      setModalName(level.name);
      setModalDescr(level.description);
      setModalAction(ModalType.edit);
      setModel(model);
      setOpenModal(true);
    },
    closeModal: () => {
      setOpenModal(false);
      setModalID("");
      setModalName("");
      setModalDescr("");
      setModel("");
    },
  }));

  return (
    <Modal
      open={openModal}
      onCancel={apiRef?.current?.closeModal}
      style={{ left: open ? "30%" : "0%" }}
      width={"700px"}
      footer={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <span style={{ fontSize: 18, fontWeight: 900 }}>
            Изменение уровня
          </span>
        </Stack>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Идентификатор</span>
        <Input
          disabled={modalAction == ModalType.edit ? true : false}
          id="id"
          placeholder="Идентификатор"
          onInput={(e) =>
            setModalID((e.target as HTMLInputElement).value.toUpperCase())
          }
          size="large"
          value={modalID}
          ref={ref1}
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>Наименование</span>
        <Input
          id="name"
          placeholder="Наименование"
          onInput={(e) => setModalName((e.target as HTMLInputElement).value)}
          size="large"
          value={modalName}
          ref={ref2}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Описание</span>
        <Input
          id="description"
          placeholder="Описание"
          onInput={(e) => setModalDescr((e.target as HTMLInputElement).value)}
          value={modalDescr}
          size="large"
          ref={ref3}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          Базовая временная гранулярность
        </span>
        <Input
          id="time"
          placeholder="Временная гранулярность"
          value={modalTime}
          size="large"
          disabled
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>Атрибуты</span>
        <span style={{ marginTop: -12, fontSize: 11 }}>
          * Изменение списка ключевых атрибутов происходит через создание нового
          уровня
        </span>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Атрибут</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Ключ</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lvlAttributes &&
              lvlAttributes.map((el: any, ind: any) => (
                <TableRow key={ind}>
                  <TableCell>
                    <Select
                      placeholder="Выберите атрибут"
                      optionFilterProp="children"
                      onChange={(e) => onChangeNewId(el.uuid, e)}
                      showSearch={true}
                      filterOption={(
                        input,
                        option?: { label: string; value: string },
                      ) => fuzzyIsIn(input, option?.label ?? "")}
                      style={{ width: "100%" }}
                      value={el.id}
                      disabled={!el.new}
                      options={el.new ? optionsAttributes : allAttributes}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "center",
                      fontSize: 20,
                    }}
                  >
                    {el.key ? "✅" : ""}
                  </TableCell>
                  <TableCell style={{ width: "100px" }}>
                    <Popconfirm
                      title={`Удаление атрибута`}
                      description={`Вы действительно хотите отвязать атрибут ${el.id}?`}
                      onConfirm={() => handleDelete(el.id)}
                      okText="Удалить"
                      cancelText="Отменить"
                    >
                      <ButtonB1
                        disabled={el.root}
                        size="middle"
                        type="dashed"
                        label={"❌"}
                      />
                    </Popconfirm>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button
          type="default"
          style={{ width: "100%" }}
          onClick={() => handleAdd()}
        >
          Добавить атрибут
        </Button>
        <div
          ref={ref5}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <ButtonB1
            type="primary"
            label={modalAction === ModalType.create ? "➕ Создать" : "Изменить"}
            onClick={handleCreate}
          />
          <ButtonB1
            type="default"
            danger
            label="Отменить"
            onClick={apiRef?.current?.closeModal}
          />
        </div>
      </div>
    </Modal>
  );
}

export default LevelModal;
