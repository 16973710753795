import { Button, Form, Input, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import AttributeService from "../../../entities/model/AttributeService";
import LevelService from "../../../entities/model/LevelService";
import ModelService from "../../../entities/model/ModelService";
import { fuzzyIsIn } from "../../../shared/helper/comparison";

const ConversionCreatePage: React.FC<{
  model: string;
  modalState: any;
  dataFetch: any;
}> = ({ model, modalState, dataFetch }) => {
  const [form] = useForm();
  const [optionsFigures, setOptionsFigures] = useState<
    { value: string; label: string }[]
  >([]);
  const [optionsAttributes, setOptionsAttributes] = useState<
    { value: string; label: string }[]
  >([]);
  const [optionsLevels, setOptionsLevels] = useState<
    { value: string; label: string }[]
  >([]);
  const [optionsAttributesConversion, setOptionsAttributesConversion] =
    useState<{ value: string; label: string }[]>([]);

  const [selectedLevel, setSelectedLevel] = useState<string | null>(null);
  const [selectedFigure, setSelectedFigure] = useState<string | null>(null);

  const getOptionsAttributes = async () => {
    const response = await AttributeService.getAllModel(model);
    if (response.code === 1) {
      setOptionsAttributes(
        response.data.map((el: any) => ({ value: el.id, label: el.name })),
      );
    }
  };

  const getOptionsLevels = async () => {
    const response = await LevelService.getAll(model);
    if (response.code === 1) {
      setOptionsLevels(
        response.data.map((el: any) => ({ value: el.id, label: el.name })),
      );
    }
  };

  const getOptionsFigures = async () => {
    if (selectedLevel) {
      const response = await LevelService.getFigures(model, selectedLevel);
      if (response.code === 1) {
        setOptionsFigures(
          response.data.map((el: any) => ({ value: el.id, label: el.name })),
        );
      }
    }
  };

  const getOptionsAttributesConversion = async () => {
    if (selectedLevel) {
      const response = await LevelService.getAttributes(model, selectedLevel);
      if (response.code === 1) {
        setOptionsAttributesConversion(
          response.data.map((el: any) => ({
            value: el.attributeId,
            label: el.attributeName,
          })),
        );
      }
    }
  };

  const handleSubmit = async (el: any) => {
    const response = await ModelService.createConversion(
      el.id,
      el.name,
      model,
      el.figureId,
      el.attributeId,
      el.attributeBaseUoM,
      el.levelId,
      el.attributeFromId,
      el.attributeToId,
    );

    if (response.code === 1) {
      notification.success({ message: "Успешно создано" });
      form.resetFields();
      modalState(false);
    } else {
      notification.error({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  useEffect(() => {
    getOptionsAttributes();
    getOptionsLevels();
  }, []);

  useEffect(() => {
    getOptionsFigures();
    getOptionsAttributesConversion();
  }, [selectedLevel]);

  return (
    <div>
      <Form form={form} onFinish={(e) => handleSubmit(e)}>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
          Введите идентификатор конверсии
        </span>
        <Form.Item
          name="id"
          rules={[
            { required: true, message: "Пожалуйста, введите идентификатор" },
            {
              pattern: /^[A-Z][A-Z0-9]*$/,
              message:
                "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
            },
          ]}
        >
          <Input
            onChange={(e) => e.target.value.toUpperCase()}
            size="large"
            placeholder="Идентификатор конверсии"
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
          Введите наименование конверсии
        </span>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "Пожалуйста, введите наименование" },
          ]}
        >
          <Input
            onChange={(e) => e.target.value.toUpperCase()}
            size="large"
            placeholder="Наименование конверсии"
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
          Выберите атрибут, содержащий возможные значения конверсии
        </span>
        <Form.Item
          name="attributeId"
          rules={[{ required: true, message: "Пожалуйста, выберите атрибут" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
            options={optionsAttributes}
            size="large"
            placeholder="Атрибут значений конверсии"
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
          Выберите атрибут, содержащий базовую единицу измерения
        </span>
        <Form.Item
          name="attributeBaseUoM"
          rules={[{ required: true, message: "Пожалуйста, выберите атрибут" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
            options={optionsAttributes}
            size="large"
            placeholder="Атрибут базовой единицы измерения"
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 5.</span>{" "}
          Выберите уровень
        </span>
        <Form.Item
          name="levelId"
          rules={[{ required: true, message: "Пожалуйста, выберите уровень" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
            options={optionsLevels}
            size="large"
            placeholder="Уровень с коэффициентами конверсии"
            onChange={(e) => setSelectedLevel(e)}
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 6.</span>{" "}
          Выберите показатель конверсии
        </span>
        <Form.Item
          name="figureId"
          rules={[
            { required: true, message: "Пожалуйста, выберите показатель" },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
            options={optionsFigures}
            size="large"
            placeholder="Наименование конверсии"
            onChange={(e) => setSelectedFigure(e)}
            value={selectedFigure}
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 7.</span>{" "}
          Выберите атрибут конверсии "ИЗ"
        </span>
        <Form.Item
          name="attributeFromId"
          rules={[{ required: true, message: "Пожалуйста, выберите атрибут" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
            options={optionsAttributesConversion}
            size="large"
            placeholder='Атрибут конверсии "ИЗ"'
          />
        </Form.Item>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 8.</span>{" "}
          Выберите атрибут конверсии "В"
        </span>
        <Form.Item
          name="attributeToId"
          rules={[{ required: true, message: "Пожалуйста, выберите атрибут" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
            options={optionsAttributesConversion}
            size="large"
            placeholder='Атрибут конверсии "В"'
          />
        </Form.Item>
        <Button htmlType="submit" style={{ width: "100%" }} type="primary">
          Создать
        </Button>
      </Form>
    </div>
  );
};

export default ConversionCreatePage;
