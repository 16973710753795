import { Reorder } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Input, Modal, Popconfirm, Select } from "antd";
import { Dispatch, SetStateAction } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  ResponderProvided,
} from "react-beautiful-dnd";

import { v4 as uuidv4 } from "uuid";
import { EXCEPTION_VARIABLE_UUID } from "./state";

interface ModalFlowVariables {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;

  setVariables: Dispatch<SetStateAction<IFlowVariableField[]>>;
  variables: IFlowVariableField[];
}

export interface IFlowVariableField {
  uuid: string;
  id: string;
  desc: string;
  type: "STRING" | "ARRAY";
  defaultValue?: string | Array<string>;
}

const ModalFlowVariables = (inherits: ModalFlowVariables) => {
  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    inherits.setVariables((prev: any) => {
      const temp = [...prev];
      const d = temp[result.destination!.index];
      temp[result.destination!.index] = temp[result.source.index];
      temp[result.source.index] = d;
      return temp;
    });
  };

  const handleDelete = (uuid: string) => {
    inherits.setVariables((prev) => prev.filter((el) => el.uuid !== uuid));
  };

  const handleCreateField = () => {
    const uuidGen = uuidv4();

    if (inherits.variables) {
      inherits.setVariables((prev) => [
        ...prev,
        { uuid: uuidGen, id: "", desc: "", type: "STRING", defaultValue: "" },
      ]);
    } else {
      inherits.setVariables([
        { uuid: uuidGen, id: "", desc: "", type: "STRING", defaultValue: "" },
      ]);
    }
  };

  return (
    <Modal
      open={inherits.modalState}
      footer={false}
      onCancel={() => inherits.setModalState(false)}
      width={1000}
    >
      <div
        style={{
          width: "1000px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginTop: "30px",
        }}
      >
        <span style={{ color: "#37AB49", fontSize: 18, fontWeight: 900 }}>
          Переменные потока
        </span>
      </div>
      <Table style={{ width: "950px" }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "5%" }}>&nbsp;</TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                width: "25%",
                padding: "10px",
                height: "32px",
              }}
            >
              Идентификатор
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                width: "25%",
                padding: "10px",
                height: "32px",
              }}
            >
              Описание
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                width: "20%",
                padding: "10px",
                height: "32px",
              }}
            >
              Тип
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                width: "25%",
                padding: "10px",
                height: "32px",
              }}
            >
              Значение по умолчанию
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                width: "5%",
                padding: "10px",
                height: "32px",
              }}
            >
              Действие
            </TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <TableBody
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {inherits.variables &&
                  inherits.variables
                    .filter((v) => v.uuid !== EXCEPTION_VARIABLE_UUID)
                    .map((field: IFlowVariableField, index: number) => (
                      <Draggable
                        key={field.uuid}
                        draggableId={field.uuid}
                        index={index}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot,
                        ) => (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <TableCell style={{ width: "5%" }}>
                              <div {...draggableProvided.dragHandleProps}>
                                <Reorder />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "10px",
                                height: "25px",
                                width: "25%",
                              }}
                            >
                              <Input
                                addonBefore={"$"}
                                value={field.id}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  inherits.setVariables((prev) =>
                                    prev.map((f) => {
                                      if (f.uuid === field.uuid) {
                                        return {
                                          ...f,
                                          id: newValue
                                            .toUpperCase()
                                            .replace(/[^A-Z0-9]+/g, ""),
                                        };
                                      }
                                      return f;
                                    }),
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "10px",
                                height: "25px",
                                width: "25%",
                              }}
                            >
                              <Input
                                value={field.desc}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  inherits.setVariables((prev) =>
                                    prev.map((f) => {
                                      if (f.uuid === field.uuid) {
                                        return { ...f, desc: newValue };
                                      }
                                      return f;
                                    }),
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "10px",
                                height: "25px",
                                width: "20%",
                              }}
                            >
                              <Select
                                style={{ width: "100%" }}
                                value={field.type}
                                onChange={(e) => {
                                  const newValue = e;
                                  inherits.setVariables((prev) =>
                                    prev.map((f: any) => {
                                      if (f.uuid === field.uuid) {
                                        return { ...f, type: newValue };
                                      }
                                      return f;
                                    }),
                                  );
                                }}
                                options={__optionType}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "10px",
                                height: "25px",
                                width: "25%",
                              }}
                            >
                              <Input
                                value={field.defaultValue}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  inherits.setVariables((prev) =>
                                    prev.map((f) => {
                                      if (f.uuid === field.uuid) {
                                        return { ...f, defaultValue: newValue };
                                      }
                                      return f;
                                    }),
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "10px",
                                height: "25px",
                                width: "5%",
                              }}
                            >
                              <Popconfirm
                                title={`Вы действительно хотите удалить поле ${field.id}?`}
                                okText="Да"
                                cancelText="Нет"
                                onConfirm={() => handleDelete(field.uuid)}
                              >
                                <Button
                                  style={{ width: "50px", marginLeft: "5px" }}
                                >
                                  ❌
                                </Button>
                              </Popconfirm>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      <Button
        type="dashed"
        style={{ marginTop: "5px", width: "950px" }}
        onClick={handleCreateField}
      >
        Добавить поле
      </Button>
    </Modal>
  );
};

export default ModalFlowVariables;

const __optionType = [
  {
    label: "Строка",
    options: [{ value: "STRING", label: "Строка" }],
  },
  {
    label: "Массивы",
    options: [{ value: "ARRAY", label: "Массив строк" }],
  },
  {
    label: "Число",
    options: [{ value: "NUMBER", label: "Число" }],
  },
];
