export const historyStore = () => {
  const history: { nodes: any[]; edges: any[] }[] = [];

  return {
    history,
    historyRecord: ({ nodes, edges, comment }: any) => {
      console.log(comment, { nodes, edges }, history);
      history.push({ nodes, edges });
    },
  };
};
