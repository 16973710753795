declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_API_URL: string;

  // KeyCloak
  REACT_APP_KC_URL: string;
  REACT_APP_KC_REALM: string;
  REACT_APP_KC_CLIENT: string;
  REACT_APP_KC_REDIRECT: string;
  REACT_APP_COMPANY_LOGO: string;
};

export const env: EnvType = { ...process.env, ...window.env };
