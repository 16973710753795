import $api, { API_URL } from "../../shared/api/axios";
import { BackResponse, IBackResponse } from "../types/response";

export default class SyncService {
  static async getLogs(params?: {
    page: number;
    size: number;
    filters: any[];
    sorting: any[];
  }): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xSyncAgent}/logs/list`, params);
    return new BackResponse(response.data);
  }
}
