import { env } from "../../env";

export class KeyCloak {
  readonly url: string;
  readonly realm: string;
  readonly client: string;

  constructor(config: { url: string; realm: string; client: string }) {
    this.url = config.url;
    this.realm = config.realm;
    this.client = config.client;
  }

  authURL(redirect: string) {
    return (
      `${this.url}/realms/${this.realm}/protocol/openid-connect/auth` +
      `?client_id=${this.client}` +
      `&response_type=code` +
      `&scope=email` +
      `&redirect_uri=${redirect}`
    );
  }
}

export const kc = new KeyCloak({
  url: env.REACT_APP_KC_URL,
  realm: env.REACT_APP_KC_REALM,
  client: env.REACT_APP_KC_CLIENT,
});
