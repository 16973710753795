import { DataSheetGrid as DataSheetGridBase } from "./components/DataSheetGrid";
import { StaticDataSheetGrid as StaticDataSheetGridBase } from "./components/StaticDataSheetGrid";
import {
  AddRowsComponentProps as AddRowsComponentPropsBase,
  CellComponent as CellComponentBase,
  CellProps as CellPropsBase,
  Column as ColumnBase,
  ContextMenuComponentProps as ContextMenuComponentPropsBase,
  ContextMenuItem as ContextMenuItemBase,
  DataSheetGridProps as DataSheetGridPropsBase,
  DataSheetGridRef as DataSheetGridRefBase,
  SimpleColumn as SimpleColumnBase,
} from "./types";

export type Column<T = any, C = any, PasteValue = string> = Partial<
  ColumnBase<T, C, PasteValue>
>;
export type CellComponent<T = any, C = any> = CellComponentBase<T, C>;
export type CellProps<T = any, C = any> = CellPropsBase<T, C>;
export type DataSheetGridProps<T = any> = DataSheetGridPropsBase<T>;
export type AddRowsComponentProps = AddRowsComponentPropsBase;
export type SimpleColumn<T = any, C = any> = SimpleColumnBase<T, C>;
export type ContextMenuComponentProps = ContextMenuComponentPropsBase;
export type ContextMenuItem = ContextMenuItemBase;
export type DataSheetGridRef = DataSheetGridRefBase;
export const DynamicDataSheetGrid = DataSheetGridBase;
export const DataSheetGrid = StaticDataSheetGridBase;
export { checkboxColumn } from "./columns/checkboxColumn";
export { dateColumn } from "./columns/dateColumn";
export { floatColumn } from "./columns/floatColumn";
export { intColumn } from "./columns/intColumn";
export { isoDateColumn } from "./columns/isoDateColumn";
export { keyColumn } from "./columns/keyColumn";
export { percentColumn } from "./columns/percentColumn";
export { createTextColumn, textColumn } from "./columns/textColumn";
export { createAddRowsComponent } from "./components/AddRows";
export {
  createContextMenuComponent,
  defaultRenderItem as renderContextMenuItem,
} from "./components/ContextMenu";
