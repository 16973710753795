import { CheckCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, notification, Popconfirm, Tag } from "antd";
import type {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TransferService from "../../../entities/model/TransferService";
import { ISource } from "../../../entities/types/ISource";

type Response = {
  code: number;
  text: string;
  data: {
    count: number;
    rows: ISource[];
  };
};

const IntegrationSourceViewPage: React.FC = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const {
    data: { data: { rows, count } } = { data: { rows: [], count: 0 } },
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<Response>({
    queryKey: [
      "integration-sources",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await TransferService.getAll({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка источников интеграций: "${response.text}"`,
        );
      }

      return response;
    },
  });

  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "ID",
      accessorKey: "id",
      Cell: (e) => (
        <Link
          to={`/integration/source/view/${e.row.original.id}`}
          style={{ fontWeight: "bold" }}
        >
          {e.row.original.id}
        </Link>
      ),
    },
    {
      header: "Система-источник",
      accessorKey: "system",
    },
    {
      header: "Кол-во заданий",
      enableGrouping: false,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: (e) => <Tag bordered={false}>1</Tag>,
    },
    {
      header: "Статус",
      enableGrouping: false,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: (e) => (
        <Tag
          style={{ fontSize: 16 }}
          icon={<CheckCircleOutlined />}
          color="green"
        >
          Активно
        </Tag>
      ),
    },
    {
      header: "Действие",
      enableGrouping: false,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Button size="small">
            <Link to={`/integration/source/view/${row.original.id}`}>✏</Link>
          </Button>
          <Popconfirm
            title={`Вы действительно хотите удалить источник интеграции ${row.original.id}?`}
            okText="Да"
            cancelText="Нет"
            onConfirm={async () => {
              const id = row.original.id;

              const response = await TransferService.delete(id);

              if (response.code !== 0) {
                return notification.error({
                  message: response.text,
                  description: `Код ответа: ${response.code}`,
                });
              }

              notification.success({
                message: response.text,
                description: `Код ответа: ${response.code}`,
              });

              refetch();
            }}
          >
            <Button size="small">❌</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const table = useMaterialReactTable({
    data: rows,
    columns: columns,
    initialState: { density: "compact" },
    localization: MRT_Localization_RU,

    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    enableGrouping: true,
    groupedColumnMode: "remove",

    layoutMode: "grid",

    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    rowCount: count,

    state: {
      columnFilters: filters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <div className="pageAttribute">
      <div
        className="ManageTable"
        style={{ display: "flex", flexDirection: "row", gap: "15px" }}
      >
        <Link to="/integration/source/create">
          <Button type="primary" size="large">
            📁 Создать
          </Button>
        </Link>
        <Button size="large" type="default" onClick={() => refetch()}>
          🔄 Обновить
        </Button>
      </div>
      <div style={{ marginTop: "15px" }}>
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default IntegrationSourceViewPage;
