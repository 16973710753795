import $api, { API_URL } from "../../shared/api/axios";
import { IField } from "../types/IFileFormat";
import { BackResponse, IBackResponse } from "../types/response";

export default class FlatFileService {
  static async getAll(
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {},
  ): Promise<IBackResponse<{ count: number; rows: any[] }>> {
    const response = await $api.post(
      `${API_URL.xTransfer}/fileformat/list`,
      params,
    );
    return new BackResponse(response.data);
  }

  static async getOne(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/fileformat/getone`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async create(
    id: string,
    desc: string,
    tags: string[],
    fields: IField[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/fileformat/create`, {
      id,
      desc,
      tags,
      fields,
    });
    return new BackResponse(response.data);
  }

  static async update(
    id: string,
    desc: string,
    tags: string[],
    fields: IField[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/fileformat/update`, {
      id,
      desc,
      tags,
      fields,
    });
    return new BackResponse(response.data);
  }

  static async delete(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/fileformat/delete`, {
      id,
    });
    return new BackResponse(response.data);
  }
}
