import styled from "@emotion/styled";
import {
  Controls,
  MiniMap,
  Node,
  ReactFlow,
  SelectionMode,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Modal } from "antd";
import { NodeWindow, nodeTypes } from "./blockSettings/blockTypes";
import { nodeColor } from "./colors";
import { ContextMenu } from "./contextMenu";
import { edgeTypes } from "./edge";
import { useICState } from "./state";

const Container = styled.div({
  backgroundColor: "var(--White)",
  height: "100%",
  borderRadius: "8px",
  padding: "16px",
  overflowY: "auto",
});

export const Constructor = () => {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    setReactFlowInstance,
    onDrop,
    onDragOver,
    ref,
    onPaneClick,
    onNodeContextMenu,
    menu,
    openModal,
    historyRecord,
    open,
    setOpen,
    modalType,
    nodeId,
    reactFlowWrapper,
  } = useICState();

  return (
    <Container style={{ width: "100%" }}>
      <div
        className="reactflow-wrapper"
        ref={reactFlowWrapper}
        style={{ width: "100%", height: "100%" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          fitView
          proOptions={{ hideAttribution: true }}
          selectionOnDrag
          panOnDrag={[1, 2]}
          selectionMode={SelectionMode.Partial}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          ref={ref}
          onPaneClick={onPaneClick}
          onNodeContextMenu={onNodeContextMenu}
        >
          <Controls />
          <MiniMap
            style={{ paddingLeft: 0, margin: 0 }}
            pannable={true}
            nodeColor={(node) => {
              let color = nodeColor(node);
              return typeof color === "string" ? color : color.default;
            }}
          />
          {menu && (
            <ContextMenu
              onClick={onPaneClick}
              onView={(node?: Node<any>) => openModal(node?.id, node?.type)}
              historyRecord={historyRecord}
              {...menu}
            />
          )}
        </ReactFlow>
      </div>
      <Modal
        centered
        open={open}
        width="fit-content"
        styles={{ content: { padding: 0 } }}
        closable={false}
        footer={false}
        destroyOnClose
      >
        {NodeWindow(modalType, nodeId, nodes)}
      </Modal>
    </Container>
  );
};
