import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import Particles from "react-particles";
import { useNavigate } from "react-router-dom";
import { loadFull } from "tsparticles";
import { useAppContext } from "../..";
import { env } from "../../env";
import { kc } from "../../shared/keycloak";

const initParticles = async (engine: any) => {
  await loadFull(engine);
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { store } = useAppContext();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (code) {
      store
        .auth(
          code,
          env.REACT_APP_KC_REDIRECT
            ? env.REACT_APP_KC_REDIRECT
            : env.REACT_APP_API_URL,
        )
        .then(() => navigate("/"));
    }
  });

  return (
    <div
      style={{
        height: "calc(100vh - 56px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ zIndex: -1 }}>
        <Particles options={options} init={initParticles} />
      </div>
      <div
        style={{
          backgroundColor: "white",
          padding: "15px",
          width: "350px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img width={50} src="/img/logo_small.png" alt="logo" />
          {env.REACT_APP_COMPANY_LOGO && (
            <>
              <span style={{ fontWeight: "bold", fontSize: "28px" }}> & </span>
              <img
                height={35}
                src={env.REACT_APP_COMPANY_LOGO}
                alt="company logo"
              />
            </>
          )}
        </div>
        <span style={{ fontWeight: 900 }}>Добро пожаловать</span>
        <Button
          href={kc.authURL(
            env.REACT_APP_KC_REDIRECT
              ? env.REACT_APP_KC_REDIRECT
              : env.REACT_APP_API_URL,
          )}
        >
          Войти
        </Button>
      </div>
    </div>
  );
};

export default observer(LoginPage);

const options: any = {
  particles: {
    number: {
      value: 45,
      density: {
        enable: true,
        area: 800,
      },
    },
    color: {
      value: ["#2EB67D", "#ECB22E", "#E01E5B", "#36C5F0"],
    },
    shape: {
      type: "triangle",
    },
    opacity: {
      value: 1,
    },
    size: {
      value: { min: 1, max: 12 },
    },
    links: {
      enable: true,
      distance: 150,
      color: "#808080",
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      outModes: "out",
    },
  },
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: "grab",
      },
      onClick: {
        enable: false,
        mode: "push",
      },
    },
    modes: {
      grab: {
        distance: 140,
        links: {
          opacity: 1,
        },
      },
      push: {
        quantity: 4,
      },
    },
  },
};
