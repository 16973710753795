import { Button, Form, Input, Select, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import ConfigProvider from "antd/lib/config-provider";
import ruRU from "antd/lib/locale/ru_RU";
import Tour from "antd/lib/tour";
import { TourProps } from "antd/lib/tour/interface";
import React, { useEffect, useRef, useState } from "react";
import FigureService from "../../../entities/model/FigureService";
import LevelService from "../../../entities/model/LevelService";
import { FigureCreate } from "../../../entities/types/IFigure";
import { Loader } from "../../../shared/components/loader";
import { fuzzyIsIn } from "../../../shared/helper/comparison";
import { counter } from "../../../shared/helper/counter";
import {
  FigureAggAttrModes,
  FigureAggTimeModes,
  FigureDisAgrModes,
  FigureDisTimeModes,
  FigureType,
} from "./constants";
import type { Figure } from "./types";

interface Props {
  model: string;
  figure: string;
  onFinish?: () => void;
}

const FigureEditPage: React.FC<Props> = ({ model, figure, onFinish }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [figureData, setFigureData] = useState<Figure>();

  const [levels, setLevels] = useState<Record<string, any>[]>([]);
  const [figures, setFigures] = useState<Record<string, any>[]>([]);

  const [objType, setObjType] = useState<Figure["type"]>("STORED");
  const [objLevel, setObjLevel] = useState<Figure["level"]>("");

  const [objAgg, setObjAgg] = useState<Figure["aggmode"]>("SUM");
  const [objAggTime, setObjAggTime] = useState<Figure["aggtimemode"]>("SUM");

  const [objDisagg, setObjDisagg] = useState<Figure["disaggmode"]>("EQUAL");
  const [objDisaggFigure, setObjDisaggFigure] =
    useState<Figure["disaggfigure"]>();
  const [objDisaggTime, setObjDisaggTime] =
    useState<Figure["disaggtimemode"]>("EQUAL");
  const [objDisaggTimeFigure, setObjDisaggTimeFigure] =
    useState<Figure["disaggtimefigure"]>();

  const [form] = useForm();

  const [open, setOpen] = useState<boolean>(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    FigureService.getOne(model, figure)
      .then(({ code, data }) => code === 0 && setFigureData(data))
      .then(() => setIsLoading(false));
  }, [model, figure]);

  useEffect(() => {
    if (figureData) {
      setObjType(figureData.type);
      setObjLevel(figureData.level);
      setObjAgg(figureData.aggmode);
      setObjAggTime(figureData.aggtimemode);
      setObjDisagg(figureData.disaggmode);
      setObjDisaggFigure(figureData.disaggfigure);
      setObjDisaggTime(figureData.disaggtimemode);
      setObjDisaggTimeFigure(figureData.disaggtimefigure);

      form.setFieldsValue({
        id: figureData.id,
        name: figureData.name,
        description: figureData.description,
        type: figureData.type,
        level: figureData.level,
        formulacalc: figureData.formulacalc,
        aggmode: figureData.aggmode,
        aggtimemode: figureData.aggtimemode,
        disaggmode: figureData.disaggmode,
        disaggtimemode: figureData.disaggtimemode,
      });
    }
  }, [figureData, form]);

  useEffect(() => {
    if (objDisagg !== "FIGURE") {
      setObjDisaggFigure(undefined);
    }
  }, [objDisagg]);

  useEffect(() => {
    if (objDisaggTime !== "FIGURE") {
      setObjDisaggTimeFigure(undefined);
    }
  }, [objDisaggTime]);

  const fetchLevels = async () => {
    const rawData = await LevelService.getAll(model);
    if (rawData) setLevels(rawData.data);
  };

  const fetchFigures = async () => {
    const response = await FigureService.getAll(model);
    setFigures(response.data);
  };

  useEffect(() => {
    fetchLevels();
    fetchFigures();
  }, []);

  const updateFigure = async () => {
    const figure = {
      id: form.getFieldValue("id"),
      model,
      name: form.getFieldValue("name"),
      description: form.getFieldValue("description"),
      aggmode: objAgg,
      aggtimemode: objAggTime,
      disaggmode: objDisagg,
      disaggtimemode: objDisaggTime,
      disaggfigure: objDisaggFigure,
      disaggtimefigure: objDisaggTimeFigure,
      formulacalc: form.getFieldValue("formulacalc"),
    };

    const toSend = new FigureCreate(figure);

    const response = await FigureService.update(toSend);

    switch (response.code) {
      case 0: {
        notification.success({ message: "Показатель успешно изменен" });
        onFinish?.();
        break;
      }
      default: {
        notification.error({ message: response.text });
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (figureData === undefined) {
    return (
      <div style={{ marginTop: "50px", marginBottom: "50px" }}>
        Не удалось загрузить показатель {figure}
      </div>
    );
  }

  const steps: TourProps["steps"] = [
    {
      title: "Введите наименование показателя",
      description:
        "Это короткое и понятное наименование показателя, отражающее его суть (например, Выручка | План | BIAS (смещение прогноза)).",
      target: () => ref1.current,
    },
    {
      title: "Введите описание показателя (опционально)",
      description: "Текст в произвольном формате",
      target: () => ref2.current,
    },
    {
      title: "Введите формулу расчета показателя",
      description:
        'Поле для ввода будет активно если выбран тип показателя "Расчетный"',
      target: () => ref3.current,
    },
    {
      title: "Выберите метод агрегации показателя по атрибутам",
      description: "",
      target: () => ref4.current,
    },
    {
      title: "Выберите метод агрегации показателя по времени",
      description: "",
      target: () => ref5.current,
    },
    {
      title: "Выберите метод дезагрегации показателя по атрибутам",
      description: "",
      target: () => ref6.current,
    },
    {
      title:
        "Выберите показатель пропорционально которому будет выполнена дезаграгация по атрибутам",
      description:
        'Поле для ввода будет активно если выбран метод дезагрегации "По показателю"',
      target: () => ref7.current,
    },
    {
      title: "Выберите метод дезагрегации показателя по времени",
      description: "",
      target: () => ref8.current,
    },
    {
      title:
        "Выберите показатель пропорционально которому будет выполнена дезаграгация по времени",
      description:
        'Поле для ввода будет активно если выбран метод дезагрегации "По показателю"',
      target: () => ref9.current,
    },
  ];

  const index = counter({ start: 1 });

  const possibleFiguresOptions = figures
    .filter((figure) => figure.level.id === objLevel)
    .map((figure) => ({ value: figure.id, label: figure.name }));

  return (
    <div style={{ padding: "15px" }}>
      <ConfigProvider locale={ruRU}>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          mask={false}
          type="primary"
          steps={steps}
          placement="right"
        />
      </ConfigProvider>
      <Form
        name="basic"
        style={{ display: "flex", flexDirection: "column" }}
        initialValues={{ remember: true }}
        onFinish={(_) => updateFigure()}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontSize: 18, fontWeight: 700 }}>
            <span style={{ color: "#37AB49", fontWeight: 900 }}>
              Шаг {index()}.
            </span>{" "}
            Введите идентификатор показателя
          </span>
          <div>
            <Form.Item name="id">
              <Input placeholder="Идентификатор показателя" disabled />
            </Form.Item>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontSize: 18, fontWeight: 700 }}>
            <span style={{ color: "#37AB49", fontWeight: 900 }}>
              Шаг {index()}.
            </span>{" "}
            Введите наименование показателя
          </span>
          <div ref={ref1}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите наименование показателя",
                },
              ]}
            >
              <Input placeholder="Наименование показателя" />
            </Form.Item>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontSize: 18, fontWeight: 700 }}>
            <span style={{ color: "#37AB49", fontWeight: 900 }}>
              Шаг {index()}.
            </span>{" "}
            Введите описание показателя
          </span>
          <div ref={ref2}>
            <Form.Item name="description">
              <Input placeholder="Описание показателя" />
            </Form.Item>
          </div>
        </div>

        <Form.Item name="type">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Выберите тип показателя
            </span>
            <div>
              <Select
                placeholder={"Тип показателя"}
                options={FigureType.map(({ id: value, label }) => ({
                  value,
                  label,
                }))}
                value={objType}
                onChange={(type) => setObjType(type)}
                disabled
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>

        <Form.Item name="level">
          <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                {" "}
                Шаг {index()}.
              </span>{" "}
              Выберите базовый уровень планирования
            </span>
            <div>
              <Select
                disabled
                placeholder="Выберите уровень планирования из выпадающего списка"
                options={levels.map(({ id: value, name: label }) => ({
                  value,
                  label,
                }))}
                onChange={(level) => setObjLevel(level)}
                value={objLevel}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>

        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontSize: 18, fontWeight: 700 }}>
            <span style={{ color: "#37AB49", fontWeight: 900 }}>
              Шаг {index()}.
            </span>{" "}
            Укажите формулу расчета
          </span>
          <div ref={ref3}>
            <Form.Item name="formulacalc">
              <Input
                placeholder="Пропишите формулу расчета показателя"
                disabled={objType === "STORED"}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item name="aggmode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Агрегация (по атрибутам)
            </span>
            <div ref={ref4}>
              <Select
                placeholder="Выберите метод агрегации"
                options={FigureAggAttrModes.map(({ id: value, label }) => ({
                  value,
                  label,
                }))}
                onChange={(method) => setObjAgg(method)}
                value={objAgg}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>

        <Form.Item name="aggtimemode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Агрегация (по времени)
            </span>
            <div ref={ref5}>
              <Select
                placeholder="Выберите метод агрегации"
                options={FigureAggTimeModes.map(({ id: value, label }) => ({
                  value,
                  label,
                }))}
                onChange={(method) => setObjAggTime(method)}
                value={objAggTime}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>

        <Form.Item name="disaggmode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Дезагрегация (по атрибутам)
            </span>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <div style={{ width: "50%" }} ref={ref6}>
                <Select
                  placeholder="Выберите метод дезагрегации"
                  options={FigureDisAgrModes.map(({ id: value, label }) => ({
                    value,
                    label,
                    disabled: !(
                      value !== "FIGURE" || possibleFiguresOptions.length > 0
                    ),
                  }))}
                  onChange={(method) => setObjDisagg(method)}
                  value={objDisagg}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </div>
              <span style={{ width: "50%" }} ref={ref7}>
                <Select
                  disabled={
                    !(
                      objDisagg === "FIGURE" &&
                      possibleFiguresOptions.length > 0
                    )
                  }
                  options={possibleFiguresOptions}
                  allowClear
                  onChange={(figure) => setObjDisaggFigure(figure)}
                  value={objDisaggFigure}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </span>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="disaggtimemode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Дезагрегация (по времени)
            </span>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <div style={{ width: "50%" }} ref={ref8}>
                <Select
                  placeholder="Выберите метод дезагрегации"
                  options={FigureDisTimeModes.map(({ id: value, label }) => ({
                    value,
                    label,
                    disabled: !(
                      value !== "FIGURE" || possibleFiguresOptions.length > 0
                    ),
                  }))}
                  onChange={(method) => setObjDisaggTime(method)}
                  value={objDisaggTime}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </div>
              <span style={{ width: "50%" }} ref={ref9}>
                <Select
                  disabled={
                    !(
                      objDisaggTime === "FIGURE" &&
                      possibleFiguresOptions.length > 0
                    )
                  }
                  options={possibleFiguresOptions}
                  allowClear
                  onChange={(figure) => setObjDisaggTimeFigure(figure)}
                  value={objDisaggTimeFigure}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </span>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#37AB49" }}
            >
              ✏ Изменить
            </Button>
            <Button type="dashed" onClick={() => setOpen(true)}>
              🎓 Инструкция
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FigureEditPage;
