import { InboxOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadProps, message, notification } from "antd";
import { useEffect, useImperativeHandle, useState } from "react";
import AttributeService from "../../entities/model/AttributeService";
import { AttributeCreate } from "../../entities/types/IAttribute";
import { IBackResponse } from "../../entities/types/response";
import { API_URL } from "../../shared/api/axios";
import Button from "../../shared/components/button";
import LoadingSpinner from "../../shared/components/loader";
import { exportToExcel } from "../../shared/helper/excelExport";

interface ComponentProps {
  apiRef: any;
  fetchData: any;
  columns: any[];
  id: string | undefined;
}

function DictionaryRecordImportModal({
  apiRef,
  fetchData,
  columns,
  id,
}: ComponentProps) {
  const { Dragger } = Upload;

  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [exportColumns, setExportColumns] = useState<any>({});

  const handleCreate = async (event: any) => {
    event.preventDefault();
    const attribute = new AttributeCreate({
      id: modalID,
      name: modalName,
    });
    let response: IBackResponse = await AttributeService.create(attribute);
    switch (response.code) {
      case 1005:
        fetchData();
        apiRef.current?.closeModal();
        return notification.success({
          message: "Атрибут создан",
          description: response?.text,
        });
      case 1008:
        fetchData();
        apiRef.current?.closeModal();
        return notification.success({
          message: "Атрибут изменен",
          description: response?.text,
        });
      default:
        return notification.error({
          message: "Ошибка при создании атрибута",
          description: response?.text,
        });
    }
  };

  useImperativeHandle(apiRef, () => ({
    openModal: () => {
      setOpenModal(true);
    },
    closeModal: () => {
      setOpenModal(false);
    },
  }));

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".xlsx",
    action: `${API_URL.xModel}/dictionary/importexcel`,
    data: { id },
    withCredentials: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        <LoadingSpinner />;
      }
      if (status === "done") {
        console.log(info.file.response);
        message.warning(`${info.file.response.text}`);
        fetchData();
        setOpenModal(false);
      } else if (status === "error") {
        message.error(`Возникла ошибка при попытке загрузки файла`);
      }
    },
    onDrop(e) {},
  };

  useEffect(() => {
    let dict: any = {};
    for (const column of columns) {
      dict[column.id] = "";
    }
    setExportColumns(dict);
  }, [columns]);

  return (
    <Modal open={openModal} onCancel={apiRef?.current?.closeModal}>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл</p>
          <p className="ant-upload-hint">
            Файл должен содержать список записей для словаря {id} в установленом
            формате.
          </p>
        </Dragger>
        <Button
          label="Скачать формат файла"
          type="primary"
          onClick={() => exportToExcel(`Импорт_словарь_${id}`, [exportColumns])}
        />
      </div>
    </Modal>
  );
}

export default DictionaryRecordImportModal;
