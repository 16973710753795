import { useQuery } from "@tanstack/react-query";
import { Button, Input, Modal, Select, Table, TableProps } from "antd";
import { produce } from "immer";
import { useMemo } from "react";
import AgentService from "../../../entities/model/AgentService";
import FlowService from "../../../entities/model/FlowService";
import { fuzzyIsIn } from "../../../shared/helper/comparison";
import { EXCEPTION_VARIABLE_UUID, useICState } from "./state";

type Props = {
  modalState: boolean;
  setModalState: (state: boolean) => void;
};

type PaginationResponse<T> = {
  count: number;
  rows: T[];
};

const Menu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      {children}
    </div>
  );
};

const MenuItem: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

enum ParameterType {
  CONSTANT = "constant",
  VARIABLE = "variable",
}

type Parameter<T = any> = {
  type: ParameterType;
  value: T;
};

type State = {
  flow: Parameter<string>;
  agent: Parameter<string>;
  variables: any[];
};

const initialState: State = {
  flow: { type: ParameterType.CONSTANT, value: "" },
  agent: { type: ParameterType.CONSTANT, value: "" },
  variables: [],
};

const Editor = ({
  state,
  onChange,
}: {
  state: State;
  onChange: (state: (state: State) => State) => void;
}) => {
  const { variables } = useICState();

  const setStateKeyValue = (key: string) => (value: any) =>
    onChange((state) => ({ ...state, [key]: value }));

  const setFlow = setStateKeyValue("flow");
  const setAgent = setStateKeyValue("agent");
  const setVariables = setStateKeyValue("variables");

  const { data: { rows: flows = [] } = {} } = useQuery<
    PaginationResponse<{ id: string; project: string }>
  >({
    queryKey: ["flows", "launchFlow"],
    queryFn: async () => {
      const response = await FlowService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка заданий интеграции: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const flowOptions = useMemo(
    () => [
      ...flows.map(({ id }) => ({
        value: id,
        label: id,
        data: {
          type: ParameterType.CONSTANT,
          value: id,
        },
      })),
      ...variables
        .filter((v) => v.uuid !== EXCEPTION_VARIABLE_UUID)
        .map(({ id }) => ({
          value: `VARIABLE_${id}`,
          label: `🧮 ${id}`,
          data: {
            type: ParameterType.VARIABLE,
            value: id,
          },
        })),
    ],
    [flows, variables],
  );

  const { data: agents = [] } = useQuery<{ id: string }[]>({
    queryKey: ["integration-agents", "launchFlow"],
    queryFn: async () => {
      const response = await AgentService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка агентов интеграции: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const agentOptions = useMemo(
    () => [
      ...agents.map(({ id }) => ({
        value: id,
        label: id,
        data: {
          type: ParameterType.CONSTANT,
          value: id,
        },
      })),
      ...variables
        .filter((v) => v.uuid !== EXCEPTION_VARIABLE_UUID)
        .map(({ id }) => ({
          value: `VARIABLE_${id}`,
          label: `🧮 ${id}`,
          data: {
            type: ParameterType.VARIABLE,
            value: id,
          },
        })),
    ],
    [agents, variables],
  );

  async function fetchVariables(flow: string) {
    const response = await FlowService.getOne(flow);

    if (response.code !== 1) {
      throw new Error(`Ошибка при попытке загрузки потока: "${response.text}"`);
    }

    return response.data;
  }

  const onFlowSelect = (flow: Parameter<string>) => {
    setFlow(flow);

    if (flow.type === ParameterType.CONSTANT) {
      fetchVariables(flow.value).then((data) =>
        setVariables(
          data.variables.filter((v) => v.uuid !== EXCEPTION_VARIABLE_UUID),
        ),
      );
    } else {
      setVariables([]);
    }
  };

  const variableColumns: TableProps<any>["columns"] = [
    {
      title: "Переменная",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Значение",
      dataIndex: "",
      key: "value",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Input
            defaultValue={record.defaultValue}
            value={record.value}
            onChange={({ target: { value } }) =>
              setVariables(
                state.variables.map((v) =>
                  v.id === record.id ? { ...v, value } : v,
                ),
              )
            }
          />
          <Button
            onClick={() => {
              setVariables(
                state.variables.map((v) =>
                  v.id === record.id ? { ...v, value: v.defaultValue } : v,
                ),
              );
            }}
          >
            ↩️
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Menu>
        <MenuItem>
          <div style={{ width: "150px", fontWeight: "bold" }}>Выбор потока</div>
          <Select
            value={state.flow.value}
            style={{ width: "300px", color: "black" }}
            onChange={(id) =>
              onFlowSelect(flowOptions.find((x) => x.value === id)!.data)
            }
            options={flowOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </MenuItem>
        <MenuItem>
          <div style={{ width: "150px", fontWeight: "bold" }}>Выбор агента</div>
          <Select
            value={state.agent.value}
            style={{ width: "300px", color: "black" }}
            onChange={(id) =>
              setAgent(agentOptions.find((x) => x.value === id)!.data)
            }
            options={agentOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </MenuItem>
      </Menu>
      <div style={{ marginTop: "15px" }}>
        <Table
          columns={variableColumns}
          dataSource={state.variables}
          pagination={false}
        />
      </div>
    </div>
  );
};

const ExceptionModal: React.FC<Props> = ({ modalState, setModalState }) => {
  const { variables, setVariables } = useICState();

  const exception = variables?.find((v) => v.uuid === EXCEPTION_VARIABLE_UUID);

  const onCreate = () => {
    setVariables(
      produce((vs: any[]) => {
        vs.push({
          uuid: EXCEPTION_VARIABLE_UUID,
          id: "__IBPSPECIAL__EXCEPTION__",
          type: "STRING",
          desc: "Обработчик ошибок",
          defaultValue: JSON.stringify(initialState),
        });
      }),
    );
  };

  const onRemove = () => {
    setVariables(
      produce((vs: any[]) => {
        const index = vs.findIndex((v) => v.uuid === EXCEPTION_VARIABLE_UUID);
        if (index !== -1) {
          vs.splice(index, 1);
        }
      }),
    );
  };

  if (typeof exception === "undefined") {
    return (
      <Modal
        title="Обработчик ошибок"
        open={modalState}
        onCancel={() => setModalState(false)}
        footer={[]}
      >
        <Button type="primary" onClick={onCreate}>
          Создать
        </Button>
      </Modal>
    );
  }

  const state = JSON.parse(exception.defaultValue);

  const onChange = (producer: (state: State) => State) => {
    setVariables(
      produce((vs: any[]) => {
        const index = vs.findIndex((x) => x.uuid === EXCEPTION_VARIABLE_UUID);
        if (index !== -1) {
          const value = producer(JSON.parse(vs[index].defaultValue));
          vs[index].defaultValue = JSON.stringify(value);
        }
      }),
    );
  };

  return (
    <Modal
      title="Обработчик ошибок"
      width={"1000px"}
      open={modalState}
      onCancel={() => setModalState(false)}
      footer={[]}
    >
      <Editor state={state} onChange={onChange} />
      <Button type="default" danger onClick={onRemove}>
        Удалить
      </Button>
    </Modal>
  );
};

export default ExceptionModal;
