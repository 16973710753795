import Button from "antd/lib/button";
import Radio from "antd/lib/radio";
import React from "react";

export type Format = "xlsx" | "csv";
export type ColumnsType = "id" | "name";

export type Props = {
  onDownload: ({
    format,
    columnsType,
  }: {
    format: Format;
    columnsType: ColumnsType;
  }) => void;
};

export const DownloadMenu: React.FC<Props> = ({ onDownload }) => {
  const [format, setFormat] = React.useState<Format>("xlsx");
  const [columnsType, setColumnsType] = React.useState<ColumnsType>("name");

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
          Формат файла
        </span>
        <Radio.Group
          onChange={({ target: { value } }) => setFormat(value)}
          value={format}
        >
          <Radio value="xlsx">XLSX</Radio>
          <Radio value="csv">CSV</Radio>
        </Radio.Group>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
          Наименования столбцов
        </span>
        <Radio.Group
          onChange={({ target: { value } }) => setColumnsType(value)}
          value={columnsType}
        >
          <Radio value="name">Стандартные</Radio>
          <Radio value="id">Технические</Radio>
        </Radio.Group>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Button onClick={() => onDownload({ format, columnsType })}>
          Скачать
        </Button>
      </div>
    </div>
  );
};

export default DownloadMenu;
