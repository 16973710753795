import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Input, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import FlatFileService from "../../../../../entities/model/FlatFileService";
import { IFileFormat } from "../../../../../entities/types/IFileFormat";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Props = {
  id: string;
};

interface Format {
  value: string;
  label: string;
}

type State = {
  path: string;
  format: string;
  formula: MappingField[];
};

const initialState: State = {
  path: "",
  format: "",
  formula: [],
};

const FlatFileWindow: React.FC<Props> = ({ id }) => {
  const { nodes, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  const [formatOptions, setFormatOptions] = useState<Format[]>([]);
  const [fileFormats, setFileFormats] = useState<IFileFormat[]>([]);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.path = state.path;
          node.data.format = state.format;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.path = node.data.path;
          state.format = node.data.format;
          state.formula = node.data.formula;
        }),
      );
    }
  }, [id]);

  const handleFetch = async () => {
    const response = await FlatFileService.getAll();
    if (response.code === 1) {
      const options: Format[] = response.data.rows.map((el: any) => ({
        label: el.id,
        value: el.id,
      }));
      setFileFormats(response.data.rows);
      setFormatOptions(options);
    } else {
      message.error(
        "Ошибка при попытке получения форматов файла. Обратитесь к администратору.",
      );
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const onFormatChange = (format: string) => {
    const ff = fileFormats.find((x) => x.id === format);

    setState(
      produce((state) => {
        state.format = format;
        state.formula = ff.fields;
      }),
    );
  };

  const onPathChange = (path: string) => {
    setState(
      produce((state) => {
        state.path = path;
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Формат</div>
          <Select
            style={{ width: "350px", color: "black" }}
            className="fileformat__select"
            value={state.format}
            onChange={onFormatChange}
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={formatOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Путь</div>
          <Input
            style={{ width: "350px", color: "black" }}
            value={state.path}
            onChange={R.pipe(R.path(["target", "value"]), onPathChange)}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.formula?.length > 0 ? (
              state.formula.map((f, i) => (
                <TableRow key={i}>
                  <TableCell>{f.id}</TableCell>
                  <TableCell>{f.desc}</TableCell>
                  <TableCell>{f.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default FlatFileWindow;
