import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Button, Input, Modal, notification, Tag } from "antd";
import React from "react";
import RoleService from "../../entities/model/RoleService";
import { IRoleList } from "../../entities/types/IRole";
import CreateRole from "./createRole";
import EditRole from "./editRole";

const RolesPage: React.FC = () => {
  const [filterText, setFilterText] = React.useState<string>("");
  const [addModalState, setAddModalState] = React.useState<boolean>(false);

  const [editModalState, setEditModalState] = React.useState<boolean>(false);
  const [editModalRole, setEditModalRole] = React.useState<IRoleList>();

  const { data: roles = [], refetch } = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await RoleService.getList();

      if (response.code !== 0) {
        throw new Error(`Ошибка при загрузке ролей: ${response.text}`);
      }

      return response.data;
    },
  });

  const createRole = async ({
    id,
    name,
    description,
    catalogs,
  }: Record<string, any>) => {
    const response = await RoleService.create({
      id,
      name,
      description,
      catalogs,
    });

    if (response.code !== 0) {
      notification.error({
        message: `Ошибка при создании роли: ${response.text}`,
      });
      return;
    }

    notification.success({ message: "Роль успешно создана" });
    setAddModalState(false);
    refetch();
  };

  const editRole = async ({
    id,
    name,
    description,
    catalogs,
  }: Record<string, any>) => {
    const response = await RoleService.update({
      id,
      name,
      description,
      catalogs,
    });

    if (response.code !== 0) {
      notification.error({
        message: `Ошибка при обновлении роли: ${response.text}`,
      });
      return;
    }

    notification.success({ message: "Роль успешно обновлена" });
    setEditModalState(false);
    setEditModalRole(undefined);
    refetch();
  };

  const deleteRole = async (id: string) => {
    const response = await RoleService.delete(id);

    if (response.code !== 0) {
      notification.error({
        message: `Ошибка при удалении роли: ${response.text}`,
      });
      return;
    }

    notification.success({ message: "Роль успешно удалена" });
    refetch();
  };

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>Всего ролей: {roles ? roles.length : 0}</span>
          <Button type="primary" onClick={() => setAddModalState(true)}>
            Добавить
          </Button>
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>ИД</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Наименование</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Каталоги</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Фильтры</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles &&
              roles
                .filter((role) => {
                  const searchText = filterText.toLowerCase();
                  return (
                    role.id.toString().toLowerCase().includes(searchText) ||
                    role.name.toLowerCase().includes(searchText) ||
                    role.description?.toLowerCase().includes(searchText)
                  );
                })
                .map((role: IRoleList) => (
                  <TableRow key={role.id}>
                    <TableCell>{role.id}</TableCell>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>{role.description}</TableCell>
                    <TableCell>
                      {role.catalogs.map((catalog: string) => (
                        <Tag color="orange-inverse">{catalog}</Tag>
                      ))}
                    </TableCell>
                    <TableCell>
                      {role.filters.map((filter: string) => (
                        <Tag color="orange-inverse">{filter}</Tag>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                        }}
                      >
                        <Button
                          type="dashed"
                          onClick={() => {
                            setEditModalRole(role);
                            setEditModalState(true);
                          }}
                        >
                          Изменить
                        </Button>
                        <Button
                          type="dashed"
                          danger
                          onClick={() => deleteRole(role.id)}
                        >
                          Удалить
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        title="Создание роли"
        open={addModalState}
        onCancel={() => setAddModalState(false)}
        width="500px"
        destroyOnClose
        footer={[]}
      >
        <CreateRole onSubmit={createRole} />
      </Modal>
      <Modal
        title="Изменение роли"
        open={editModalState}
        onCancel={() => {
          setEditModalState(false);
          setEditModalRole(undefined);
        }}
        width="500px"
        destroyOnClose
        footer={[]}
      >
        {editModalRole && (
          <EditRole
            id={editModalRole.id}
            name={editModalRole.name}
            description={editModalRole.description}
            catalogs={editModalRole.catalogs}
            onSubmit={editRole}
          />
        )}
      </Modal>
    </>
  );
};

export default RolesPage;
