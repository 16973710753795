import "./wdyr";

import { setAutoFreeze } from "immer";
import { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./app/global/color.css";
import "./app/global/fonts.css";
import "./app/global/index.css";
import Store from "./entities/model/store";

setAutoFreeze(false);

const store = new Store();

interface State {
  store: Store;
  content: {
    type: "default" | "custom";
    setType: (value: "default" | "custom") => void;
  };
}

const Context = createContext<State>({
  store,
  content: { type: "default", setType: () => {} },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

export const useAppContext = () => useContext(Context);

const Root = () => {
  const [type, setType] = useState<"default" | "custom">("default");

  const value = {
    store,
    content: { type, setType },
  };

  return (
    <Context.Provider value={value}>
      <App />
    </Context.Provider>
  );
};

root.render(<Root />);
