import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Input, Select, notification } from "antd";
import { useMemo, useRef, useState } from "react";
import AttributeService from "../../entities/model/AttributeService";
import { AttributeCreate } from "../../entities/types/IAttribute";
import { IBackResponse } from "../../entities/types/response";
import Button from "../../shared/components/button";
import { fuzzyIsIn } from "../../shared/helper/comparison";

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

function CreateAttribute({ onSuccess, onCancel }: Props) {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [reference, setReference] = useState<string>("");

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const handleCreate = async (event: any) => {
    event.preventDefault();

    const attribute = new AttributeCreate({
      id: id,
      name: name,
      description: description,
      type: type,
      reference: reference,
    });

    const response: IBackResponse = await AttributeService.create(attribute);

    if (response.code !== 1005) {
      return notification.error({
        message: "Ошибка при создании атрибута",
        description: response?.text,
      });
    }

    notification.success({
      message: "Атрибут изменен",
      description: response?.text,
    });

    onSuccess();
  };

  const { data = [] } = useQuery({
    retry: false,
    queryKey: ["attributes"],
    queryFn: async () => {
      const response = await AttributeService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке атрибутов: "${response.text}"`,
        );
      }

      return response.data.rows;
    },
  });

  const attributes = useMemo(
    () => data.map(({ id, name }: any) => ({ label: name, value: id })),
    [data],
  );

  // const steps: TourProps["steps"] = [
  //   {
  //     target: () => ref1.current,
  //     title: "Введите идентификатор атрибута",
  //     description: "Индификатор должен содержать только латинские буквы и цифры от 0 до 9.",
  //   },

  //   {
  //     target: () => ref2.current,
  //     title: "Введите наименование атрибута",
  //     description:
  //       "Краткое наименование атрибута, отражающее его суть (например, Продукт или Клиент). Доступна любая форма наименования.",
  //   },

  //   {
  //     target: () => ref3.current,
  //     title: "Введите краткое описание атрибута",
  //     description: "Оно поможет в дальнейшем не потерять бизнес или технический смысл созданного атрибута.",
  //   },

  //   {
  //     target: () => ref4.current,
  //     title: "Выберите тип атрибута",
  //     description: "Атрибут может быть разного вида (строка, число, дата). Но пока доступна только строка.",
  //   },

  //   {
  //     target: () => ref5.current,
  //     title: "Не забудьте нажать сохранить!",
  //     description: "Иначе атрибут не будет создан.",
  //   },
  // ];

  return (
    <div>
      {/* <ConfigProvider locale={ruRU}>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          mask={false}
          type='primary'
          steps={steps}
          zIndex={1000000}
          placement={'right'}
        />
      </ConfigProvider> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <span style={{ fontSize: 18, fontWeight: 900 }}>
            Создание атрибута
          </span>

          {/* <Button
            label='🎓 Инструкция'
            type='dashed'
            onClick={() => setOpen(true)}
          /> */}
        </Stack>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
          Введите идентификатор атрибута
        </span>
        <Input
          id="id"
          placeholder="Идентификатор"
          onInput={(e) =>
            setId((e.target as HTMLInputElement).value.toUpperCase())
          }
          size="large"
          value={id}
          ref={ref1}
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
          Введите наименование атрибута
        </span>
        <Input
          id="name"
          placeholder="Наименование"
          onInput={(e) => setName((e.target as HTMLInputElement).value)}
          size="large"
          value={name}
          ref={ref2}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
          Введите описание атрибута
        </span>
        <Input
          id="description"
          placeholder="Описание"
          onInput={(e) => setDescription((e.target as HTMLInputElement).value)}
          value={description}
          size="large"
          ref={ref3}
        ></Input>
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
          Выберите тип атрибута
        </span>
        <Select
          optionFilterProp="children"
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
          options={[{ label: "Строка", value: "STRING" }]}
          onChange={(e) => setType(e)}
          value={type}
          size="large"
          placeholder="Тип атрибута"
          ref={ref4}
        />
        <span style={{ fontSize: 18, fontWeight: 700 }}>
          <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 5.</span> Если
          применимо, выберите связанный атрибут
        </span>
        <Select
          optionFilterProp="children"
          showSearch={true}
          filterOption={(input, option?: { label: string; value: string }) =>
            fuzzyIsIn(input, option?.label ?? "")
          }
          onChange={(e) => setReference(e)}
          options={attributes}
          value={reference}
          size="large"
          placeholder="Связанный атрибут"
        />
        <div
          ref={ref5}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <Button type="primary" label="➕ Создать" onClick={handleCreate} />
          <Button type="default" danger label="Отменить" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}

export default CreateAttribute;
