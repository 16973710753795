import _ from "lodash";
import $api, { API_URL } from "../../shared/api/axios";
import { IBackResponse } from "../types/response";

export default class CalculationService {
  static async request(
    model: string,
    level: string[],
    figure: string,
    time: string,
    filter: any[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xMath}/figure/request`, {
      model,
      level,
      figure,
      time,
      filter,
    });
    if (response.data) {
      return response.data;
    } else {
      return { code: 0, text: "Ошибка при запросе", data: [] };
    }
  }

  static async disaggregate(
    model: string,
    level: string[],
    time: string,
    data: { [key: string]: any }[],
  ) {
    const figures = [
      ...new Set(_.difference(data.flatMap(Object.keys), [...level, time])),
    ];

    const promises = figures.map(async (figure) => {
      const portion = data
        .filter((item) => Object.hasOwn(item, figure))
        .map((item) => {
          const newItem = { ..._.pick(item, level), ..._.pick(item, [figure]) };
          newItem["DATE"] = item[time];
          return newItem;
        });

      const response = await $api.post(`${API_URL.xMath}/figure/desaggregate`, {
        settings: { model, level, figure, time },
        data: portion,
      });

      if (response.data.code !== 100) {
        throw new Error("Request have failed.");
      }

      return response;
    });

    const response = await Promise.all(promises);

    return response;
  }
}
