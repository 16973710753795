import { format } from "date-fns";

export interface IModel {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export class Model implements IModel {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.createdAt = format(new Date(model.createdAt), "dd.MM.yyyy HH:mm:ss");
    this.updatedAt = format(new Date(model.updatedAt), "dd.MM.yyyy HH:mm:ss");
    this.createdBy = model.createdBy;
    this.updatedBy = model.updatedBy;
  }
}

export interface IModelCreate {
  id: string;
  name: string;
  description: string;
}

export class ModelCreate implements IModelCreate {
  id: string;
  name: string;
  description: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
  }
}

export interface ITimeModel {
  model: string;
  time: string;
  enabled: boolean;
  past: number | null;
  future: number | null;
}

export interface IAttributeModel {
  attributeId: string;
  attributeName: string;
  dictionaryId: string;
  dictionaryName: string;
}

export interface IModelGeneralInfo {
  model: {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
  };
  time: ITimeModel[];
}

export interface attributeLevel {
  id: string;
  attribute: string;
  name: string;
  selected: boolean;
  key: boolean;
}
