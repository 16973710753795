import InboxOutlined from "@ant-design/icons/InboxOutlined";
import message from "antd/lib/message";
import notification from "antd/lib/notification";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { API_URL } from "../../shared/api/axios";
import Button from "../../shared/components/button";
import { exportToExcel } from "../../shared/helper/excelExport";

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

function ImportAttribute({ onSuccess, onCancel }: Props) {
  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status, response } = info.file;

    if (status === "error") {
      return message.error(`Возникла ошибка при попытке загрузки файла`);
    }

    if (status === "done") {
      message.success(`${response.text}`);

      response.data
        .filter((x: any) => x.type === "ERROR")
        .forEach((x: any) =>
          notification.error({
            message: `Атрибут ${x.id} (стр. ${x.row}): ошибка при загрузке`,
            description: `Код ответа: ${x.code}`,
          }),
        );

      onSuccess();
    }
  };

  return (
    <div
      style={{
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <Dragger
        name="file"
        accept=".xlsx"
        multiple={false}
        action={`${API_URL.xModel}/attribute/importexcel`}
        withCredentials={true}
        onChange={onChange}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Нажмите или перетащите файл</p>
        <p className="ant-upload-hint">
          Файл должен содержать список атрибутов в установленном формате.
        </p>
      </Dragger>
      <Button
        label="Скачать формат файла"
        type="primary"
        onClick={() =>
          exportToExcel("Импорт_Атрибут", [
            {
              id: "Идентификатор атрибута",
              name: "Наименование атрибута",
              type: "Тип атрибута (STRING)",
              description: "Опциональное описание",
            },
          ])
        }
      />
    </div>
  );
}

export default ImportAttribute;
