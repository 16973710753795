import $api, { API_URL } from "../../shared/api/axios";
import { Version, VersionCreate } from "../types/IVersion";
import { BackResponse, IBackResponse } from "../types/response";
import UserService from "./UserService";

export default class VersionService {
  static async getAll(params: {
    modelId: string;
    levelId?: string | undefined;
  }): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/version/getList`,
      params,
    );

    response.data.data = response.data.data.map(
      (model: any) => new Version(model),
    );
    for (const element of response.data.data) {
      element.createdBy = await UserService.getUsernameById(element.createdBy);
      element.updatedBy = await UserService.getUsernameById(element.updatedBy);
    }

    return response.data;
  }

  static async create(version: VersionCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/version/create`, {
      ...version,
    });
    return new BackResponse(response.data);
  }

  static async update(version: VersionCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/version/update`, {
      ...version,
    });
    return new BackResponse(response.data);
  }

  static async delete(
    modelId: string,
    versionId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/version/delete`, {
      modelId,
      versionId,
    });
    return new BackResponse(response.data);
  }

  static async getFigures(
    modelId: string,
    versionId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/version/getfigures`, {
      modelId,
      versionId,
    });

    return new BackResponse(response.data);
  }

  static async addFigure(
    modelId: string,
    versionId: string,
    figureId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/version/assign`, {
      modelId,
      versionId,
      figureId,
    });
    return new BackResponse(response.data);
  }

  static async removeFigure(
    modelId: string,
    versionId: string,
    figureId: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/version/unassign`, {
      modelId,
      versionId,
      figureId,
    });
    return new BackResponse(response.data);
  }

  static async getAvailableFigures(
    modelId: string,
    versionId?: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xModel}/version/getavailablefigures`,
      { modelId, versionId },
    );
    return new BackResponse(response.data);
  }
}
