/**
 * Return current value then increment it.
 * Can be used for automatic list enumeration.
 */
export const counter = ({ start = 0 } = {}) => {
  let value = start;

  return () => {
    let current = value;
    value += 1;
    return current;
  };
};
