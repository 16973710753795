import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, Popover, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import FlatFileService from "../../../../../entities/model/FlatFileService";
import { IFileFormat } from "../../../../../entities/types/IFileFormat";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

type Props = {
  id: string;
};

interface Option {
  value: string;
  label: string;
}

type State = {
  path: string;
  format: string;
  formula: MappingField[];
};

const initialState: State = {
  path: "",
  format: "",
  formula: [],
};

const TargetFlatFileWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setFormat = setStateKeyValue("format");
  const setPath = setStateKeyValue("path");
  const setFormula = setStateKeyValue("formula");

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.path = state.path;
          node.data.format = state.format;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [transform, setTransform] = useState<string | null>(null);

  const [fileFormats, setFileFormats] = useState<IFileFormat[]>([]);
  const [formatOptions, setFormatOptions] = useState<Option[]>([]);

  // load file formats
  useEffect(() => {
    const fetchFileFormats = async () => {
      const response = await FlatFileService.getAll();
      if (response.code === 1) {
        const options: Option[] = response.data.rows.map((el: any) => ({
          label: el.id,
          value: el.id,
        }));
        setFileFormats(response.data.rows);
        setFormatOptions(options);
      } else {
        message.error(
          "Ошибка при попытке получения форматов файла. Обратитесь к администратору.",
        );
      }
    };

    fetchFileFormats();
  }, []);

  useEffect(() => {
    setInFs([]);
    setTransform(null);

    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.path = node.data.path;
          state.format = node.data.format;
          state.formula = node.data.formula || [];
        }),
      );
    }

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id]);

  useEffect(() => {
    if (state.format && fileFormats.length > 0) {
      const ff = fileFormats.find((ff) => ff.id === state.format);

      setFormula(
        produce((fs: any) => {
          return ff.fields.map((field: any) => {
            const f = fs.find((f) => f.uuid === field.uuid);
            const formula = f ? f.formula : undefined;
            return { ...field, formula };
          });
        }),
      );
    }
  }, [fileFormats, state.format]);

  const outFsMap: Record<string, MappingField> = {};

  state.formula.forEach((f) => {
    outFsMap[f.uuid] = f;
  });

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Формат</div>
          <Select
            style={{ width: "350px", color: "black" }}
            value={state.format}
            onChange={setFormat}
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={formatOptions}
            className="fileformat__select"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Путь</div>
          <Input
            style={{ width: "350px", color: "black" }}
            value={state.path ? state.path : ""}
            onChange={R.pipe(R.path(["target", "value"]), setPath)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "335px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>⤵️ Входящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() =>
                          IntegrationFieldsManager.mapAllFieldsToRight({
                            sourceFields: inFs,
                            setTargetFields: setFormula,
                          })
                        }
                      >
                        ↘
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inFs.length > 0 ? (
                    inFs.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            f.id,
                            state.formula,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              IntegrationFieldsManager.mapFieldToTarget(
                                f.id,
                                inFs,
                                setFormula,
                              )
                            }
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>⤴️ Исходящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.formula.length > 0 ? (
                    state.formula.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.formula && f.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.id || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.id || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "id"),
                              )}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.desc || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.desc || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "desc"),
                              )}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.type || ""}>
                            <Select
                              style={{ width: "100%" }}
                              value={outFsMap[f.uuid]?.type || ""}
                              onChange={makeFormulaUpdater(f.uuid, "type")}
                              options={__optionType}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setTransform(f.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {state.formula.find((el) => el.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log({ nodes, edges })}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default TargetFlatFileWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
