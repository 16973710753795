import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { Button } from "antd";
import { Link } from "react-router-dom";

const OperatorPage: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <Link to="/operator/create">
          <Button size="large" type="primary" style={{ width: "110px" }}>
            Создать
          </Button>
        </Link>
        <Button size="large" type="dashed" style={{ width: "110px" }}>
          Обновить
        </Button>
      </div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell>Создал</TableCell>
              <TableCell>Изменил</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          {__data.map((el: any) => {
            return (
              <TableRow>
                <TableCell>{el.id}</TableCell>
                <TableCell>{el.description}</TableCell>
                <TableCell>{el.createdBy}</TableCell>
                <TableCell>{el.updatedBy}</TableCell>
                <TableCell>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Button>✏️</Button>
                    <Button>❌</Button>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default OperatorPage;

const __data: any = [
  {
    id: "COPYDATA",
    description: "Копирование чего-нибудь",
    modelId: "CALC",
    createdBy: "Д.Джамиев",
    createdAt: "...",
    updatedBy: "Д.Джамиев",
    updatedAt: "...",
  },
];
