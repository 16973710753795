import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Button, Popconfirm, Tag, notification } from "antd";
import { format } from "date-fns";
import type {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { useState } from "react";
import { Link } from "react-router-dom";
import FlowService from "../../../entities/model/FlowService";
import UserPopover from "../../../shared/components/popovers/user";
import IntegrationFlowCopy from "./flowCopy";
import IntegrationFlowLaunch from "./flowLaunch";

type TasksListResponse = {
  code: number;
  data: {
    rows: any[];
    count: number;
  };
};

const IntegrationTaskPage = () => {
  const [copyModalState, setCopyModalState] = useState<boolean>(false);
  const [copyFlowTarget, setCopyFlowTarget] = useState<string | null>();
  const [copyFlowName, setCopyFlowName] = useState<string | null>();

  const [launchModalState, setLaunchModalState] = useState<boolean>(false);
  const [launchFlowId, setLaunchFlowId] = useState<string | null>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "id", desc: false },
  ]);

  const {
    data: { data: { rows, count } } = { data: { rows: [], count: 0 } },
    isError: isDataError,
    isRefetching: isDataRefetching,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useQuery<TasksListResponse>({
    placeholderData: keepPreviousData,
    queryKey: [
      "integration-tasks",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await FlowService.getAll({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка заданий интеграции: "${response.text}"`,
        );
      }

      return response;
    },
  });

  const refresh = () => {
    refetchData();
  };

  const openCopyDrawer = (id: string) => {
    setCopyFlowTarget(id);
    setCopyModalState(true);
  };

  const openLaunchDrawer = (id: string) => {
    setLaunchFlowId(id);
    setLaunchModalState(true);
  };

  const closeCopyDrawer = () => {
    setCopyFlowTarget("");
    setCopyFlowName("");
    setCopyModalState(false);
    refetchData();
  };

  const closeLaunchDrawer = () => {
    setLaunchFlowId("");
    setLaunchModalState(false);
    refetchData();
  };

  const copyFlow = async () => {
    if (!(copyFlowName && copyFlowName.length > 0 && copyFlowTarget)) {
      notification.error({ message: "Введите наименование потока" });
      return;
    }

    const response = await FlowService.copy(copyFlowTarget, copyFlowName);

    if (response.code !== 1) {
      notification.error({ message: "Ошибка при попытке копирования" });
      return;
    }

    notification.success({ message: `Успешно скопировано` });
    closeCopyDrawer();
  };

  const deleteFlow = async (id: string) => {
    const response = await FlowService.delete(id);

    if (response.code !== 1) {
      notification.error({
        message: "Возникла ошибка при потока",
        description: `Код ответа: ${response.code}`,
      });
      return;
    }

    notification.warning({ message: `Поток ${id} успешно удален` });
    refetchData();
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "Идентификатор",
      accessorKey: "id",
      enableClickToCopy: true,
    },
    {
      header: "Проект",
      accessorKey: "project",
      Cell: ({ row }) =>
        row.original.project && (
          <Tag
            style={{
              fontWeight: "bold",
              fontSize: 16,
              padding: "5px 10px 5px 10px",
            }}
          >
            {row.original.project}
          </Tag>
        ),
    },
    {
      header: "Создан",
      accessorKey: "createdBy",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return <UserPopover userId={row.original.createdBy} />;
      },
    },
    {
      header: "Дата создания",
      accessorKey: "createdAt",
      filterVariant: "date",
      filterFn: "betweenInclusive",
      muiFilterDatePickerProps: { format: "DD.MM.YYYY", timezone: "UTC" },
      Cell: ({ cell }: any) =>
        format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
    },
    {
      header: "Изменен",
      accessorKey: "updatedBy",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return <UserPopover userId={row.original.updatedBy} />;
      },
    },
    {
      header: "Дата изменения",
      accessorKey: "updatedAt",
      filterVariant: "date",
      filterFn: "betweenInclusive",
      muiFilterDatePickerProps: { format: "DD.MM.YYYY", timezone: "UTC" },
      Cell: ({ cell }: any) =>
        format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
    },
    {
      header: "Действие",
      enableGrouping: false,
      Cell: ({ row }) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Button
            size="small"
            onClick={() => openLaunchDrawer(row.original.id)}
          >
            ▶
          </Button>
          <Link to={`/integration/constructor/${row.original.id}`}>
            <Button size="small">✏</Button>
          </Link>
          <Button size="small" onClick={() => openCopyDrawer(row.original.id)}>
            🖨️
          </Button>
          <Popconfirm
            title={`Вы действительно хотите удалить формат файла ${row.original.id}?`}
            okText="Да"
            cancelText="Нет"
            onConfirm={() => deleteFlow(row.original.id)}
          >
            <Button size="small">❌</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const table = useMaterialReactTable({
    data: rows,
    columns: columns,
    initialState: { density: "compact" },
    localization: MRT_Localization_RU,

    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    enableGrouping: true,
    groupedColumnMode: "remove",

    layoutMode: "grid",

    muiToolbarAlertBannerProps: isDataError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    rowCount: count,

    state: {
      columnFilters: filters,
      isLoading: isDataLoading,
      pagination,
      showAlertBanner: isDataError,
      showProgressBars: isDataRefetching,
      sorting,
    },
  });

  return (
    <div>
      <div
        className="ManageTable"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Link to={`/integration/constructor/new`}>
          <Button size="small">➕</Button>
        </Link>
        <Button size="small" onClick={refresh}>
          🔄
        </Button>
      </div>
      <div style={{ marginTop: "15px" }}>
        <MaterialReactTable table={table} />
      </div>
      <IntegrationFlowCopy
        open={copyModalState}
        onClose={closeCopyDrawer}
        flowToCopyName={copyFlowTarget}
        launchCopy={copyFlow}
        setNewFlowName={setCopyFlowName}
      />
      <IntegrationFlowLaunch
        open={launchModalState}
        onClose={closeLaunchDrawer}
        flowId={launchFlowId}
      />
    </div>
  );
};

export default IntegrationTaskPage;
